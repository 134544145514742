import React, { Component } from 'react';
import config from './config';

import { motion } from "framer-motion";

import TopBarTitle from './TopBarTitle';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from '@material-ui/core/Card';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import CircularProgress from '@material-ui/core/CircularProgress';

class Offers extends Component {
    
    constructor() {
        super();
    
        this.state = {
            isLoading: true,
            apiResponse: {}
        }

        this.goBack = this.goBack.bind(this);
    }
    
    componentWillMount() {
      localStorage.setItem('isMobyTopBarBlink','false');
      var requestParams = { "fsAction":"getAdvertisementImage",'fsScreenType':'map-screen' }; 
      config.fetchInformation(requestParams).then((responseJson)=>{
        this.setState({
            apiResponse: responseJson,
            isLoading:false
          });
      });
    }

    goBack() {
        this.props.history.push({
            pathname: '/',
        });
    }

    moveDealOfTheDayPage(dealDeatils){
        var category =  dealDeatils.fiCategory > 0 ? dealDeatils.fiCategory : 0;
        localStorage.setItem('fiBannerAdId',dealDeatils.fiAdId);
        localStorage.setItem('fiLoadCategory',category);
        this.goBack();
    }


    render() {

        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >
                <div className="AppDashboard">
                    <div className="topAndSideBar">
                        <TopBarTitle strTitle="Ongoing Deals" goBack={this.goBack} />
                    </div>

                    <div className="AppContent isNormalView isTopBarOnTop" >
                        <div className="OfferSliderScreen">
                            <div>
                                {this.state.isLoading === true ?
                                    <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                                    :
                                    this.state.isLoading !== true && this.state.apiResponse.fbIsError === false?
                                        <Card><Swiper
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation
                                        pagination={{ clickable: false }}
                                        scrollbar={{ draggable: true }}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        onSlideChange={() => console.log('slide change')}
                                        >
                                            {this.state.apiResponse.foAdvertisementList.map((single,i)=>{
                                                return (
                                                    <SwiperSlide>
                                                        <div className="slideItems">
                                                            <img onClick={()=>this.moveDealOfTheDayPage(single)} src={config.getImageUrl(single.fsImage)}/>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })}
                                        </Swiper></Card>
                                    :''
                                }
                           </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Offers;