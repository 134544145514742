import React, { Component } from "react";
import config from './config';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { motion } from "framer-motion";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CircularProgress from '@material-ui/core/CircularProgress';

import ContinuePopup from './popup/ContinuePopup';

import TopBarDrawer from './TopBarDrawer';

import InternetNotAvailable from './InternetNotAvailable';

export default class BillUpload extends Component {
  constructor(props) {
    super(props)
    var minDate = this.props.location.state.fdEngageDate.split(', ')[0];
    minDate = minDate.split('-');

    this.state = {
      adId: this.props.match.params.adId,
      tabValue:0,
      fieldValues:{purchaseDate:new Date().toISOString().split('T')[0], billAmount:0, cardSelection:"0"},
      minMaxDate:{ 
          min: minDate[2]+'-'+minDate[1]+'-'+minDate[0], 
          max: new Date().toISOString().split('T')[0] 
      },
      isLoading: false,
      userCards:'',
      isSumitResponse:'',

      fsFirstImage:'',
      fsSecondImage:'',
      isDisplayError:false
    }

    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.uploadBillClickHandle = this.uploadBillClickHandle.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.removeBillImage = this.removeBillImage.bind(this);
  }

  removeBillImage(removeImageName){
      if(removeImageName===1){
          this.setState({ fsFirstImage:'' })
      }else{
        this.setState({ fsSecondImage:'' })
      }
  }

  uploadBillClickHandle(){
    var isDisplayError = false;
    if(this.state.fieldValues.purchaseDate===""){ isDisplayError=true; }
    if(this.state.fieldValues.billAmount===0){ isDisplayError=true; }
    if(this.state.fsFirstImage==="" && this.state.fsSecondImage===""){ isDisplayError=true; }
    if(this.state.tabValue===1 && this.state.fieldValues.cardSelection==="0"){ isDisplayError=true; }
    if(isDisplayError===true){
        this.setState({ isDisplayError:true })
    }else{
        this.setState({ isDisplayError:false,isLoading:true });

        var requestParams = {
            "user_contact": localStorage.getItem('PhonePrefix').trim()+localStorage.getItem('userPhone'),
            "transaction_date":this.state.fieldValues.purchaseDate,
            "amount":this.state.fieldValues.billAmount,
            "mode_of_payment":this.state.tabValue===0?'Cash':'Card',
            "card_id":this.state.fieldValues.cardSelection,
            "transaction_id":this.state.adId,
            "type":"timeline",
            "fsFirstImage":this.state.fsFirstImage,
            "fsSecondImage":this.state.fsSecondImage
        };
        //var formBody = config.convertParams(requestParams);
        var request = { method: 'POST',body:JSON.stringify(requestParams)  };
        
        fetch(config.API_URL+'TRANSACTION_REGISTER&', request)
        .then((response) => response.text())
        .then((responseText) => {
          this.setState({
            isSumitResponse:JSON.parse(responseText),
            isLoading:false
          });
        }).catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillMount() {
    var requestParams = {
    };
    var formBody = config.convertParams(requestParams);
    var request = config.getRequestHeaders();
    fetch(config.API_URL+'GET_USER_CARD&'+formBody, request)
    .then((response) => response.text())
    .then((responseText) => {
      this.setState({
        userCards:JSON.parse(responseText),
      });
    }).catch((error) => {
      console.error(error);
    });
}

  handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        var formValues = this.state.fieldValues;
        formValues[name] = value.trim();
        this.setState({ 'fieldValues': formValues });
    }


  handleChange(event, newValue){
    this.setState({"tabValue":newValue});
  }

  goBack() {
    this.props.history.goBack();
  }


  handleImageUpload(e){

    const [file] = e.target.files;
    var fileName = e.target.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        //current.src = e.target.result;
        if(fileName==='fsFirstImage'){
            this.setState({ fsFirstImage:e.target.result })
        }else{
            this.setState({ fsSecondImage:e.target.result })
        }
      };
      reader.readAsDataURL(file);
    }
  }


  render() {

    /* No Internet */
    if(!navigator.onLine){
        return(
            <InternetNotAvailable/>
        )
    }

    console.log(this.state.minMaxDate);
    var profileForm = <div className="updateProfileScreen removeAnimation">
                        <form noValidate autoComplete="off">
                            <div className="firstNameLastName billUploadForm">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="withLabel withIcon">
                                            <label>Purchase Date</label>
                                            <span className="material-icons-outlined">
                                                date_range
                                        </span>
                                            <TextField
                                                id="date"
                                                label="Birthday"
                                                type="date"
                                                name="purchaseDate"
                                                defaultValue={this.state.fieldValues.purchaseDate}
                                                
                                                variant="outlined"
                                                onChange={(event) => this.handleUserInput(event)}
                                                InputProps={{ inputProps: { min: this.state.minMaxDate.min, max: this.state.minMaxDate.max }}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {this.state.isDisplayError && this.state.fieldValues.purchaseDate===""?
                                                <div className="error">Purchase date must be required</div>
                                            : '' }
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="withLabel withIcon">
                                            <label>Bill Amount</label>
                                            <span className="material-icons-outlined">
                                                credit_card
                                            </span>
                                            <TextField
                                                id="BillAmount"
                                                label="BillAmount"
                                                type="number"
                                                name="billAmount"
                                                variant="outlined"
                                                onChange={(event) => this.handleUserInput(event)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {this.state.isDisplayError && this.state.fieldValues.billAmount===0?
                                                <div className="error">Bill Amount must be required</div>
                                            : '' }
                                        </div>
                                    </Grid>
                                    
                                    

                                    <Grid item xs={12}>
                                        <div className="mapListToggle genderMaleFemale">
                                            <div style={{'marginBottom':'4px'}}><label>Payment Type</label></div>
                                            <Paper square>
                                                <Tabs
                                                    value={this.state.tabValue}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={this.handleChange}
                                                    aria-label="disabled tabs example"
                                                    centered
                                                >
                                                    <Tab label="Cash" />
                                                    <Tab label="Card" />
                                                </Tabs>
                                            </Paper>
                                        </div>
                                    </Grid>


                                    {this.state.userCards!=="" && this.state.tabValue===1?
                                        <Grid item xs={12}>
                                            <div className="cardSelection">
                                                <label>Card Type</label>
                                                <select name="cardSelection" onChange={(event) => this.handleUserInput(event)} className="htmlInput">
                                                    <option key={0} value={0}>Select Card</option>
                                                    {this.state.userCards.message.map((item,i)=>
                                                        <option key={item.id} value={item.id}>{item.bank_name}</option>
                                                    )}
                                                </select>
                                                {this.state.isDisplayError && this.state.fieldValues.cardSelection==="0"?
                                                    <div className="error">Card must be required</div>
                                                : '' }
                                            </div>
                                        </Grid>
                                    :
                                    ''
                                    }

                                    <Grid item xs={12}>
                                        <div><label>Capture Bill Photo / Select From Gallery</label></div>
                                        <div className="billListContainer">
                                            {this.state.fsFirstImage!==""?
                                                    <div className="list uploadIcon"><img src={this.state.fsFirstImage} alt="First Bill"/>
                                                    <span className="material-icons-outlined removeBillUploaded" onClick={()=>this.removeBillImage(1)}>
                                                        clear
                                                    </span>
                                                </div>
                                                :
                                                    <div className="list uploadIcon uploadFromGallery">
                                                        <span className="material-icons-outlined">
                                                            add
                                                        </span>
                                                        <input
                                                            type="file" name="fsFirstImage" accept="image/*" onChange={this.handleImageUpload}
                                                        />
                                                    </div>
                                            }

                                            {this.state.fsSecondImage!==""?
                                                    <div className="list uploadIcon"><img src={this.state.fsSecondImage} alt="Second Bill"/>
                                                    <span className="material-icons-outlined removeBillUploaded" onClick={()=>this.removeBillImage(2)}>
                                                        clear
                                                    </span>
                                                    </div>
                                                :
                                                    <div className="list uploadIcon uploadFromGallery">
                                                        <span className="material-icons-outlined">
                                                            add
                                                        </span>
                                                        <input
                                                            type="file" name="fsSecondImage" accept="image/*" onChange={this.handleImageUpload}
                                                        />
                                                    </div>
                                            }
                                            
                                        </div>
                                        {this.state.isDisplayError && this.state.fsFirstImage==="" && this.state.fsSecondImage===""?
                                                <div className="error">Bill image must be required</div>
                                            : '' }
                                    </Grid>
                                    
                                </Grid>
                            </div>
                            <div className="submitButton progressButton" style={{'marginBottom':'15px'}}>
                                <Button variant="contained" onClick={this.uploadBillClickHandle} color="secondary">
                                    {this.state.isLoading===true?<CircularProgress color="secondary" />:'Upload Bill'}
                                </Button>
                            </div>

                        </form>
                    </div>;

    
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className="AppDashboard">
          
          <div className="topAndSideBar">
              <TopBarDrawer isMessageGoBack={this.goBack} isSingleMessage={true} strTitle="Upload Bill" />
          </div>

          <div className="AppContent isNormalView">
            {profileForm}
            {this.state.isSumitResponse!==""?
                <ContinuePopup popupButtonText="Ok" popupTitle="Congratulations!" popupContent={this.state.isSumitResponse.message} handleClose={this.goBack} openDialog={true} />
            :''}
          </div>
          
        </div>
      </motion.div>
    )
  }
}