import React, { Component } from "react";
import config from './config';
import { motion } from "framer-motion";

import TopBarDrawer from './TopBarDrawer';
import CircularProgress from '@material-ui/core/CircularProgress';

import TimeLineList from './TimeLine/TimeLineList';
import TimeLinePopUp from './TimeLine/TimeLinePopUp';
import ContinuePopup from './popup/ContinuePopup';

import InternetNotAvailable from './InternetNotAvailable';
import BillUploadPopup from './BillUploadPopup';

import AdSense from 'react-adsense';

export default class MyTimeLine extends Component {
  constructor(props){
    super(props)

    var isMoveOnSingleTimeLine = this.props.match.params.activityId !== undefined?true:false;
    var currentTimeline = {};
    var openBillUploadPopup = false;
    if (this.props.location.state !== undefined) {
        if(this.props.location.state.openBillUploadPopup!== undefined){
          currentTimeline = this.props.location.state.currentTimeline;
          openBillUploadPopup = this.props.location.state.openBillUploadPopup;
          this.props.history.push('/my-timeline');
        }
    }

    var fiHighlightActivity = 0;
    if(localStorage.getItem('fiHighlightActivity')!==null){
      fiHighlightActivity = parseInt(localStorage.getItem('fiHighlightActivity'));
      localStorage.removeItem('fiHighlightActivity');
    }

    this.state={
      openDialog : false,
      fiHighlightActivity : fiHighlightActivity,
      isLoading : true,
      isSecondLoading : false,
      apiResponse:'',
      fiTrasactionId:0, 
      fdEngageDate:'',
      orderBy:'',
      fsFilter:'',
      fiTotalBillVerified:0,
      isRedPopup:false,
      isRedPopupMessage:'',
      isMoveOnSingleTimeLine : isMoveOnSingleTimeLine,

      currentTimeline:currentTimeline,
      openBillUploadPopup:openBillUploadPopup
    }

    this.handleClose = this.handleClose.bind(this);
    this.openPopupForBrand = this.openPopupForBrand.bind(this);
    this.popupClose = this.popupClose.bind(this);
    this.goToUploadBill = this.goToUploadBill.bind(this);
    this.handleRedPopupDisplay = this.handleRedPopupDisplay.bind(this);
    this.closeRedPopup = this.closeRedPopup.bind(this); 
    this.openBillUploadPopup = this.openBillUploadPopup.bind(this);
    this.moveUploadBillScreen = this.moveUploadBillScreen.bind(this);
    this.timelineOrderBy = this.timelineOrderBy.bind(this);
    this.timelineFilter = this.timelineFilter.bind(this);
    this.getTimelineWithFilter = this.getTimelineWithFilter.bind(this);
    this.billUploadLater = this.billUploadLater.bind(this);
    this.billUploaded = this.billUploaded.bind(this);
    this.handleBillVerifiedPopup = this.handleBillVerifiedPopup.bind(this);
    this.updateMarkAsRead = this.updateMarkAsRead.bind(this);
  }

  handleBillVerifiedPopup(){
    if(parseInt(this.state.fiTotalBillVerified) > 0){
      this.props.history.push({
        pathname: '/map',
        state:{}
      });
    }else{
      this.setState({
        'isRedPopup':true,
        'isRedPopupMessage':'Sorry, You Require Min 1 Purchase via Moby Coupon'
      })
    }
  }

  billUploaded(){
    this.getTimelineWithFilter();
    this.setState({ 'openBillUploadPopup': false,'isRedPopup':true,'isRedPopupMessage':'billSuccess'});
  }

  billUploadLater(){
    this.setState({ 'openBillUploadPopup': false})
  }

  timelineOrderBy(event){
    this.setState({ 'orderBy':event.target.value },()=>{this.getTimelineWithFilter()})
  }

  timelineFilter(event){
    this.setState({ 'fsFilter':event.target.value },()=>{this.getTimelineWithFilter()})
  }

  openBillUploadPopup(fiTrasactionId,fdEngageDate,timlineObj){

    // var billUploadLink = '/bill-upload/' + fiTrasactionId;
    // this.props.history.push({
    //   pathname: billUploadLink,
    //   state:{ fiTrasactionId:fiTrasactionId,fdEngageDate:fdEngageDate }
    // });

    this.setState({
      currentTimeline:timlineObj,
      openBillUploadPopup:true
    });

    // var replaceAmount =timlineObj.fiVirtualCaseTransferDays;
    // var message = config.getDynamicLabel("timeline","msg_upload_bill_no_gc");
    // message = message.replace('45 DAYS',replaceAmount+' DAYS');
    // message = message.replace('XXX',timlineObj.fsGiftCardCode);
    // this.setState({
    //   fiTrasactionId:fiTrasactionId,
    //   fdEngageDate:fdEngageDate,
    //   isRedPopup:true,
    //   isRedPopupMessage:message
    // })
  }

  getTimelineWithFilter(){
    // console.log(value);
    this.setState({ 'isSecondLoading': true })
    var requestParams = {
      'fsAction':'getActivityList',
      'fsOrderBy':this.state.orderBy,
      'fsFilter':this.state.fsFilter
    };
    config.fetchInformation(requestParams).then((responseJson)=>{
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
        isSecondLoading:false,
        fiTotalBillVerified:responseJson.fiTotalBillVerified
      },()=>{
        if(this.state.isMoveOnSingleTimeLine){
          this.goToUploadBill(0);
        }
      });
    });
  }

  moveUploadBillScreen(){
    var billUploadLink = '/bill-upload/' + this.state.fiTrasactionId;
    this.props.history.push({
      pathname: billUploadLink,
      state:{ fiTrasactionId:this.state.fiTrasactionId,fdEngageDate:this.state.fdEngageDate }
    });
  }

  handleRedPopupDisplay(popupType){
    var message = "";
    if(popupType==='billUploaded'){
      message = "You have already uploaded bill for this Ad, Try other brands.";
    }
    if(popupType==='useGiftcardFirstMobile'){
      message = 'To upload, Please view your coupon, then “Mark as Used” by entering your mobile.';
    }
    if(popupType==='useGiftcardFirstPin'){
      message = 'To upload, Please view your coupon, then “Mark as Used” by entering your PIN.';
    }
    if(popupType==='giftCardUsed'){
      message = "The giftcard already used, Please try other.";
    }
    if(popupType==='giftCardExpired'){
      message = "The giftcard was expired, Please try other.";
    }
    this.setState({isRedPopup:true,isRedPopupMessage:message});
  }

  closeRedPopup(){
    this.setState({isRedPopup:false})
  }
  
  goToUploadBill(billIndex){
    var currentTimeline = this.state.apiResponse.foActivityList[billIndex];
    // console.log('currentTimeline.fiActivityId',currentTimeline);

    if(currentTimeline.fsPinColor==='red' && currentTimeline.fsMarkIsUsedFlow==="offline"){
      this.props.history.push({
        pathname: '/ads-mark-as-read-in-store/'+currentTimeline.fiActivityId
      });
    }else if(currentTimeline.fsPinColor==='red-voucher'){
      this.props.history.push({
        pathname: '/ads-mark-as-read-voucher/'+currentTimeline.fiActivityId
      });
    }else if(currentTimeline.fsPinColor==='green' && currentTimeline.fsMarkIsUsedFlow==='install-register'){
      this.props.history.push({
        pathname: '/ads-mark-as-install/'+currentTimeline.fiActivityId
      });
    }else{
      if(currentTimeline.fsMarkIsUsedFlow==="offline-hybrid-green" || currentTimeline.fsMarkIsUsedFlow==="offline-direct-green"){
        this.props.history.push({
          pathname: '/ads-mark-as-read-hybrid/'+currentTimeline.fiActivityId
        });
      }else{
        this.props.history.push({
          pathname: '/ads-mark-as-read/'+currentTimeline.fiActivityId
        });
      }
    }
  }

  updateMarkAsRead(billIndex){
    var currentTimeline = this.state.apiResponse.foActivityList[billIndex];
    var requestParams = {
        "fsAction":"couponMarkAsUsed",
        "fiActivityId" : currentTimeline.fiActivityId,
        "fiAmount":currentTimeline.fiQuizReward
    };
    config.fetchInformation(requestParams).then((responseJson1)=>{
        this.getTimelineWithFilter();
    });
  }
  
  openPopupForBrand(){
    this.setState({
      openDialog : true
    });
  }

  popupClose(){
    this.setState({
      openDialog : false
    });
  }

  handleClose(){
    this.props.history.push({
        pathname: '/',
        state:{ }
    });
  }

  componentDidMount() {
    this.getTimelineWithFilter();

    /* Disable Ad List Screen Back Event */
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', ()=>
    // {
    //   window.history.pushState(null, document.title, window.location.href);
    //   this.openPopupForBrand();
    // });
    /* Disable Ad List Screen Back Event */

  }

  render(){

    /* No Internet */
    if(!navigator.onLine){
      return(
        <InternetNotAvailable/>
      )
    }

    
    console.log(this.state.apiResponse.message);
    var displayTimeline = <div className="apiCallLoader fullScreenLoader"><CircularProgress color="secondary" /></div>;
    
    var isRedPopupDisplay = "";
    if(this.state.isRedPopup){
      if(this.state.fiTrasactionId > 0){
        isRedPopupDisplay = <ContinuePopup popupButtonText="Ok" popupContent={this.state.isRedPopupMessage} handleClose={this.moveUploadBillScreen} openDialog={true} />;
      }else{
        var message = this.state.isRedPopupMessage;
        var title = "Oops!";
        if(this.state.isRedPopupMessage==='billSuccess'){
          title = "";
          message = "Your bill was successfully uploaded!";
        }
        isRedPopupDisplay = <ContinuePopup popupButtonText="Ok" popupTitle={title} popupContent={message} handleClose={this.closeRedPopup} openDialog={true} />;
      }
    }

    var timeLineFilter = <div className="timelineFilter">
      <div>
        <div>
          <div><span className="material-icons-outlined">sort_by_alpha</span></div>
          <div className="charitySelection">
            <select name="charitySelection" onChange={this.timelineOrderBy} className="htmlInput">
                <option value="">Featured</option>
                <option value="coupon-expired-desc">Expiring</option>
                <option value="price-desc">Highest Price</option>
                <option value="ad_name-asc">Alphabetic</option>
            </select> 
          </div>
        </div>
        <div>
          <div><span className="material-icons-outlined">filter_alt</span></div>
          <div className="charitySelection">
            <select name="charitySelection" onChange={this.timelineFilter} className="htmlInput">
                <option value="">All Coupons</option>
                
                <option value="offline-coupons">Offline Coupons</option>
                <option value="restaurant-coupons">Restaurant Coupons</option>
                <option value="online-voucher">Online Vouchers</option>
                <option value="online-coupons">Online Cashbacks</option>
                <option value="unused-coupons">Unused</option>
                <option value="bill_uploaded">Bills Upload</option>
                <option value="bill_verified">Verified Coupons</option>
                <option value="cashback_recieved">Paid</option>
            </select> 
          </div>
        </div>
      </div>
    </div>
    var NoDataFound = config.getProviderSetting().fsNoData;
    if(this.state.isLoading===false){
      // console.log(this.state.apiResponse.message.timeline);
      if(this.state.apiResponse.fbIsError === false && this.state.apiResponse.foActivityList.length > 0){
          displayTimeline = <div>
                {
                this.state.isSecondLoading?
                  <div className="apiCallLoader fullScreenLoader"><CircularProgress color="secondary" /></div>
                :
                  <TimeLineList 
                  fiHighlightActivity = {this.state.fiHighlightActivity}
                  goToUploadBill={this.goToUploadBill} 
                  handleRedPopupDisplay={this.handleRedPopupDisplay}
                  openBillUploadPopup = {this.openBillUploadPopup} 
                  updateMarkAsRead={this.updateMarkAsRead}
                  timeLine={this.state.apiResponse.foActivityList}/>
                }
                {/* <div className=""  style={{ 'marginTop':'30px' }}>
                    <AdSense.Google
                        client={config.adsense.clientId}
                        slot={config.adsense.slotId}
                        style={{ display: 'block' }}
                        format="auto"
                        responsive="true"
                    />
                </div> */}
                <TimeLinePopUp popupClose={this.popupClose} openDialog={this.state.openDialog} handleBillVerifiedPopup={this.handleBillVerifiedPopup} handleClose={this.handleClose} apiResponse={this.state.apiResponse} currentTimeline/>
                {isRedPopupDisplay}
        </div>
      }else{
        displayTimeline = <div className="NoDataFound" style={{'marginTop':'0px'}}>
          <img src={NoDataFound} alt="No Data Found"/>
          <div>Oops!</div>
          <div>No coupons found on your account</div>

          {/* <div className="" style={{ 'marginTop':'30px' }}>
              <AdSense.Google
                  client={config.adsense.clientId}
                  slot={config.adsense.slotId}
                  style={{ display: 'block' }}
                  format="auto"
                  responsive="true"
              />
          </div> */}
        </div>
      }
    }
    console.log(this.state.apiResponse);
    return(
      <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
          >
        <div className="AppDashboard">
          <div className="topAndSideBar">
              {this.state.apiResponse.fbIsError===false && this.state.apiResponse.fiTotalBillVerified===0 ?
                <TopBarDrawer isMessageGoBack={this.openPopupForBrand} isSingleMessage={true} strTitle="My Coupons"/>
                  :
                <TopBarDrawer strTitle="My Coupons"/>
              }
          </div>

          <div className="AppContent isNormalView" style={{ 'height':'calc(100vh - 55px)' }}>
              {this.state.orderBy!=="" || this.state.fsFilter!=="" || 
              (this.state.isLoading===false && this.state.apiResponse.foActivityList.length > 0) ? timeLineFilter :''} 
              {displayTimeline}
              {this.state.openBillUploadPopup?
                <BillUploadPopup 
                  fdEngageDate={this.state.currentTimeline.fdQuizEnggagTimePWA} 
                  fiTrasactionId={this.state.currentTimeline.fiActivityId}
                  billUploadLater = {this.billUploadLater}
                  billUploaded = {this.billUploaded}
                  currentTimeline={this.state.currentTimeline}
                />
              :''}
          </div>
        </div>
      </motion.div>
    )
  }
}