import React, { Component } from "react";
import config from "./config";
import { motion } from "framer-motion";
import PaymentMethod from "./PaymentMethod";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "@material-ui/core/NativeSelect";
import Confetti from "react-confetti";

import ContinuePopup from "./popup/ContinuePopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import InternetNotAvailable from "./InternetNotAvailable";
import Card from "@material-ui/core/Card";

import { Swiper, SwiperSlide } from "swiper/react";

import DeviceConnectionPopup from "./popup/DeviceConnectionPopup";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import PaymentMethodForVoucher from "./PaymentMethodForVoucher";

export default class VoucherDetails extends Component {
  constructor(props) {
    super(props);
    var isQuizSubmitted = false;
    var quizResponse = "";
    if (this.props.location.state !== undefined) {
      // console.log(this.props.location.state);
      if ("marketingAd" in this.props.location.state) {
        console.log("Marketing Ad Redirected");
        localStorage.setItem("isAdDetailBackToMap", true);
      } else {
        isQuizSubmitted = true;
        quizResponse = this.props.location.state.response;
      }
    }
    // console.log('construct',this.props.location.state);
    this.state = {
      adId: this.props.match.params.adId,
      locationId: this.props.match.params.locationId,
      isLoading: true,
      currentOffer: "",
      isAbleToEngage: true,
      adDistance: 0,
      selectedPaymentMethod:"",
      isQuizSubmitted: isQuizSubmitted,
      quizResponse: quizResponse,
      userDetails: JSON.parse(localStorage.getItem("userProfile")),

      foVoucherDetails: [],
      fbIsVocuherLoader: true,
      fiVoucherSelectedAmount: 0,
      fiVoucherQty: 1,
      foVoucherDominations: [],
      fbBuyVoucherLoader: false,
      foPurchaseDetails: {},
      showPaymentLoader: false,
      fbIsOpenPaymentMethod: false,
      fbContinueLinkLoader: false,
      openVouSuccessPopup: false,
      openRedirectPopup: false,
      fsPopupTitle: "",
      fsPopupBtnTxt: "Continue",
      isUseInstantCash: true,
      fbIsDevicePopupEnable: false,
    };

    this.goBack = this.goBack.bind(this);
    this.popupGoBack = this.popupGoBack.bind(this);
    this.closeAdResponsePopup = this.closeAdResponsePopup.bind(this);
    this.openNotOnRadiusPopup = this.openNotOnRadiusPopup.bind(this);
    this.placeVoucherOrder = this.placeVoucherOrder.bind(this);
    this.closePopupAndRedirect = this.closePopupAndRedirect.bind(this);
  }

  closePopupAndRedirect(goToLink = "") {
    if (goToLink === "my-timeline") {
      this.props.history.push({
        pathname: "/" + goToLink,
      });
      return;
    }
    this.setState({ fbContinueLinkLoader: true });
    setTimeout(() => {
      this.setState({
        openVouSuccessPopup: false,
        fbContinueLinkLoader: false,
      });
      var goToCouponLink =
        "/my-timeline/" + this.state.foPurchaseDetails.fsLastActivityId;
      localStorage.setItem(
        "fiHighlightActivity",
        this.state.foPurchaseDetails.fsLastActivityId
      );
      this.props.history.push({
        pathname: goToCouponLink,
      });
    }, 2000);
  }

  closeAdResponsePopup() {
    this.setState({
      isQuizSubmitted: false,
    });
  }

  openNotOnRadiusPopup() {
    this.setState({
      isAbleToEngage: false,
    });
  }

 // Function to update selected payment method in the parent
 handlePaymentMethodChange = (paymentMethod) => {
  this.setState({ selectedPaymentMethod: paymentMethod });
};

  componentDidMount(props) {
    var foGlobalSetting = JSON.parse(localStorage.getItem("foGlobalSetting"));

    let mid = foGlobalSetting.fsPaytmMID; // Merchant ID
    var url = foGlobalSetting.fbPaytmProd
      ? "securegw.paytm.in"
      : "securegw-stage.paytm.in";


    var requestParams = {
      fsAction: "getOfferDetails",
      fiAdId: this.state.adId,
      fiLocationId: this.state.locationId,
    };
    config.fetchInformation(requestParams).then((responseJson) => {
      var distance = 0;
      if (responseJson.foOfferDetails.flCoverageRadius !== "") {
        var userLat = localStorage.getItem("userLat");
        var userLong = localStorage.getItem("userLong");
        var adLat = responseJson.foOfferDetails.foLocationList[0].ffLatitude;
        var adLong = responseJson.foOfferDetails.foLocationList[0].ffLongitude;
        distance = config.getDistanceFromLatLonInKm(
          userLat,
          userLong,
          adLat,
          adLong
        );
      }

      this.setState({
        currentOffer: responseJson.foOfferDetails,
        isLoading: false,
        adDistance: distance,
      });

      if (responseJson.foOfferDetails.fsVouProductBrdCode !== "") {
        var requestParams = {
          fsAction: "fsVoucherActions",
          fsCallFun: "getBrands",
          fsProductBrandCode: responseJson.foOfferDetails.fsVouProductBrdCode,
        };
        config.fetchInformation(requestParams).then((voucherJosn) => {
          if (voucherJosn.foVoucherDetails.length > 0) {
            var denominationList =
              voucherJosn.foVoucherDetails[0].denominationList.split(",");
            this.setState({
              foVoucherDetails: voucherJosn.foVoucherDetails,
              fbIsVocuherLoader: false,
              foVoucherDominations: denominationList,
              fiVoucherSelectedAmount:
                denominationList.length > 0 ? denominationList[0] : 0,
            });
          } else {
            this.setState({
              foVoucherDetails: voucherJosn.foVoucherDetails,
              fbIsVocuherLoader: false,
            });
          }
        });
      }
    });
  }

  openLinkOnBrowser(fsCouponLink) {
    var fsProvider = config.getProviderReferral();
    if (fsProvider === "M91APP") {
      try {
        window.JSBridgePlugin.openInBrowser(fsCouponLink);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        window.app.openLinkOnBrowser(fsCouponLink);
      } catch (error) {
        console.log(error);
      }
    }

    if (config.getProviderSetting().fbIsPWAOpenOnWeb) {
      window.open(fsCouponLink, "_blank");
    }
  }

  placeVoucherOrder(paymentObject) {
    // this.setState({ fbBuyVoucherLoader: false, openVouSuccessPopup: true });return;
    console.log("paymentObject ", paymentObject.razorpay_payment_id);
    console.log("status in voucher", paymentObject.STATUS);
    if (paymentObject.STATUS === "TXN_SUCCESS") {
      console.log("paymentObject", paymentObject);
      this.setState({ fbBuyVoucherLoader: true });
      var foGlobalSetting = JSON.parse(localStorage.getItem("foGlobalSetting"));
      var voucherDetails = this.calculatePayment();
      var requestParams = {
        fsAction: "fsVoucherActions",
        fsCallFun: "pulVoucher",
        fsProductBrandCode: this.state.currentOffer.fsVouProductBrdCode,
        fiAdId: this.state.currentOffer.fiAdId,
        fiQuantity: this.state.fiVoucherQty,
        fiDenomination: this.state.fiVoucherSelectedAmount,
        fiUserId: foGlobalSetting.fiUserId,
        fiPaymentId: paymentObject.TXNID,
        fiFinalPaidTotal: voucherDetails.finalAmount,
        fiRedeemCash: voucherDetails.fiRedeemCash,
      };
      config.fetchInformation(requestParams).then((responseJson) => {
        if (responseJson.fbIsError) {
          this.setState({
            fbBuyVoucherLoader: false,
            foPurchaseDetails: responseJson,
          });
        } else {
          this.setState({
            fbBuyVoucherLoader: false,
            openVouSuccessPopup: true,
            foPurchaseDetails: responseJson,
          });
        }
      });
    } else {
      this.setState({ fbIsOpenPaymentMethod: false, showPaymentLoader: false });
      toast.error(paymentObject.RESPMSG, {
        position: "top-center",
        autoClose: 8000,
      });
      return false;
    }
  }

  goBack() {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: "/",
    });
  }

  popupGoBack() {
    this.props.history.push({
      pathname: "/",
    });
  }

  calculatePayment() {

    // Extract voucher details from state
    var fiVoucherAmount = parseInt(this.state.fiVoucherSelectedAmount);
    var fiMyCashPayable = parseInt(
      ((fiVoucherAmount * this.state.currentOffer.fsFlatCashBackPer) / 100) *
      this.state.fiVoucherQty
    );
    console.log("fiMyCashPayable", fiMyCashPayable)
    var finalAmount = fiVoucherAmount * this.state.fiVoucherQty;

    // Get total usable instant cash
    var fiTotalInstantCash =
      this.state.currentOffer.foInstantCash.fiTotalUsableCash;

    // Initialize fiRedeemCash
    var fiRedeemCash = 0;

    // Check if instant cash should be used
    if (this.state.isUseInstantCash) {
      // Calculate additional amount based on percentage and bonus cash limit
      let additionalAmount = parseInt(
        (finalAmount * this.state.currentOffer.fiMaxInstaCashPerCanUse) / 100
      );

      // Ensure that the additional amount does not exceed bonus cash limit
      additionalAmount = Math.min(
        additionalAmount,
        this.state.currentOffer.foInstantCash.fiBonusCash
      );

      // Add the additional amount to fiRedeemCash
      fiRedeemCash += additionalAmount;

      // Add fiCashback to fiRedeemCash
      fiRedeemCash += this.state.currentOffer.foInstantCash.fiCashback;
      if (fiRedeemCash >= finalAmount) {
        fiRedeemCash = finalAmount - 1;
      }

      // Pay at least 1 rs and use all instant cash
      if (fiRedeemCash < fiTotalInstantCash) {
        fiRedeemCash = Math.max(fiRedeemCash, 1);
      }
    }

    // Calculate fiTotalAmount after deducting fiRedeemCash
    var fiTotalAmount = finalAmount - fiRedeemCash;

    // Calculate fsDiscountUpTo
    let fsDiscountUpTo = 0;
    if (fiRedeemCash > 0 && this.state.currentOffer.fiDefVouDenomination > 0) {
      fsDiscountUpTo = parseInt(
        (fiRedeemCash * 100) /
        (parseInt(fiVoucherAmount) * this.state.fiVoucherQty)
      );
      if (fsDiscountUpTo >= 100) {
        fsDiscountUpTo = "99";
      }
      fsDiscountUpTo += "%";
    } else {
      fsDiscountUpTo = "0%";
    }

    // Return an object containing calculated values
    return {
      fiVoucherAmount: fiVoucherAmount,
      fiMyCashPayable: fiMyCashPayable,
      finalAmount: finalAmount,
      fiRedeemCash: fiRedeemCash,
      fiTotalAmount: fiTotalAmount,
      fsDiscountUpTo: fsDiscountUpTo,
      fbIsUseInstantCash: this.state.isUseInstantCash
    };
  }


  calculateOrderDetails() {
    const { fiTotalAmount, fiRedeemCash, fbIsUseInstantCash } = this.calculatePayment();
    const foGlobalSetting = JSON.parse(localStorage.getItem("foGlobalSetting"));
    const voucherDetails = this.calculatePayment();

    return {
      fsDescription: this.state.currentOffer.fsAdName + " Giftcard Purchase",
      fbIsUseInstantCash: fbIsUseInstantCash,
      fsProductBrandCode: this.state.currentOffer.fsVouProductBrdCode,
      fiAdId: this.state.currentOffer.fiAdId,
      fiQuantity: this.state.fiVoucherQty,
      fiDenomination: this.state.fiVoucherSelectedAmount,
      fiUserId: foGlobalSetting.fiUserId,
      fiFinalPaidTotal: voucherDetails.finalAmount,
      fiRedeemCash: voucherDetails.fiRedeemCash,
      fsUserContact: localStorage.getItem("userPhone"),
      fsType: "purchase-voucher",
    };
  }


  render() {
    var foGlobalSetting = JSON.parse(localStorage.getItem("foGlobalSetting"));

    /* No Internet */
    if (!navigator.onLine) {
      return <InternetNotAvailable />;
    }

    var startQuizLink =
      "/start-quiz/" + this.state.adId + "/" + this.state.locationId;
    var isLoaderOrError = "";
    if (this.state.isLoading === false) {
      if (this.state.currentOffer.fbIsEngageLimitOver === true) {
        isLoaderOrError = (
          <Alert severity="error">Sorry, No data found on timeline.</Alert>
        );
      }
    } else {
      isLoaderOrError = (
        <div className="apiCallLoader">
          <CircularProgress color="secondary" />
        </div>
      );
    }

    if (isLoaderOrError !== "") {
      return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
        >
          <div className="AppDashboard screenAdDetails">
            <div className="adDetailsImage" style={{ height: "50px" }}>
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="loaderOrError">{isLoaderOrError}</div>
          </div>
        </motion.div>
      );
    }

    var CouponBg = config.getProviderSetting().fsCouponImg;

    var locationsListHtml = "";

    var shopClasses = "Coupon ShopOnline box-shadow active";

    var {
      finalAmount,
      fiMyCashPayable,
      fiRedeemCash,
      fiTotalAmount,
      fsDiscountUpTo,
    } = this.calculatePayment();

    var fsVoucherHtml = "";
    if (this.state.fbIsVocuherLoader) {
      fsVoucherHtml = (
        <div className="apiCallLoader">
          <CircularProgress color="secondary" />
        </div>
      );
    } else if (this.state.foVoucherDetails.length > 0) {
      var voucherCalculatation = this.calculatePayment();
      var fiTotalInstantCash = 0;
      if (
        this.state.currentOffer !== "" &&
        this.state.currentOffer !== undefined
      ) {
        fiTotalInstantCash =
          this.state.currentOffer.foInstantCash.fiTotalUsableCash;
      }

      let userTotalMyCash =
        parseInt(foGlobalSetting.fiUsrTotalMyCash) + parseInt(fiMyCashPayable);


      // Buy Mycash Added On Payable Items
      let userTotalCashInWallet = parseInt(foGlobalSetting.fiUsrTotalMyCash);
      let fiMinBuyMyCash = parseInt(foGlobalSetting.fiMinBuyMyCash);
      let fiPayableAmountForMinBuyMyCash = parseInt(foGlobalSetting.fiPayableAmountForMinBuyMyCash);
      let requiredMore = Math.max(
        fiMyCashPayable - userTotalCashInWallet,
        0
      );
      let minBuyMyCash = Math.ceil(requiredMore / fiMinBuyMyCash) * fiMinBuyMyCash;
      let transferCost = (minBuyMyCash / fiMinBuyMyCash) * fiPayableAmountForMinBuyMyCash;
      let totalPayable = fiTotalAmount + transferCost;
      // Buy Mycash Added On Payable Items

      var fsVoucherSelection = (
        <Select
          onChange={(e) => {
            this.setState({ fiVoucherSelectedAmount: e.target.value });
          }}
          name="voucher-selection"
        >
          {this.state.foVoucherDominations.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </Select>
      );
      var fsVoucherQty = (
        <Select
          onChange={(e) => {
            this.setState({ fiVoucherQty: parseInt(e.target.value) });
          }}
          name="voucher-qty"
        >
          {[1, 2, 3, 4, 5].map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </Select>
      );
      fsVoucherHtml = (

        <div >
          <div className="buy-now-giftcard-slabs MuiPaper-elevation1 " >
            <div></div>
            <table className="table-striped" >
              <thead>
                <tr className="voucher-checkout">
                  <th>
                    Voucher Credit <br /> <span>(Choose Amount)</span>
                  </th>
                  <th>Quantity <br /> <span>(Choose #)</span></th>


                  <th style={{ paddingLeft: "4px", textAlign: "right" }}> Store Credit <br /> <span>(Spend Worth)</span></th>

                </tr>

              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="voucher-selection">
                      {fsVoucherSelection}
                    </div>
                  </td>
                  <td>
                    <div className="voucher-selections">{fsVoucherQty}</div>
                  </td>
                  <td className="text-center">
                    <div style={{ fontWeight: "600" }}>Rs. {finalAmount}</div>
                  </td>
                </tr>
                {requiredMore > 0 ? (
                  <tr>
                    <td
                      className="voucher-mycash"
                      colSpan={2}
                      style={{ padding: "10px" }}
                    >
                      <span>Your {config.getGlobalCashbackString()} Balance is {userTotalCashInWallet} Only</span>
                      <div className="max-use-instat-balance">
                        <div>
                        We need {requiredMore} more to get the full cashback  <br />
                        Buy min {minBuyMyCash}{" "} {config.getGlobalCashbackString()}, cost of Rs {transferCost}, will be added<br />  In your voucher payment

                        </div>
                      </div>

                    </td>
                    <td className="text-center" style={{ fontWeight: "600", padding: "10px" }}>
                      Rs. {fiMyCashPayable}
                    </td>
                  </tr>
                ) : (
                  <tr style={{ padding: "10px" }}>
                    <td
                      className="voucher-mycash"
                      colSpan={2}
                      style={{ padding: "10px" }}
                    >
                      <span >Auto Applied Maximum "{config.getGlobalCashbackString()}" </span>
                      <div className="max-use-instat-balance">
                        <div>
                          Your "{config.getGlobalCashbackString()}" converts to instant CashBack,
                          <br />
                          Always Have Enough Mycash Balance

                        </div>
                      </div>

                    </td>
                    <td className="text-center" style={{ fontWeight: "600", padding: "10px" }}>
                      Rs. {fiMyCashPayable}
                    </td>
                  </tr>
                )}
                <tr>
                  {fiTotalInstantCash > 0 ? (
                    <td className="voucher-mycash" colSpan={2} style={{ padding: "10px" }}>
                      <span>
                        <input
                          type="checkbox"
                          name="isUseInstantCash"
                          checked={this.state.isUseInstantCash}

                          className="custom-checkbox"
                          onChange={(e) => {
                            this.setState({
                              [e.target.name]: e.currentTarget.checked,
                            });
                          }}
                        />
                        <b style={{ verticalAlign: "top", paddingLeft:"5px" }}>Use Wallet Balance (Rs. {fiRedeemCash})</b></span>
                      <div className="max-use-instat-cash">
                        <div>
                          Earned Cashback - You can use full amount
                          <br />
                          Bonus Cash -{" "}
                          {this.state.currentOffer.fiMaxInstaCashPerCanUse}% of the
                          voucher value only
                        </div>
                      </div>

                    </td>
                  ) : (
                    <td className="voucher-mycash" colSpan={2} style={{ padding: "10px" }}>
                      You can't use any cashback Balance ({fiTotalInstantCash})
                    </td>
                  )}
                  <td className="text-center" style={{ fontWeight: "600", padding: "10px" }}>
                    Rs. {fiRedeemCash}
                  </td>
                </tr>
                <tr>
                  <td
                    className="voucher-mycash"
                    colSpan={3}
                    style={{ verticalAlign: "top" }}
                  >
                    <div>
                      <span style={{'marginTop':'6px','marginBottom':'-4px','display':'block'}}><b>Instant Cashback :
                        Rs. {fiMyCashPayable}</b> </span>
                      <div className="max-use-instat-balance">
                        <div>
                          (Use full amount in next buy)
                        </div>
                      </div>
                    </div>

                    <div style={{'textAlign':'right','font-size':'var(--font-big)', 'margin-right':'-10px'}}>
                      <b>Pay Only: Rs. {totalPayable}</b>
                  
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          {this.state.foPurchaseDetails.fbIsError === true ? (
            <div style={{ marginTop: "15px" }}>
              <Alert severity="error">
                {this.state.foPurchaseDetails.fsMessage}
              </Alert>
            </div>
          ) : (
            ""
          )}


          <div style={{ marginTop: "15px" }}>
            <PaymentMethodForVoucher
            totalPayable={totalPayable}
            onPaymentMethodChange={this.handlePaymentMethodChange}
              hideLoader={() => this.setState({ showPaymentLoader: false })}
              placeVoucherOrder={this.placeVoucherOrder}
              orderDetails={this.calculateOrderDetails()} /* Moved order details to a method */
              openLogin={() => { this.setState({ fbIsDevicePopupEnable: true }) }}
              discountTotal={parseInt(fsDiscountUpTo) + parseInt(this.state.currentOffer.fsFlatCashBackPer)}
              minBuyMyCash={minBuyMyCash}
              myCashCharge={transferCost}
              backToMain={() => {
                this.setState({
                  fbIsOpenPaymentMethod: false,
                  showPaymentLoader: false,
                  openRedirectPopup: true,
                });
              }}
              reEnablePayment={() => {
                this.setState({
                  fbIsOpenPaymentMethod: false,
                  showPaymentLoader: false,
                });
              }}
             
            />
          </div>
        </div>

      );
    } else {
      fsVoucherHtml = (
        <Alert severity="error">
          Sorry, no coupon found, Please try with other offer.
        </Alert>
      );
    }

    /* Open Payment Method */
    var PaymentMethodHtml = "";
    if (this.state.fbIsOpenPaymentMethod) {
      var { fiTotalAmount, fiRedeemCash, fbIsUseInstantCash } = this.calculatePayment();
      var foGlobalSetting = JSON.parse(localStorage.getItem("foGlobalSetting"));
      var voucherDetails = this.calculatePayment();

      var orderDetails = {
        // fiTotalAmount: voucherCalculatation.fiTotalAmount,
        // fiRedeemCash: voucherCalculatation.fiRedeemCash,
        fsDescription: this.state.currentOffer.fsAdName + " Giftcard Purchase",

        /* For Just Pay */
        fbIsUseInstantCash: fbIsUseInstantCash,
        fsProductBrandCode: this.state.currentOffer.fsVouProductBrdCode,
        fiAdId: this.state.currentOffer.fiAdId,
        fiQuantity: this.state.fiVoucherQty,
        fiDenomination: this.state.fiVoucherSelectedAmount,
        fiUserId: foGlobalSetting.fiUserId,
        fiFinalPaidTotal: voucherDetails.finalAmount,
        fiRedeemCash: voucherDetails.fiRedeemCash,
        fsUserContact: localStorage.getItem("userPhone"),
        fsType: "purchase-voucher",
      };
      PaymentMethodHtml = (
        <PaymentMethodForVoucher
          hideLoader={() => this.setState({ showPaymentLoader: false })}
          placeVoucherOrder={this.placeVoucherOrder}
          orderDetails={orderDetails}
          backToMain={() => {
            this.setState({
              fbIsOpenPaymentMethod: false,
              showPaymentLoader: false,
              openRedirectPopup: true,
            });
          }}
          reEnablePayment={() => {
            this.setState({
              fbIsOpenPaymentMethod: false,
              showPaymentLoader: false
            });
          }}
        ></PaymentMethodForVoucher>
      );
    }

    var foVoucherBanner = "";
    if (this.state.currentOffer.foCouponList?.length > 0) {
      foVoucherBanner = (
        <div className="amazon-slider">
          <Swiper>
            {this.state.currentOffer.foCouponList.map((offerItem, key) => {
              if (offerItem.fsCouponBanner === "") {
                return <></>;
              }
              return (
                <SwiperSlide key={key}>
                  <div className="slideItems">
                    <img src={offerItem.fsCouponBanner} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      );
    }
    const { innerWidth: width, innerHeight: height } = window;
    var deliveryPopupMessage =
      '<span style="font-size:13px !important;">\
        Wow! Your Cashack Rs.' +
      fiMyCashPayable +
      " has been<br/>\
        credited to your wallet. This is Real Cash<br/>\
        that you can Withdraw to your bank A/C.<br/><br/>\
        You can Leave it in your wallet to discount<br/>\
        your next shopping with us for any brand.<br/>\
        ";
    if (this.state.openRedirectPopup === true) {
      var deliveryPopupMessage =
        '<span style="font-size:13px !important;">\
        On Order Verified, <br/>\
        You can find your order in My Coupons<br/>\
        ';
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        {this.state.fbIsDevicePopupEnable ? (
          <DeviceConnectionPopup
            popupContent="To create your account for<br/>Cashback Credit"
            closeDevicePopup={() => {
              this.setState({ fbIsDevicePopupEnable: false });
            }}
          />
        ) : (
          ""
        )}
        {this.state.openVouSuccessPopup ? (
          <Confetti
            numberOfPieces={150}
            width={width}
            height={height}
            style={{ top: "305px" }}
          />
        ) : (
          ""
        )}
        {this.state.openVouSuccessPopup ? (
          <ContinuePopup
            popupButtonText={
              this.state.fbContinueLinkLoader
                ? "Please wait..."
                : this.state.fsPopupBtnTxt
            }
            popupTitle={this.state.fsPopupTitle}
            popupContent={deliveryPopupMessage}
            secondLineText=""
            handleClose={this.closePopupAndRedirect}
            openDialog={true}
          />
        ) : (
          ""
        )}
        {this.state.openRedirectPopup ? (
          <ContinuePopup
            popupButtonText={"Ok"}
            popupTitle={this.state.fsPopupTitle}
            popupContent={deliveryPopupMessage}
            secondLineText=""
            handleClose={() => this.closePopupAndRedirect("my-timeline")}
            openDialog={true}
          />
        ) : (
          ""
        )}
        {PaymentMethodHtml}
        <ToastContainer theme="colored" />
        {this.state.fbBuyVoucherLoader ? (
          <div className="buy-voucher-loader">
            <div className="apiCallLoader">
              <CircularProgress color="secondary" />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="AppDashboard markAsReadScreen">
          <div className="screenPadding">
            <div className="markAsReadBack">
              <span
                style={{ fontSize: "24px", marginTop: "-2px" }}
                className="material-icons-outlined"
                onClick={this.goBack}
              >
                keyboard_backspace
              </span>
            </div>
            <div className="MainTitle">
              <div className="onlineOfflineTitle">
                {this.state.currentOffer.fsMarkIsUsedFlow === "voucher-red"
                  ? "OFFLINE VOUCHER"
                  : "ONLINE VOUCHER"}
              </div>

            </div>

            <div className="newOnlineCouponDesign">
              <img src={CouponBg} />
              <img
                className="brandLogo"
                src={config.getImageUrl(this.state.currentOffer.fsAdLogo)}
              />
              <div className="offer-name">
                {this.state.currentOffer.fsAdName}
              </div>
              <div className="extra-text" style={{ fontSize: "14px" }}>
                + USE {config.getGlobalCashbackString().toUpperCase()}
              </div>
              <div className="upto-dis text-design">
                {fiTotalInstantCash > 0 &&
                  this.state.currentOffer.fiDefVouDenomination > 0 ? (
                  <span>Exact</span>
                ) : (
                  <span>UP TO</span>
                )}
                <span>{fsDiscountUpTo}</span>
                <span>BRAND</span>
                <span>DISCOUNT</span>
              </div>

              <div>
                <div className="upto-other text-design">
                  <span>BUY NOW</span>
                  <span>{this.state.currentOffer.fsFlatCashBack}</span>
                  <span>'INSTANT'</span>
                  <span>CASHBACK</span>
                </div>
                <div
                  className="max-cashback-amount"
                  style={{
                    fontSize: "10px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Your '{config.getGlobalCashbackString()}' WILL CONVERT TO
                  CASHBACK
                </div>
              </div>
            </div>
            {foVoucherBanner}
            
            <div className="offerListMain">
              {locationsListHtml}
              {/* {offerListHtml} */}
            </div>

            {fsVoucherHtml}
            <Card>
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: this.state.currentOffer.fsOfferDetails,
                }}
              ></div>
            </Card>

          </div>
        </div>
        
      </motion.div>
    );
  }
}
