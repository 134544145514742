import React, { Component } from "react";
import config from './config';
import { motion } from "framer-motion";
import PaymentMethod from "./PaymentMethod";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from '@material-ui/core/NativeSelect';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import InternetNotAvailable from './InternetNotAvailable';
import Card from '@material-ui/core/Card';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import ContinuePopup from "./popup/ContinuePopup";

export default class BuyMyCashDetails extends Component {
  constructor(props) {
    super(props)
    var isQuizSubmitted = false;
    var quizResponse = "";
    if (this.props.location.state !== undefined) {
      // console.log(this.props.location.state);
      if ('marketingAd' in this.props.location.state) {
        console.log('Marketing Ad Redirected');
        localStorage.setItem('isAdDetailBackToMap', true);
      } else {
        isQuizSubmitted = true;
        quizResponse = this.props.location.state.response;
      }
    }
    // console.log('construct',this.props.location.state);
    this.state = {
      adId: this.props.match.params.adId,
      locationId: this.props.match.params.locationId,
      isLoading: true,
      currentOffer: "",
      isAbleToEngage: true,
      adDistance: 0,

      isQuizSubmitted: isQuizSubmitted,
      quizResponse: quizResponse,
      userDetails: JSON.parse(localStorage.getItem('userProfile')),

      foVoucherDetails: [],
      fbIsVocuherLoader: true,
      fiVoucherSelectedAmount: 0,
      fiVoucherQty: 1,
      foVoucherDominations: [],
      fbBuyMyCashLoader: false,
      showPaymentLoader: false,
      fbIsOpenPaymentMethod: false,
      fsPopupTitle: '',
      openRedirectPopup: false,
    }

    this.goBack = this.goBack.bind(this);
    this.popupGoBack = this.popupGoBack.bind(this);
    this.closeAdResponsePopup = this.closeAdResponsePopup.bind(this);
    this.openNotOnRadiusPopup = this.openNotOnRadiusPopup.bind(this);
    this.placeBuyMyCashOrder = this.placeBuyMyCashOrder.bind(this);
    this.closePopupAndRedirect = this.closePopupAndRedirect.bind(this);
  }

  closePopupAndRedirect(goToLink = '') {
    this.props.history.push({
      pathname: '/' + goToLink,
    });
  }


  closeAdResponsePopup() {
    this.setState({
      isQuizSubmitted: false
    });
  }

  openNotOnRadiusPopup() {
    this.setState({
      isAbleToEngage: false
    });
  }

  componentDidMount(props) {

    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

    let mid = foGlobalSetting.fsPaytmMID; // Merchant ID
    var url = foGlobalSetting.fbPaytmProd ? 'securegw.paytm.in' : 'securegw-stage.paytm.in';

    const script = document.createElement("script");
    script.async = false;
    script.src = 'https://' + url + "/merchantpgpui/checkoutjs/merchants/" + mid + '.js';
    document.body.appendChild(script);


    var requestParams = {
      "fsAction": "getOfferDetails",
      "fiAdId": this.state.adId,
      "fiLocationId": this.state.locationId
    };
    config.fetchInformation(requestParams).then((responseJson) => {
      var distance = 0;
      if (responseJson.foOfferDetails.flCoverageRadius !== "") {
        var userLat = localStorage.getItem('userLat');
        var userLong = localStorage.getItem('userLong');
        var adLat = responseJson.foOfferDetails.foLocationList[0].ffLatitude;
        var adLong = responseJson.foOfferDetails.foLocationList[0].ffLongitude;
        distance = config.getDistanceFromLatLonInKm(userLat, userLong, adLat, adLong);
      }

      this.setState({
        currentOffer: responseJson.foOfferDetails,
        isLoading: false,
        adDistance: distance
      });

    });

  }

  placeBuyMyCashOrder(paymentObject) {
    console.log('paymentObject ', paymentObject.razorpay_payment_id);
    console.log('status in voucher', paymentObject.STATUS);
    if (paymentObject.STATUS === "TXN_SUCCESS") {
      console.log('paymentObject', paymentObject);
      this.setState({ fbBuyMyCashLoader: true });
      // var requestParams = {
      //   "fsAction": "purchaseMyCash",
      //   "fiAdId": this.state.currentOffer.fiAdId,
      //   "fiMyCashAmount": this.state.currentOffer.fiQuizReward,
      //   "fiMyCashPayableAmt": this.state.currentOffer.fiMyCashPayableAmt,
      //   "foTransactionLog": paymentObject,
      // };
      // config.fetchInformation(requestParams).then((responseJson) => {
      //   if (responseJson.fbIsError) {
      //     toast.error(requestParams.fsMessage, { position: "top-center", autoClose: 8000 });
      //     this.setState({ fbBuyMyCashLoader: false });
      //   } else {
      //     var goToCouponLink = '/wallet/';
      //     this.props.history.push({ pathname: goToCouponLink });
      //   }
      // });
    } else {
      this.setState({ fbIsOpenPaymentMethod: false, showPaymentLoader: false });
      toast.error(paymentObject.RESPMSG, { position: "top-center", autoClose: 8000 });
      return;
    }
  }

  goBack() {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: '/',
    });
  }

  popupGoBack() {
    this.props.history.push({
      pathname: '/',
    });
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable />
      )
    }

    var startQuizLink = "/start-quiz/" + this.state.adId + "/" + this.state.locationId;
    var isLoaderOrError = "";
    if (this.state.isLoading === false) {
      if (this.state.currentOffer.fbIsEngageLimitOver === true) {
        isLoaderOrError = <Alert severity="error">Sorry, No data found on timeline.</Alert>
      }
    } else {
      isLoaderOrError = <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
    }

    if (isLoaderOrError !== "") {
      return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
        >
          <div className="AppDashboard screenAdDetails">
            <div className="adDetailsImage" style={{ 'height': '50px' }}>
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="loaderOrError">
              {isLoaderOrError}
            </div>
          </div>
        </motion.div>
      )
    }

    var CouponBg = config.getProviderSetting().fsCouponImg;

    var shopClasses = 'Coupon ShopOnline box-shadow active';

    var fsFooterHTML = <div>
      <div className={shopClasses} style={{ marginTop: '15px' }}>
        {this.state.fbBuyMyCashLoader ?
          <div rel="noopener noreferrer" target="_blank">Please Wait...</div>
          :
          <div rel="noopener noreferrer" onClick={() => this.setState({ fbIsOpenPaymentMethod: true, showPaymentLoader: true })} target="_blank">{this.state.showPaymentLoader ? 'LOADING...' : 'PAY NOW'}</div>
        }
      </div>
      <div className="note-red-color">Try use UPI option for best discounts & zero transaction cost</div>
    </div>

    /* Open Payment Method */
    var PaymentMethodHtml = "";
    if (this.state.fbIsOpenPaymentMethod) {
      var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
      var orderDetails = {
        fiTotalAmount: this.state.currentOffer.fiMyCashPayableAmt,
        fsDescription: this.state.currentOffer.fsAdName + ' ' + config.getCashbackString().toUpperCase() + ' Purchase',

        /* For Just Pay */
        "fiUserId": foGlobalSetting.fiUserId,
        "fiAdId": this.state.currentOffer.fiAdId,
        "fiMyCashAmount": this.state.currentOffer.fiQuizReward,
        "fiMyCashPayableAmt": this.state.currentOffer.fiMyCashPayableAmt,
        "fsUserContact": localStorage.getItem('userPhone'),
        "fsType": 'purchase-mycash'
      };

      PaymentMethodHtml = <PaymentMethod hideLoader={() => this.setState({ showPaymentLoader: false })}
        placeVoucherOrder={this.placeBuyMyCashOrder} orderDetails={orderDetails}
        backToMain={() => { this.setState({ fbIsOpenPaymentMethod: false, showPaymentLoader: false, openRedirectPopup:true }) }}></PaymentMethod>
    }

    if (this.state.openRedirectPopup === true) {
      var deliveryPopupMessage = '<span style="font-size:13px !important;">\
        You Just Paid Rs '+this.state.currentOffer.fiMyCashPayableAmt+' for MyCash, You<br/>\
        got Rs '+this.state.currentOffer.fiQuizReward+' MyCash Credit,Shop to<br/>\
        convert your MyCash to Instant<br/>\
        Cashbacks Now !<br/>\
        ';
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        {this.state.openRedirectPopup ?
          <ContinuePopup popupButtonText={'Check Wallet'}
            popupTitle={this.state.fsPopupTitle}
            popupContent={deliveryPopupMessage}
            secondLineText=""
            handleClose={() => this.closePopupAndRedirect('history-mycash')} openDialog={true} />
          : ''}
        {PaymentMethodHtml}
        <ToastContainer theme="colored" />
        {this.state.fbBuyMyCashLoader ? <div className="buy-voucher-loader"><div className="apiCallLoader"><CircularProgress color="secondary" /></div></div> : ''}
        <div className="AppDashboard markAsReadScreen">
          <div className="screenPadding">
            <div className="markAsReadBack">
              <span style={{ 'fontSize': '24px', 'marginTop': '-2px' }} className="material-icons-outlined" onClick={this.goBack}>keyboard_backspace</span>
            </div>
            <div className="MainTitle">
              <div className="onlineOfflineTitle">BUY MYCASH</div>
            </div>

            <div className="newOnlineCouponDesign">
              <img src={CouponBg} />
              <img className="brandLogo" src={config.getImageUrl(this.state.currentOffer.fsAdLogo)} />
              <div className="offer-name">{this.state.currentOffer.fsAdName}</div>
              <div className="extra-text" style={{ 'fontSize': '14px' }}>BUY NOW</div>
              <div className="upto-dis text-design">
                <span>{config.getGlobalCashbackString().toUpperCase()} CREDIT</span>
                <span>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.state.currentOffer.fiQuizReward)}</span>
                <span style={{ 'fontSize': '.6em' }}>1 {config.getGlobalCashbackString().toUpperCase()} = 1 Rs Discount </span>
              </div>

              <div>
                <div className="upto-other text-design">
                  <span>PAY AMOUNT</span>
                  <span>Rs {this.state.currentOffer.fiMyCashPayableAmt}/-</span>
                  <span style={{ 'fontSize': '.7em' }}>(USE UPI or CARDS)</span>
                </div>
                <div className="max-cashback-amount" style={{ 'fontSize': '10px', 'textTransform': 'uppercase', 'fontWeight': 'bold' }}>Your '{config.getGlobalCashbackString()}' AUTO CRDITES IN WALLET</div>
              </div>
            </div>
            <Card>
              <div className="Description" dangerouslySetInnerHTML={{ __html: this.state.currentOffer.fsOfferDetails }}>
              </div>
            </Card>

            {fsFooterHTML}

          </div>
        </div>
      </motion.div>
    )

  }
}