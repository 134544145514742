import React, { Component } from 'react';
import config from './config';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

class PWASetpsAndroid extends Component {

    render() {
        return (
            <div className="spashScreen">
                <div className="whiteBg">
                    <div className="AppLoginScreen">

                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: false }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        >
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/android/0.jpg'} title="0" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/android/1.jpg'} title="1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/android/2.jpg'} title="2" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/android/3.jpg'} title="3" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/android/4.jpg'} title="4" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }
}

export default PWASetpsAndroid;