import React, { Component } from "react";
import config from './config';
import GoBackPopup from './popup/GoBackPopup';
import ContinuePopup from './popup/ContinuePopup';

import Button from '@material-ui/core/Button';
import { motion } from "framer-motion";

import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import YouTube from 'react-youtube';
import QrReader from 'react-qr-reader';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import InternetNotAvailable from './InternetNotAvailable';

import Card from '@material-ui/core/Card';


export default class StartQuiz extends Component {
  constructor(props) {
    super(props)

    var quizTimeLimit = localStorage.getItem('foGlobalSetting');
    quizTimeLimit = JSON.parse(quizTimeLimit);
    this.state = {
      adId: this.props.match.params.adId,
      locationId: this.props.match.params.locationId,
      isLoading: true,
      isYoutubeView: false,
      youtubeVideId: "",
      isScanStart: false,
      apiResponse: "",
      foQuestions: [],
      currentQuestion: 1,
      isOpenPopup: false,
      goBackContent: '',
      quizResponse: "",
      quizTimeInterval: 0,
      isSubmitClicked: false,
      quizTimeLimit: quizTimeLimit.fiQuizTimeInterval,

      isOpenContinuePopup: false,
      continuePopupContent: '',
    }

    this.goBack = this.goBack.bind(this);
    this.loadNextQuestion = this.loadNextQuestion.bind(this);
    this.multiChoiceTextChange = this.multiChoiceTextChange.bind(this);
    this.isVideoEnded = this.isVideoEnded.bind(this);
    this.startYoutubeVideo = this.startYoutubeVideo.bind(this);
    this.startYouTubeVideoForMCQ = this.startYouTubeVideoForMCQ.bind(this);
    this.submitQuizToServer = this.submitQuizToServer.bind(this);
    this.startScanQR = this.startScanQR.bind(this);
    this.scanResultFound = this.scanResultFound.bind(this);
    this.scanErrorFound = this.scanErrorFound.bind(this);
    this.openGiveAnswerPopup = this.openGiveAnswerPopup.bind(this);
    this.closeContinuePopup = this.closeContinuePopup.bind(this);
    this.callNativeCamera = this.callNativeCamera.bind(this);
    this.isCampainQuiz = this.isCampainQuiz.bind(this);

  }

  callNativeCamera(){
    try {
      if(localStorage.getItem('fiDeviceType')==='0'){
        window.Android.scanBarcode();
      }else if(localStorage.getItem('fiDeviceType')==='1'){
        window.webkit.messageHandlers.callbackHandler.postMessage("scanBarcode");
      }
    } catch (e) {
      console.log(e);
    }
  }

  openGiveAnswerPopup(){
    this.setState({
      'isOpenContinuePopup':true,
      'continuePopupContent':'All Answer are must to solve Quiz,<br/>Pls select right answer.'
    })
  }

  closeContinuePopup(){
    this.setState({
      'isOpenContinuePopup':false
    })
  }


  startScanQR() {
    this.setState({ isScanStart: true })
  }

  submitQuizToServer() {
    this.setState({ isSubmitClicked: true });
    var quiz_answered = [];
    var questionList = this.state.foQuestions;

    questionList.map((quizItems) => {
      quiz_answered.push({
        'fiQuizId': quizItems.fiQuizId,
        'fsAnswer': quizItems.givenAnswer,
        'fiAnsInterval': quizItems.quizTimeInterval
      });
      return (<></>);
    });

    var requestParams = {
      "fsAction":"submitQuiz",
      "fiAdId": this.state.adId,
      "fiLocationId": this.state.locationId,
      // "device_unique_id":3,
      "foQuizAnswerList": quiz_answered
    };
    config.fetchInformation(requestParams).then((responseJson)=>{
        if (responseJson.fbIsError === true) {
          this.setState({
            isOpenPopup: true,
            quizResponse: responseJson,
            goBackContent: responseJson.fsMessage, 
            currentQuestion: 1
          })
        } else {
          localStorage.setItem('fiHighlightActivity',responseJson.fiAddedActivityId);
          
          var redirectToWithPopup = '/my-timeline/'+responseJson.fiAddedActivityId;
          this.props.history.push({ 
            pathname: redirectToWithPopup,
            state: { isQuizSubmitted: true, response: responseJson }
          });

          // var redirectToWithPopup = '/add-details/' + this.state.adId + '/' + this.state.locationId;
          // this.props.history.push({ 
          //   pathname: redirectToWithPopup,
          //   state: { isQuizSubmitted: true, response: responseJson }
          // });
        }
    });

  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidMount() {

    document.nativeQRiOSScanReceiver = (QRCode) => {
      console.log('Scanned QR : '+QRCode);
      this.scanResultFound(QRCode);
    }

    window.nativeQRScanReceiver = (QRCode) => {
      console.log('Scanned QR : '+QRCode);
      this.scanResultFound(QRCode);
    }

    this.timeout = setInterval(() => {
      this.setState({
        quizTimeInterval: this.state.quizTimeInterval + 1
      });
    }, 1000);

    var requestParams = {
      "fsAction":"getQuizDetails",
      "fiAdId": this.state.adId,
      "fiLocationId": this.state.locationId
    };
    config.fetchInformation(requestParams).then((responseJson)=>{
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
        foQuestions: responseJson.foQuizList
      });
    });
  }

  loadNextQuestion() {
    /* Check first question currect */
    if (this.state.currentQuestion === 1) {
      var foUpdateQuestion = this.state.foQuestions;
      var currentFullQuestion = foUpdateQuestion[this.state.currentQuestion - 1];
      if (parseInt(this.state.apiResponse.fsFirstQuestionAnswer) === parseInt(currentFullQuestion.givenAnswer) || localStorage.getItem('isAdDetailBackToMap')==='true') {
        this.setState({
          currentQuestion: this.state.currentQuestion + 1,
          isScanStart: false,
          QRInvalidError:false,
          quizTimeInterval: 0,
          foUpdateQuestion:foUpdateQuestion
        });
      } else {
        this.setState({
          currentQuestion: 1,
          isScanStart: false,
          isOpenPopup: true,
          QRInvalidError:false,
          goBackContent: 'Your answer is wrong & the quiz is terminate.',
          quizTimeInterval: 0
        });
      }
    } else {
      this.setState({
        currentQuestion: this.state.currentQuestion + 1,
        isScanStart: false,
        quizTimeInterval: 0,
        QRInvalidError:false,
        foUpdateQuestion:foUpdateQuestion
      });
    }
  }

  goBack() {
    if(localStorage.getItem('isAdDetailBackToMap')==='true'){
      localStorage.removeItem('isAdDetailBackToMap');
      localStorage.setItem('isMarketingEnagged','true');
      this.props.history.push({
        pathname: '/',
      });
    }else{
      this.props.history.goBack();
    }
  }

  multiChoiceTextChange = (event) => {
    var foUpdateQuestion = this.state.foQuestions;
    var currentFullQuestion = foUpdateQuestion[this.state.currentQuestion - 1];
    if (currentFullQuestion.answerType === "multiChoice") {
      foUpdateQuestion[this.state.currentQuestion - 1].givenAnswer = currentFullQuestion.foOptionsWithIndexes[event.target.value].fiIndex;
      foUpdateQuestion[this.state.currentQuestion - 1].activeIndex = event.target.value;
    } else {
      foUpdateQuestion[this.state.currentQuestion - 1].givenAnswer = event.target.value;
    }
    foUpdateQuestion[this.state.currentQuestion - 1].quizTimeInterval = this.state.quizTimeInterval;
    // console.log(foUpdateQuestion);
    this.setState({
      foQuestions: foUpdateQuestion
    })
  }

  startYouTubeVideoForMCQ(videoLink) {
    console.log(videoLink);
    var youtubeVideId = "";
    if (videoLink.match(/(\?|&)v=([^&#]+)/)) {
      youtubeVideId = videoLink.match(/(\?|&)v=([^&#]+)/);
      youtubeVideId = youtubeVideId.pop();
    } else if (videoLink.match(/(\.be\/)+([^\/]+)/)) {
      youtubeVideId = videoLink.match(/(\.be\/)+([^\/]+)/);
      youtubeVideId = youtubeVideId.pop();
    } else if (videoLink.match(/(\embed\/)+([^\/]+)/)) {
      youtubeVideId = videoLink.match(/(\embed\/)+([^\/]+)/);
      youtubeVideId = youtubeVideId.pop().replace('?rel=0', '');
    }

    console.log(youtubeVideId);

    this.setState({
      youtubeVideId: youtubeVideId,
      isYoutubeView: true
    })
  }

  isVideoEnded() {
    var foUpdateQuestion = this.state.foQuestions;
    foUpdateQuestion[this.state.currentQuestion - 1].givenAnswer = foUpdateQuestion[this.state.currentQuestion - 1].foOptionsWithIndexes[0].option;
    foUpdateQuestion[this.state.currentQuestion - 1].quizTimeInterval = this.state.quizTimeInterval;
    // console.log(foUpdateQuestion);
    this.setState({
      foQuestions: foUpdateQuestion,
      isYoutubeView: false,
      quizTimeInterval: 0
    });
  }

  isCampainQuiz(link,base64Link) {

    try {
      if(localStorage.getItem('fiDeviceType')==='0'){
        window.Android.openCampaignLink(link);
      }else if(localStorage.getItem('fiDeviceType')==='1'){
        var openCampaignLink = 'openCampaignLink-----'+link;
        window.webkit.messageHandlers.callbackHandler.postMessage(openCampaignLink);
      }else{
        link = base64Link;
        window.open(link, '_blank');
      }
    } catch (e) {
      console.log(e);
    }

    var foUpdateQuestion = this.state.foQuestions;
    foUpdateQuestion[this.state.currentQuestion - 1].givenAnswer = foUpdateQuestion[this.state.currentQuestion - 1].foOptionsWithIndexes[0].option;
    foUpdateQuestion[this.state.currentQuestion - 1].quizTimeInterval = 10;
    // console.log(foUpdateQuestion);
    this.setState({
      foQuestions: foUpdateQuestion,
      quizTimeInterval: 0
    });
  }

  scanErrorFound() {
    //alert('Error on scan');
    this.setState({
      isOpenPopup: true,
      goBackContent: "You have issue on camera permission."
    });
  }

  scanResultFound = data => {
    if (data !== null) {
      var foUpdateQuestion = this.state.foQuestions;
      foUpdateQuestion[this.state.currentQuestion - 1].givenAnswer = data;
      foUpdateQuestion[this.state.currentQuestion - 1].quizTimeInterval = this.state.quizTimeInterval;
      // console.log(foUpdateQuestion);
      //alert(data);
      this.setState({
        foQuestions: foUpdateQuestion,
        isScanStart: true,
        quizTimeInterval: 0,
        QRInvalidError:false
      });
    }else{
      this.setState({
        QRInvalidError: true
      });
    }
  }


  startYoutubeVideo(event) {
    event.target.playVideo();
  }

  render() {
    var qrSampleImg = config.getProviderSetting().fsQRImg;
    var youTubeVideo = config.getProviderSetting().fsYouTube;
    /* No Internet */
    if(!navigator.onLine){
      return(
        <InternetNotAvailable/>
      )
    }

    /* logic for loader or error */
    var isLoaderOrError = "";
    if (this.state.isLoading === false) {
      if (this.state.apiResponse.fbIsError) {
        isLoaderOrError = <Alert severity="error">Sorry, No question found.</Alert>
      }
    } else {
      isLoaderOrError = <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
    }

    if (isLoaderOrError !== "") {
      return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
        >
          <div className="AppDashboard screenAdDetails">
            <div className="adDetailsImage" style={{ 'height': '50px' }}>
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="loaderOrError">
              {isLoaderOrError}
            </div>
          </div>
        </motion.div>
      )
    }
    /* logic for loader or error */

    var foQuestions = this.state.foQuestions;
    var apiResponse = this.state.apiResponse;

    console.log(foQuestions);
    var QuizDisplayHtml = "";
    var totalQuestions = foQuestions.length;
    var currentQuestionDetails = foQuestions[this.state.currentQuestion - 1];
    var currentQuestion = currentQuestionDetails.fsQuestion;
    var colorClass = "AppDashboard screenAdDetails quizDetails ";

    colorClass = colorClass + " colorRed";

    // Progress Bar Html
    var quizLimitTime = this.state.quizTimeLimit;
    quizLimitTime = quizLimitTime - this.state.quizTimeInterval;
    if (quizLimitTime <= 0) {
      quizLimitTime = 0;
    }
    var ProgressBarHtml =
      <div className="quizProgressBar">
        <CircularProgressbarWithChildren
          strokeWidth={12} counterClockwise={true} background="#ffffff"
          value={config.getPersontageValue(this.state.quizTimeLimit, quizLimitTime)}
        >
          <div className="timingSecond">{quizLimitTime}</div>
        </CircularProgressbarWithChildren>
      </div>;
    var isShowTimer = false;

    if (currentQuestionDetails.answerType === "multiChoice") {
      isShowTimer = true;
      console.log(currentQuestionDetails.givenAnswer !== undefined ? parseInt(currentQuestionDetails.activeIndex) : "");
      QuizDisplayHtml =
        <div className="QTypeMCQ">
          <div className="QRCustomDesign">
            <RadioGroup aria-label="gender" key={currentQuestionDetails.fiQuizId} value={currentQuestionDetails.givenAnswer !== undefined ? parseInt(currentQuestionDetails.activeIndex) : ""}>
              {currentQuestionDetails.foOptionsWithIndexes.map((singleQuestion, i) => {
                var className = 'singleRadioButton ';
                var radioName = 'radio_button_unchecked';
                if (parseInt(currentQuestionDetails.activeIndex) === i) {
                  className = className + 'activeRadio';
                  radioName = 'radio_button_checked';
                }

                if (singleQuestion.fsValue === "") {
                  return (<></>);
                }
                var radioName = 'mcqRadioButton'+currentQuestionDetails.fiQuizId;
                return (
                  <div className={className} key={i}>
                    <label>
                      <span className="material-icons-outlined">
                        {radioName}
                      </span>
                      <input type="radio" onChange={this.multiChoiceTextChange} name={radioName} value={i} />
                      <span>{singleQuestion.fsValue}</span>
                    </label>
                  </div>
                )
              })}
            </RadioGroup>
          </div>
        </div>
    }

    if (currentQuestionDetails.answerType === "barCode" && 
    (localStorage.getItem('fiDeviceType')==='0' || localStorage.getItem('fiDeviceType')==='1')) {
      QuizDisplayHtml =
        <div className="MCQDisplayImage isiOSWebView QRTypeMCQ mt-30">
          <div className="qrContainer">
              <div className="QrScanLabel">Please Scan QR-Code</div>
              <div className="handleIOSDesign">
                <QrReader
                  delay={200}
                  legacyMode={true}
                  onError={this.scanErrorFound}
                  onScan={this.scanResultFound}
                  style={{ width: '100%' }}
                />
                <img onClick={this.callNativeCamera} className="sampleImage" alt="Qrcode" src={qrSampleImg} />
              </div>
              {this.state.QRInvalidError?<div className="QRInvalidError">QR is invalid.</div>:''}
            </div>
        </div>
    }else if (currentQuestionDetails.answerType === "barCode") {
      QuizDisplayHtml =
        <div className="MCQDisplayImage QRTypeMCQ mt-30">
          {this.state.isScanStart ?
            <img onClick={this.startScanQR} alt="Qrcode" src={qrSampleImg} />
            :
            <div className="qrContainer">
              <div className="QrScanLabel">Please Scan QR-Code</div>
              <QrReader
                delay={200}
                onError={this.scanErrorFound}
                onScan={this.scanResultFound}
                style={{ width: '100%' }}
              />
            </div>
          }
        </div>
    }

    if (currentQuestionDetails.answerType === "youtubeVideo") {
      QuizDisplayHtml =
        <div className="MCQDisplayImage YoutubeTypeMCQ mt-30">
          <img alt="Youtube" onClick={() => this.startYouTubeVideoForMCQ(currentQuestionDetails.foOptionsWithIndexes[0])} src={youTubeVideo} />
        </div>
    }

    if (currentQuestionDetails.answerType === "texable") {
      isShowTimer = true;
      QuizDisplayHtml =
        <div className="MCQDisplayImage TextTypeMCQ mt-30" key={currentQuestionDetails.fiQuizId}>
          <div className="withLabel">
            <label>Answer text...</label>
            <TextField name="AnsText" onChange={this.multiChoiceTextChange} id="outlined-otp" label="" variant="outlined" />
          </div>
        </div>
    }

    if (currentQuestionDetails.answerType === "campaign") {
      var campaign = currentQuestionDetails.foCampainDetails;
      var campainLink = currentQuestionDetails.foCampainDetails.link;
      var base64Link = currentQuestionDetails.foCampainDetails.base64_link;
      
      //console.log(campainLink);
      QuizDisplayHtml =
        <div className="MCQDisplayImage appInstall mt-30">
          <div className="details">
            <img alt="App Install" src={config.convertHttps(currentQuestionDetails.foCampainDetails.image)} />
            <div>{currentQuestionDetails.foCampainDetails.title}</div>
          </div>
          <div className="button">
              <Button variant="contained" color="secondary" onClick={()=>this.isCampainQuiz(campainLink,base64Link)}>
                Continue
              </Button>
          </div>
        </div>
    }

    if (this.state.isYoutubeView === true) {
      var opts = {
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          controls: 0,
          showinfo: 0
        },
      };
      return (
        <div className="AppContent youtubePopupView adDetailsImage isNormalView">
          <div className="goBackToMap">
            <span className="material-icons-outlined" onClick={this.isVideoEnded}>
              keyboard_backspace
              </span>
          </div>
          {/* <iframe width="734" height="409" src="https://www.youtube.com/embed/oK_rLUl2_8w?autoplay=0&showinfo=0&controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <YouTube videoId={this.state.youtubeVideId} opts={opts} onReady={this.startYoutubeVideo} onEnd={this.isVideoEnded} />
        </div>
      )
    }

    var isOpenPopupHtml = "";
    if (this.state.isOpenPopup === true) {
      isOpenPopupHtml = <GoBackPopup popupButtonText="Go Back" popupTitle="OOPS!" popupContent={this.state.goBackContent} goBack={this.goBack} openDialog={true} />
    }
 
    if (isOpenPopupHtml==="" && this.state.isOpenContinuePopup === true) {
      isOpenPopupHtml = 
      <ContinuePopup popupButtonText="Ok" popupTitle="OOPS!" popupContent={this.state.continuePopupContent} handleClose={this.closeContinuePopup} openDialog={true} />
    }

    var isDisabled = "";
    var isOpenNextOrSubmitPopup = false;
    if (this.state.isSubmitClicked === true) {      isDisabled = 'btnDisabled';     }
    if(currentQuestionDetails.givenAnswer === undefined){      isOpenNextOrSubmitPopup = true;     }

    var nextButtonShowLogic = <Button className={isDisabled} variant="contained" color="secondary" onClick={isOpenNextOrSubmitPopup?this.openGiveAnswerPopup:this.loadNextQuestion}>
    Next Question
    </Button>;
    if ((currentQuestionDetails.answerType === "campaign") && currentQuestionDetails.givenAnswer===undefined) {
      nextButtonShowLogic = '';
    }
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className={colorClass}>
          <div className="adDetailsImage">
              <img src={config.getImageUrl(apiResponse.fsAdBanner)} />
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
          </div>
          {/* <div className="adDetailsImage">
            <div className="cusveImage"></div>
            <div className="titleBar">
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
                </span>
              <div>{apiResponse.ad_name}</div>
            </div>
            <div className="QuestionTitle">
              {currentQuestion}
            </div>
            <div className="NumberCounter">
              {this.state.currentQuestion} of {totalQuestions}
            </div>
            {isShowTimer === true ? ProgressBarHtml : ''}
          </div> */}

          <div className="AppContent autoHeight isNormalView minHeight300">
            <Card>
              <div className="adBrandDetails">
                <div>
                  <img alt={apiResponse.ad_name} src={config.getImageUrl(apiResponse.fsAdLogo)} />
                </div>
                <div className="info">
                  <h3>{apiResponse.fsAdName}</h3>
                  <div><b>Answer To Win</b></div>
                </div>
                <div>
                  {isShowTimer === true ? ProgressBarHtml : ''}
                  <div className="outOf">{this.state.currentQuestion} OF {totalQuestions}</div>
                </div>
              </div>
              <div className="mt-10 quizDetailsCard">
                <div className="QuestionTitle">
                  {currentQuestion}
                </div>
                {QuizDisplayHtml}
                {isOpenPopupHtml}
              </div>
            </Card>
          </div>

          <div className="playQuiz mt-10">
            {this.state.currentQuestion === totalQuestions
              ?
              <Button className={isDisabled} variant="contained" color="secondary" onClick={isOpenNextOrSubmitPopup?this.openGiveAnswerPopup:this.submitQuizToServer}>
                Submit Quiz
            </Button>
              :
              nextButtonShowLogic
            }
          </div>

        </div>
      </motion.div>
    )
  }
}