import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function GoBackPopup(props) {
    return (
        
        <Dialog
          open={props.openDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup">
            <DialogTitle id="alert-dialog-slide-title">{props.popupTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {props.popupContent}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.goBack} variant="contained" color="primary">
                  {props.popupButtonText}
                </Button>
              </DialogActions>
          </div>
        </Dialog>

    );
}