import React, { Component } from "react";
import config from '../config';
import { motion } from "framer-motion";
import Card from '@material-ui/core/Card';
import TopBarTitle from '../TopBarTitle';

import MoreCashbackPopup from '../popup/MoreCashbackPopup';

import InternetNotAvailable from '../InternetNotAvailable';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdSense from 'react-adsense';

export default class InstantCashHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      apiResponse: '',
      isOpenPopup: false,
      cashbackType: '',
    }

    this.goBack = this.goBack.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }


  componentWillMount() {
    var requestParams = { "fsAction": "getInstantCashHistory" };
    config.fetchInformation(requestParams).then((responseJson) => {
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
      });
    });
  }

  goBack() {
    this.props.history.push({
      pathname: '/wallet',
    });
  }

  openPopup(cashbackType) {
    if (cashbackType === 'instant-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'my-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'pending-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    }else if (cashbackType === 'bonus-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'bonus-cash' });
    }
  }

  closePopup() {
    this.setState({ 'isOpenPopup': false, cashbackType: '' });
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable />
      )
    }

    var foInstantCashHistory = [];
    var foBonusCashHistory = [];
    if (this.state.apiResponse !== '') {
      foInstantCashHistory = this.state.apiResponse.foInstantCashHistory
      foBonusCashHistory = this.state.apiResponse.foBonusCashHistory
    }

    var NoDataFound = config.getProviderSetting().fsNoData;
    var reason = "No History Found.";
    var loader = <div className="apiCallLoader fullScreenLoader"><CircularProgress color="secondary" /></div>;
    return (
      <motion.div initial="initial" animate="in" exit="out" variants={config.pageAnimation} transition={config.pageTransition}>
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle={"Instant Cashback" + " History"} goBack={this.goBack} />
          </div>
          {this.state.isLoading?loader:
          <div className="AppContent isNormalView isWalletPage isTopBarOnTop">
            {this.state.isOpenPopup ? <MoreCashbackPopup popupButtonText='Close'
              secondLineText=""
              handleClose={this.closePopup}
              cashbackType={this.state.cashbackType}
              openDialog={true} /> : ''}
            <div className="timelineList walletList">
              {foInstantCashHistory.length > 0 ?
                <div className="singleCouponTimeLine MyCashTimeLine">
                  <div className="timeLineContent">
                    <div className="items2 mycash-count">
                      <h3 style={{ margin: '0' }}>{"Instant Cashback"}</h3>
                      <div className="adsPrice textRight walletTime colorGreen" style={{ position: 'unset', marginTop: '0' }}>Rs. {this.state.apiResponse.foInstantCash.fiCashback}</div>
                    </div>
                    <div className="historyTable">
                      {this.state.apiResponse.foInstantCashHistory.map((single, i) =>
                        
                        <div className="mycash-card instant-cash">
                          <div className="mycash-header">
                            <div>
                              <span>{config.replaceMyCash(single.fsTitle)}</span>
                              {(function () {
                                if (single.fsType === "debit") {
                                  return <span style={{ 'color': 'var(--red-color)' }} className="instant-cash-amt">Rs.{parseInt(single.fiAmount)}</span>;
                                } else if (single.fsType === "payment-request") {
                                  return <span style={{ 'color': 'var(--organge-color)' }} className="instant-cash-amt">Rs.{parseInt(single.fiAmount)}</span>;
                                } else {
                                  return <span style={{ 'color': 'var(--green-color)' }} className="instant-cash-amt">Rs.{parseInt(single.fiAmount)}</span>;
                                }
                              })()}
                            </div>
                          </div>
                          <div className="mycash-footer">
                            <div>
                              <div>
                                <span>Date: </span>
                                <span>{single.fdCreateDate}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                          
                      )}
                    </div>
                  </div>

                  {/* <div className="">
                      <AdSense.Google
                          client={config.adsense.clientId}
                          slot={config.adsense.slotId}
                          style={{ display: 'block' }}
                          format="auto"
                          responsive="true"
                      />
                  </div> */}
                </div>
                : ''}

                {foBonusCashHistory.length > 0 ?
                <div className="singleCouponTimeLine MyCashTimeLine">
                  <div className="timeLineContent">
                    <div className="items2 mycash-count">
                      <h3 style={{ margin: '0' }}>{"Bonus Cash"}<span onClick={() => { this.openPopup('bonus-cash') }} className=" walletHelpIcon material-icons-outlined">help</span></h3>
                      <div className="adsPrice textRight walletTime colorGreen" style={{ position: 'unset', marginTop: '0' }}>Rs. {this.state.apiResponse.foInstantCash.fiBonusCash}</div>
                    </div>
                    <div className="historyTable">
                      {this.state.apiResponse.foBonusCashHistory.map((single, i) =>
                          <div className="mycash-card">
                            <div className="mycash-header">
                              <div>
                                <span>{config.replaceMyCash(single.fsTitle)}</span>
                                <span>Rs.{parseInt(single.fiAmount) + parseInt(single.fiUsedAmount)}</span>
                              </div>
                            </div>
                            {single.fsBonusCashLog.map((singleLog, i) =>
                              <div className="mycash-log">
                                <span>{singleLog.fsSubject?config.replaceMyCash(singleLog.fsSubject):'Voucher Debit'}</span>
                                <span>Rs.{singleLog.fsType === 'debit' ? '-' : ''}{singleLog.fiAmount}</span>
                              </div>
                            )}
                            <div className="mycash-footer">
                              <div>
                                <div>
                                  <span>Expiry: </span>
                                  {single.fsExpiryDate.indexOf("Exp") >= 0 ?
                                    <span style={{ 'color': 'red' }}>{single.fsExpiryDate}</span> :
                                    <span>{single.fsExpiryDate}</span>}
                                </div>
                                <div>
                                  <span>Remaining: </span>
                                  <span>Rs.{single.fiAmount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="">
                      <AdSense.Google
                          client={config.adsense.clientId}
                          slot={config.adsense.slotId}
                          style={{ display: 'block' }}
                          format="auto"
                          responsive="true"
                      />
                  </div> */}
                </div>
                : ''}
            </div>
          </div>
          }
        </div>
      </motion.div>
    )
  }
}