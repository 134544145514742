import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from './config';

class ProviderSpashScreen extends Component {
    
    render() {
        var LoaderImg = config.getProviderSetting().fsLoader;
        console.log(LoaderImg);
        return (
            <div className="spashScreen mpokker-spash-loader">
                <img alt="Logo" src={LoaderImg} style={{'maxWidth':'90px'}} />
                {/* <div className="whiteBg">
                    <div className="AppLoginScreen" style={{'minHeight':'auto'}}>
                        <form noValidate autoComplete="off">
                            <div className="preLogo">
                                <div className="apiCallLoader" style={{'marginBottom':'30px'}}>
                                    <CircularProgress color="secondary" />
                                </div>
                                <div className="appName">PLEASE WAIT</div>
                            </div>
                            <div className="submitButton" dangerouslySetInnerHTML={{__html: 'Checking if your Phone<br/>Is already Registered'}}>
                            </div>
                        </form>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default ProviderSpashScreen;