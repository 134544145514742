import React from 'react';  
import './App.css'; 
import PWASetpsAndroid from './component/PWASetpsAndroid';
import PWASetpsIos from './component/PWASetpsIos';
import config from './component/config';
import {isDesktop} from "react-device-detect";
// import ThemeTopMenuDrawer from './component/ThemeTopMenuDrawer';
//import SplashSlider from './component/SplashSlider';
import AppScreen from './component/AppScreen';

function App() {
  
  /* Check is browser or pwa */
  let displayMode = 'browser';
  const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
      displayMode = 'standalone';
  }
  
  /* is ios or android */
  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone;

  var currentUrl = window.location.href;
  var spiltArray = currentUrl.split('/publisher/');
  var deviceOpen = 'web';
  if(spiltArray[1]!==undefined){
    var providerInfo = spiltArray[1].split('/?');
    if(providerInfo[1]===undefined){
        providerInfo = spiltArray[1].split('/');
    }
    
    if(providerInfo[1]!==undefined && providerInfo[1]!==""){
        /* Ignore Storage for Demo Promo */
        var search = providerInfo[1];
        // var userInfo = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        const rawParams = providerInfo[1].replace("?", "").split("&");
        const userInfo = {};
        rawParams.forEach((item) => {
            item = item.split("=");
            userInfo[item[0]] = item[1];
        });
        console.log(userInfo.fiDeviceType);
        if(userInfo.fiDeviceType!==undefined){
          deviceOpen = userInfo.fiDeviceType;
        }
    }
  }
  
  /* Ignore on app open */
  var currentUrl = window.location.href;
  var spiltArray = currentUrl.split('/publisher/');
  if(spiltArray[1]!==undefined || localStorage.getItem('OpenPWAUserPromo')!==null){
    displayMode='miniApp';
  }
  var NoDataFound = config.getProviderSetting().fsNoData;
  if(isDesktop){
    return(
      <div className="spashScreen">
           <div className="whiteBg">
               <div className="AppLoginScreen">
                   <div className="NoDataFound">
                       <img style={{'marginTop':'0'}} src={NoDataFound} alt="No Data Found"/>
                       <div>Oops!</div>
                       <div>This is only available via any Mobile device.<br/>
                        So please open below link from your mobile,<br/>
                        and ensure you select the “Save on Screen” <br/>
                        option to access your gifts in future. Enjoy !</div>
                   </div>
               </div>
           </div>
       </div>
    )
  }

  // if(displayMode==='browser' && config.isProd && deviceOpen==='web'){
  //   var checkData = "";
  //   var android = <div className="android">
  //       <PWASetpsAndroid/>
  //   </div>;

  //   var ios = <div className="ios">
  //      <PWASetpsIos/>
  //     </div>;

  //   if(isIOS){
  //     checkData = ios;
  //   }else{
  //     checkData = android;
  //   }

  //   if(config.isOnline===false){
  //     checkData = <div className="spashScreen">
  //         <div className="whiteBg">
  //             <div className="AppLoginScreen">
  //                 <div className="NoDataFound">
  //                     <img style={{'marginTop':'0'}} src={NoDataFound} alt="No Data Found"/>
  //                     <div>Oops!</div>
  //                     <div>You're offline right now. Check your internet connection.</div>
  //                 </div>
  //             </div>
  //         </div>
  //     </div>
  //   }

  //   return (
  //     <div className="App">
  //         <div className="spashSliderPWA">
  //             <div className="information">
  //                 {checkData}
  //             </div>
  //         </div>
  //     </div>
  //   )
  // }

  return (
    <div className="App">
        <AppScreen/>
    </div>
  );
}

export default App;
