import React, { Component } from "react";
import config from './config';
import TextField from '@material-ui/core/TextField';
export default class SearchForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            searchValue:this.props.searchValue===undefined?'':this.props.searchValue,
            searchCategories:this.props.searchCategories===undefined?[]:this.props.searchCategories,
            selectedCategory:this.props.selectedCategory===undefined?0:this.props.selectedCategory,
            isOnlineOfflineAllFilter:this.props.currentAdType===undefined?1:this.props.currentAdType
        }

        this.handleSearchString = this.handleSearchString.bind(this);
        this.storeSearchString = this.storeSearchString.bind(this);
        this.searchOnCategory = this.searchOnCategory.bind(this);
        this.submitSearchForAllCat = this.submitSearchForAllCat.bind(this);
        this.updateOnlineOfflineStatus = this.updateOnlineOfflineStatus.bind(this);
    }

    updateOnlineOfflineStatus(value){
        var oldValue = this.state.isOnlineOfflineAllFilter;
        this.setState({ 'isOnlineOfflineAllFilter': value });
        if(oldValue!==value){
            // if(value!==2){
                this.setState({ selectedCategory:0 });
            // }
            this.props.updateOnlineOffLineStatus(value);
        }
    }

    componentDidMount() {
    }
    
    getPageViewTypeBasedOnCategory(Category){
        var faOfflineCat  = [66,64,67];
        return faOfflineCat.includes(Category) ? /* Offline */ 2 : /* Online */ 1;
    }

    searchOnCategory(newVal){
    var feSearch = document.querySelector('input[name=firstName]');
    if(feSearch != undefined){
        feSearch.value = '';
    }
    var isOnlineOfflineAllFilter = newVal===0?this.state.isOnlineOfflineAllFilter:this.getPageViewTypeBasedOnCategory(newVal);
      this.setState({
        selectedCategory:newVal,
        isOnlineOfflineAllFilter:isOnlineOfflineAllFilter
      },()=>{ this.handleSearchString('defaultSearch'); });
    }

    storeSearchString(event){
        this.setState({
            searchValue:event.target.value
        });
    }

    submitSearchForAllCat(event){
        event.preventDefault();
        // this.setState({ 'isOnlineOfflineAllFilter': 1 });
        var searchValue = this.state.searchValue;
        this.setState({searchValue:'',selectedCategory:0});
        this.props.handleSearchAndCategory(searchValue,0/*this.state.selectedCategory*/, this.state.isOnlineOfflineAllFilter);
    }

    handleSearchString(e){
        this.props.handleSearchAndCategory(this.state.searchValue,this.state.selectedCategory, this.state.isOnlineOfflineAllFilter);
    }

    render() {

        var categoryHtml = "";
        if(this.state.searchCategories.length > 0){
          categoryHtml = <div className="searchCategoryRow"><div className="searchCategoryScroller">
              {this.state.searchCategories.map((item,i)=>{
                  var categoryClass="singleCategory";
                  if(this.state.selectedCategory===item.fiCategoryId){
                    categoryClass += ' activeCat';
                  }
                  return(
                      <div key={i} onClick={()=>this.searchOnCategory(item.fiCategoryId)} id={'category_'+item.fiCategoryId} className={categoryClass}>
                          {item.fsCategoryImage!==undefined?
                            <img src={config.getImageUrl(item.fsCategoryImage)}/>
                          :''}
                          <div>{item.fsCategoryName}</div>
                      </div>
                  )
              })}
        </div></div>
        }

        var checkMark = <span class="material-icons-outlined check_circle">check_circle</span>

        var AppLogo = config.getProviderSetting().fsLogo
        return (
            <div className={"addListSearchFilter" + (this.props.isSearchScreen?' search-screen':'')}>
                {config.isReferreFromExist() ?
                <div className="branding-for-refferr-top-bar">
                    <div className="logo"><img alt="App Logo" src={AppLogo} /></div>
                    <div className="reward-store">Reward Store</div>
                    <div className="powered-by">
                        <div>Powered By</div>
                        <div>Moby</div>
                    </div>
                </div> : ''}

                {this.props.isSearchScreen || this.props.searchFormToggle ?
                <div className="searchForm">
                    <form onSubmit={this.submitSearchForAllCat}>
                    <div className="withLabel withIcon">
                        <div className="searchButton" onClick={this.submitSearchForAllCat}>
                            <span className="material-icons-outlined">
                                search
                            </span>
                            <span>Search</span>
                        </div>
                        <TextField type="search" defaultValue={this.state.searchValue} name="firstName" placeholder="Search brand here..." id="outlined-otp" variant="outlined" onChange={this.storeSearchString} autocomplete="off"/>
                    </div>
                    </form>
                    <div className="onlineOfflineOfferFilter">
                    {/* <div className={this.state.isOnlineOfflineAllFilter===0?'active':''} onClick={()=>this.updateOnlineOfflineStatus(0)}>{this.state.isOnlineOfflineAllFilter===0?checkMark:''} All</div> */}
                        <div className={this.state.isOnlineOfflineAllFilter===1?'active':''} onClick={()=>this.updateOnlineOfflineStatus(1)}> {this.state.isOnlineOfflineAllFilter===1?checkMark:''} Online Offers </div>
                        <div className={this.state.isOnlineOfflineAllFilter===2?'active':''} onClick={()=>this.updateOnlineOfflineStatus(2)}> {this.state.isOnlineOfflineAllFilter===2?checkMark:''} Offline Offers</div>
                    </div>
                </div>:''}
                {categoryHtml}      
            </div>
        )
    }
}