import React, { Component } from "react";
import config from './config';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

import CircularProgress from '@material-ui/core/CircularProgress';

import { motion } from "framer-motion";

import InternetNotAvailable from './InternetNotAvailable';
import MarkAsReadPopup from './popup/MarkAsReadPopup';
import ContinuePopup from './popup/ContinuePopup';
import MoreCashbackPopup from './popup/MoreCashbackPopup';

import DeviceConnectionPopup from './popup/DeviceConnectionPopup';

import Card from '@material-ui/core/Card';


import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Alert from '@material-ui/lab/Alert';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";

// toast.configure()

export default class AdsMarkAsReadVoucher extends Component {
    constructor(props) {
        super(props)

        var currentTimeline = "";
        var isBlinkShopOnline = true;
        var fiActivityId = 0;
        fiActivityId = this.props.match.params.fiActivityId;
        localStorage.setItem('fiHighlightActivity', fiActivityId);
        this.state = {
            fiActivityId: fiActivityId,
            currentTimeline: currentTimeline,
            fsPassoword: '',
            fbShopOnlineLinkLoader: false,
            isDisableMarkAsRead: isBlinkShopOnline,
            isLoading: true,
            isBlinkButton: isBlinkShopOnline,
            isOpenContinuePopup: false,
            currentOffer: 1000,
            activeLocation: "",
            isOpenPopup: false,
            isDisplayLocations: false,
            popupContent: "",
            fbIsMyCashPopup: false,
            fbIsDevicePopupEnable: false,
            checkedVouchers: [],
            retryMessage: ""
        }

        this.goBack = this.goBack.bind(this);

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.copyAllCoupons = this.copyAllCoupons.bind(this);
        this.withOutApiGoTimelineWithBillPopup = this.withOutApiGoTimelineWithBillPopup.bind(this);
        this.scrollToRef = this.scrollToRef.bind(this);
        this.voucherUsed = this.voucherUsed.bind(this);
    }

    scrollToRef = (ref) => { window.scrollTo(0, $('.' + ref).offset().top); this.setState({ activeLocation: ref }) }
    closeDevicePopup(mutipleDeviceOpenStatus) {
        if (mutipleDeviceOpenStatus === 1) {
            this.goBack();
        } else if (mutipleDeviceOpenStatus === 2) {
            this.setState({ 'fbIsDevicePopupEnable': false, 'isOpenContinuePopup': true })
        } else {
            this.setState({ 'fbIsDevicePopupEnable': false })
        }
    }

    componentDidMount() {
        var requestParams = {
            'fsAction': 'getActivityDetails',
            'fiActivityId': this.state.fiActivityId
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            var checkedVouchers = [];
            var foCouponsUsedList = responseJson.foActivity.fsCouponDescription;
            
            checkedVouchers.push(responseJson.foActivity.fsCouponCode.split(',')[0]);
            
            if (foCouponsUsedList.length > 0) {

                for (var i = 0; i < foCouponsUsedList.length; i++) {
                    checkedVouchers.push(foCouponsUsedList[i].fsCoupon);
                }
            }
            this.setState({
                isLoading: false,
                isBlinkButton: responseJson.foActivity.fbIsBlinkShopOnline,
                currentTimeline: responseJson.foActivity,
                checkedVouchers: checkedVouchers
            });
        });
    }

    retriveTransaction(fiActivityId, fiGiftCardId) {
        var requestParams = {
            "fsAction": "fsVoucherActions",
            "fsCallFun": "retriveVoucher",
            "fiActivityId": fiActivityId,
            "fiGiftCardId": fiGiftCardId
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            console.log(responseJson);
            this.setState({ 'retryMessage': responseJson.fsMessage });
        });
    }

    voucherUsed(fsVoucherCode) {
        console.log(fsVoucherCode);
        var foVouchersChecked = this.state.checkedVouchers;

        const key = this.state.checkedVouchers.indexOf(fsVoucherCode);
        if (key > -1) {
            foVouchersChecked.splice(key, 1);
        }else{
            foVouchersChecked.push(fsVoucherCode);
        }

        var requestParams = {
            'fsAction': 'voucherMarkAsUsed',
            'fiGiftCardId': this.state.currentTimeline.fiGiftCardId,
            'fsVoucherCode': fsVoucherCode
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            this.setState({
                checkedVouchers: foVouchersChecked
            });
        });
    }

    withOutApiGoTimelineWithBillPopup() {
        this.props.history.push({
            pathname: '/my-timeline',
            state: { currentTimeline: this.state.currentTimeline, openBillUploadPopup: true }
        });
    }



    copyAllCoupons(fsShopOnlineLink) {
        // var fsCouponCode = this.state.currentTimeline.fsCouponCode;
        var fsCouponCode = this.state.checkedVouchers[0];
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.copyToClipboard(fsCouponCode);
                this.openLinkOnBrowser(fsShopOnlineLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.setClipboard(fsCouponCode);
                this.openLinkOnBrowser(fsShopOnlineLink);
            } catch (error) {
                console.log(error);
            }
        }
        try {
            navigator.clipboard.writeText(fsCouponCode).then(() => {
                this.openLinkOnBrowser(fsShopOnlineLink);
            });
        } catch (error) {
            console.log(error);
        }

        toast.success('All Codes copied!', {
            position: "top-center",
            theme: "light"
        });
    }

    copyToClipboard(fsCouponCode, fbIsCoupon = true) {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.copyToClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.setClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        }
        navigator.clipboard.writeText(fsCouponCode);
        if (fbIsCoupon) {
            toast.success('Code copied!', {
                position: "top-center",
                theme: "colored"
            });
        } else {
            toast.success('PIN copied!', {
                position: "top-center",
                theme: "colored"
            });
        }
    }

    goBack() {
        this.props.history.push({
            pathname: '/my-timeline'
        });
    }


    openLinkOnBrowser(fsCouponLink) {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.openInBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.openLinkOnBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }

        if (config.getProviderSetting().fbIsPWAOpenOnWeb) {
            window.open(fsCouponLink, '_blank');
        }
    }


    render() {

        /* No Internet */
        if (!navigator.onLine) {
            return (
                <InternetNotAvailable />
            )
        }

        if (this.state.isLoading) {
            return (
                <div className="apiCallLoader fullScreenLoader">
                    <CircularProgress color="secondary" />
                </div>
            );
        }

        var onlineOfflineText = this.state.currentTimeline.fsMarkIsUsedFlow === "voucher-red" ? 'Offline' : 'Online';

        var locationsListHtml = '';
        var offerListHtml = '';

        var fsVoucher = this.state.currentTimeline.fsCouponCode.split(',');
        var foVoucherPins = this.state.currentTimeline.foVoucherPins.split(',');
        offerListHtml = <div className="offerListTabel" style={{ "marginBottom": "25px", "marginTop": "10px" }}>
            <div className="sub-title-of-offers">
                <div style={{ "marginBottom": "15px" }}>You Saved Extra {this.state.currentTimeline.fsFlatCashBack} Share Code Now</div>
            </div>
            {this.state.currentTimeline.fsMarkIsUsedFlow == "voucher-green" ? <div className="sub-title-of-offers-2">
                <div style={{ "marginBottom": "25px" }}>Visit the online store payment gateway & choose the option " Giftcard & Vouchers" to Pay & paste codes</div>
            </div> : ''}
            <div className="use-later" style={{ "marginBottom": "25px" }}>To use later, find this coupon in your MyCoupon page</div>
            {fsVoucher.map((fsVoucherCode, key) =>
                <div key={key}>
                    <div className={"onlineOfferItem location-" + key}>
                        <Checkbox onChange={() => this.voucherUsed(fsVoucherCode)}
                            checked={this.state.checkedVouchers.indexOf(fsVoucherCode) > -1 ? true : false}
                        //disabled={this.state.checkedVouchers.indexOf(fsVoucherCode) > -1 ? true : false}
                        />
                        <div className={"voucherCode " + (this.state.currentTimeline.fsCouponPassword === "" ? 'full-width' : '')}>{fsVoucherCode}
                            <div className="offlineCouponLink copyBtn" onClick={() => this.copyToClipboard(fsVoucherCode)}><span className="material-icons-outlined">file_copy</span></div>
                        </div>
                        {this.state.currentTimeline.fsCouponPassword !== "" ? <div className="voucherPin">
                            <div onClick={() => { this.copyToClipboard(foVoucherPins[key], false) }}>{foVoucherPins[key]}</div>
                            <div>(Copy Pin)</div>
                        </div> : ''}
                    </div>
                </div>
            )}

            <div className="voucher-expiry-details">
                <table>
                    <tr>
                        <th>Voucher Amount</th>
                        <th>:</th>
                        <td>Rs. {this.state.currentTimeline.fiQuizReward}</td>
                    </tr>
                    <tr>
                        <th>Voucher Expiry</th>
                        <th>:</th>
                        <td>{this.state.currentTimeline.fdCouponExpiredDate}</td>
                    </tr>
                </table>
            </div>

        </div>;

        if (this.state.currentTimeline.fsPinColor === 'red-voucher') {
            if (this.state.currentTimeline.foLocationList.length > 0) {
                var displayStatus = 'block';
                locationsListHtml = <div className="nearByLocations" style={{ 'display': displayStatus }}>
                    {this.state.currentTimeline.foLocationList.map((singleLocation, key) => {
                        var googleMapLink = 'https://maps.google.com/?q=' + singleLocation.ffLatitude + ',' + singleLocation.ffLongitude;
                        return (
                            <div className="Coupon ShopOnline active" key={key}>
                                <a href={googleMapLink}
                                    onClick={(e) => { e.preventDefault(); this.openLinkOnBrowser(googleMapLink); }}
                                    rel="noopener noreferrer" target="_blank">NEARBY STORE {key + 1}</a>
                            </div>
                        )
                    })}
                </div>
            }
        }

        var CouponBg = config.getProviderSetting().fsCouponImg;
        let copyCodeStyle = { opacity: .8, pointerEvents: 'none' }
        if (this.state.checkedVouchers.length > 0) {
            copyCodeStyle = {}
        }
        let fiTotalInstantCash = JSON.parse(localStorage.getItem('foGlobalSetting')).fiTotalInstantCash;
        var shopClasses = 'Coupon ShopOnline box-shadow active';
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >
                <ToastContainer />
                <div className="AppDashboard markAsReadScreen">
                    <div className="screenPadding">
                        <div className="markAsReadBack">
                            <span style={{ 'fontSize': '24px', 'marginTop': '-2px' }} className="material-icons-outlined" onClick={this.goBack}>keyboard_backspace</span>
                        </div>
                        <div className="MainTitle">
                            <div className="onlineOfflineTitle">{onlineOfflineText} VOUCHER</div>
                        </div>

                        <div className="newOnlineCouponDesign">
                            <img src={CouponBg} />
                            <img className="brandLogo" src={config.getImageUrl(this.state.currentTimeline.fsAdLogo)} />
                            <div className="offer-name">{this.state.currentTimeline.fsAdName}</div>
                            <div className="extra-text">+ USE {config.getGlobalCashbackString().toUpperCase()}</div>
                            <div className="upto-dis text-design">
                                {fiTotalInstantCash > 0 && this.state.currentTimeline.fiDefVouDenomination > 0
                                    ? <span>Exact</span> : <span>UP TO</span>}
                                <span>{this.state.currentTimeline.fsDiscountUpTo}</span>
                                <span>DISCOUNT</span>
                            </div>

                            <div>
                                <div className="upto-other text-design">
                                    <span>BUY NOW</span>
                                    <span>{this.state.currentTimeline.fsFlatCashBack}</span>
                                    <span>INSTANT</span>
                                    <span>DISCOUNT</span>
                                </div>
                                <div className="max-cashback-amount" style={{ 'fontSize': '10px', 'textTransform': 'uppercase', 'fontWeight': 'bold' }}>Store Promotions + Exact {this.state.currentTimeline.fsFlatCashBack} Extra</div>
                            </div>
                        </div>

                        <Card>
                            <div className="Description" dangerouslySetInnerHTML={{ __html: this.state.currentTimeline.fsOfferDetails }}>
                            </div>
                        </Card>

                        <div className="offerListMain">
                            {this.state.currentTimeline.fsMarkIsUsedFlow === "voucher-red" ? locationsListHtml : ''}
                            {offerListHtml}
                        </div>

                        {this.state.currentTimeline.fsCouponCode === "COUPON CODE ERROR" ?
                            <div className="onlineOfferItem offer-list-details">
                                <Alert severity="error">
                                    {/* There is something issue on voucher server, Can you try again after 15 mins or contact to support with bellow details.<br/><br/>
                                    <b>User Transaction Id : </b> {this.state.currentTimeline.fiActivityId}<br/>
                                    <b>Error Code : </b> {this.state.currentTimeline.fsCouponDescription.code} */}
                                    SOME ERROR AT THE COUPON TERMINAL PLEASE RETRY AFTER 15 MINS.
                                    YOU WILL FIND THIS COUPON IN YOUR “MY COUPONS“ PAGE ANYTIME,
                                    OPEN COUPON & SCROLL DOWN TO CLICK THE REFRESH BUTTON.
                                    {/* <b>Error Description : </b> {this.state.currentTimeline.fsCouponDescription.desc} */}
                                </Alert>
                                {/* <div className="or">OR</div> */}
                                <div className={shopClasses} style={{ marginTop: '15px' }}>
                                    <div rel="noopener noreferrer" onClick={() => { this.retriveTransaction(this.state.currentTimeline.fiActivityId, this.state.currentTimeline.fiGiftCardId) }}>REFRESH VOUCHER CODE</div>
                                </div>
                                {this.state.retryMessage !== "" ? <div className="offer-list-details manage-width" style={{ 'marginTop': '15px', textAlign: 'left', textTransform: 'uppercase' }}><Alert severity="error">{this.state.retryMessage}<br /><br />
                                    Share user transition info to admin<br />
                                    <b>User Transaction Id : </b> {this.state.currentTimeline.fiActivityId}
                                </Alert></div> : ''}
                            </div>
                            :
                            <div>
                                {/* <div className="voucher-note-1">
                                    Share the voucher code with store cashier To use later, <br/>
                                    find the code in 'MyCoupons'
                                </div> */}
                                <div>
                                    {this.state.currentTimeline.fsMarkIsUsedFlow === "voucher-green" ?
                                        <div className={shopClasses} style={{ marginTop: '15px', ...copyCodeStyle }}>
                                            <div rel="noopener noreferrer" onClick={() => { this.copyAllCoupons(this.state.currentTimeline.fsShopOnlineLink) }} target="_blank">Copy ALL Codes Visit Webstore</div>
                                        </div>
                                        : ''}
                                    {this.state.currentTimeline.fsMarkIsUsedFlow === "voucher-green" ? '' :
                                        <div className="voucher-note-2">
                                            Share the voucher code with the store cashier<br />
                                            To use later, find the Voucher in "My Coupons"
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </motion.div>
        )

    }
}