import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ProviderInfo extends Component {

  constructor() {
    super();

    this.state = {
        
    }
}

componentWillMount() {
    if(localStorage.getItem('isProfileUpdated')==='true'){
        this.props.history.push({
            pathname: '/',
        });
    }else{
        this.props.history.push({
            pathname: '/update-profile',
        });
    }
}
  render() {
    

    return (
      
        <div className="AppDashboard">
          <div className="AppContent isNormalView">
                <div className="singleMessageScreen">
                    <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                </div>
            </div> 
        </div>
    )
  }
}