import React, { Component } from "react";
import config from './config';
import Button from '@material-ui/core/Button';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

import CircularProgress from '@material-ui/core/CircularProgress';

import { motion } from "framer-motion";

import InternetNotAvailable from './InternetNotAvailable';
import MarkAsReadPopup from './popup/MarkAsReadPopup';
import ContinuePopup from './popup/ContinuePopup';
import MoreCashbackPopup from './popup/MoreCashbackPopup';

import DeviceConnectionPopup from './popup/DeviceConnectionPopup';

import Card from '@material-ui/core/Card';


import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";

// toast.configure()

export default class AdsMarkAsReadHybridGreen extends Component {
    constructor(props) {
        super(props)

        var currentTimeline = "";
        var isBlinkShopOnline = true;
        var fiActivityId = 0;
        fiActivityId = this.props.match.params.fiActivityId;
        localStorage.setItem('fiHighlightActivity', fiActivityId);
        this.state = {
            fiActivityId: fiActivityId,
            currentTimeline: currentTimeline,
            fsPassoword: '',
            fbShopOnlineLinkLoader: false,
            isDisableMarkAsRead: isBlinkShopOnline,
            isLoading: true,
            isBlinkButton: isBlinkShopOnline,
            isOpenContinuePopup: false,
            currentOffer: 1000,
            activeLocation: "",
            isOpenPopup: false,
            isDisplayLocations: false,
            popupContent: "",
            fbIsMyCashPopup: false,
            fbIsDevicePopupEnable: false
        }

        this.goBack = this.goBack.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.updateMarkAsRead = this.updateMarkAsRead.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.blinkDisable = this.blinkDisable.bind(this);
        this.closePopupAndRedirectToLink = this.closePopupAndRedirectToLink.bind(this);
        this.copyOfferToClipBoard = this.copyOfferToClipBoard.bind(this);
        this.withOutApiGoTimelineWithBillPopup = this.withOutApiGoTimelineWithBillPopup.bind(this);
        this.closeDevicePopup = this.closeDevicePopup.bind(this);
        this.scrollToRef = this.scrollToRef.bind(this);
    }

    scrollToRef = (ref) => { window.scrollTo(0, $('.' + ref).offset().top); this.setState({ activeLocation: ref }) }
    closeDevicePopup(mutipleDeviceOpenStatus) {
        if (mutipleDeviceOpenStatus === 1) {
            this.goBack();
        } else if (mutipleDeviceOpenStatus === 2) {
            this.setState({ 'fbIsDevicePopupEnable': false, 'isOpenContinuePopup': true })
        } else {
            this.setState({ 'fbIsDevicePopupEnable': false })
        }
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    localStorage.setItem('ffLat', position.coords.latitude);
                    localStorage.setItem('ffLong', position.coords.longitude);
                }, (error) => {
                    console.log(error);
                    // toast.warn('This offer is location based, Please enable location setting manually.', {
                    //     position: "top-center"
                    // });
                    alert('This offer is location based, Please enable location setting manually.');
                    // setTimeout(()=>{
                        this.props.history.push({
                            pathname: '/my-timeline',
                        });
                    // },5000);
                });
        }
        var requestParams = {
            'fsAction': 'getActivityDetails',
            'fiActivityId': this.state.fiActivityId,
            'ffLat': localStorage.getItem('ffLat'),
            'ffLong': localStorage.getItem('ffLong'),
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            this.setState({
                isLoading: false,
                isBlinkButton: responseJson.foActivity.fbIsBlinkShopOnline,
                currentTimeline: responseJson.foActivity
            });
        });
    }

    withOutApiGoTimelineWithBillPopup() {
        this.props.history.push({
            pathname: '/my-timeline',
            state: { currentTimeline: this.state.currentTimeline, openBillUploadPopup: true }
        });
    }

    copyOfferToClipBoard(key) {
        config.updateUserSession('offer_online', this.state.currentTimeline.fiAdId);
        var couponDetails = this.state.currentTimeline.foCouponList[key];

        if (couponDetails.fsCouponCode != "") {
            this.copyToClipboard(couponDetails.fsCouponCode);
            toast.success('Code copied!', {
                position: "bottom-left"
            });
        }

        if (this.state.currentTimeline.fiAdCouponType === 1) {
            this.openLinkOnBrowser(couponDetails.fsCouponLink);
        } else {
            this.setState({
                isOpenContinuePopup: true,
                currentOffer: key
            });
        }
    }

    closePopupAndRedirectToLink() {
        this.blinkDisable(false);
        this.setState({ fbShopOnlineLinkLoader: true });

        /* Clear blink */
        var currentTimeline = this.state.currentTimeline;
        var fsProvider = config.getProviderReferral();
        currentTimeline.fbIsBlinkShopOnline = false;
        // this.props.history.push({
        //     pathname: '/ads-mark-as-read/'+this.state.fiActivityId,
        // });

        var couponCode = this.state.currentTimeline.fsCouponCode;
        var fsCouponLink = this.state.currentTimeline.fsShopOnlineLink;
        if (this.state.currentOffer !== 1000) {
            var couponDetails = this.state.currentTimeline.foCouponList[this.state.currentOffer];
            if (couponDetails.fsCouponCode !== "") {
                // console.log(couponDetails.fsCouponCode);
                couponCode = couponDetails.fsCouponCode;
                fsCouponLink = couponDetails.fsCouponLink;
            }
        }

        this.copyToClipboard(couponCode);
        this.openLinkOnBrowser(fsCouponLink);

        setTimeout(() => {
            this.setState({ isOpenContinuePopup: false, fbShopOnlineLinkLoader: false });
            this.goBack();
        }, 10000);
    }

    blinkDisable(isShopOnlineSession = true) {
        var requestParams = {
            "fsAction": "updateShopOnlineBlink",
            "fiActivityId": this.state.currentTimeline.fiActivityId,
            "fiGiftCardId": this.state.currentTimeline.fiGiftCardId,
            "fsType": "fatchCouponFromContainer",
            "fbNeedRefetch": false
        };
        if (this.state.currentTimeline.fsCouponCode == "RESTAURANT_COUPON") {
            requestParams.fbNeedRefetch = true;
        }
        config.fetchInformation(requestParams).then((responseJson) => {
            var isDisableMarkAsRead = true;
            var enableReadPinPopup = false;
            if (this.state.currentTimeline.fsPinColor === 'green') {
                isDisableMarkAsRead = false;
                enableReadPinPopup = true;
            }
            var currentTimeline = this.state.currentTimeline;

            if (responseJson.fsCouponCode !== "") {
                currentTimeline.fsCouponCode = responseJson.fsCouponCode;
            }

            var couponCode = currentTimeline.fsCouponCode;
            this.copyToClipboard(couponCode);

            // if(this.state.currentTimeline.fiAdCouponType===1){
            //     enableReadPinPopup = false;
            //     this.openLinkOnBrowser(this.state.currentTimeline.fsShopOnlineLink);
            // }
            this.setState({ currentTimeline: currentTimeline, isDisableMarkAsRead: isDisableMarkAsRead, 'isBlinkButton': false, 'isDisplayLocations': true, isOpenContinuePopup: true, currentOffer: 1000 });
            // if(isShopOnlineSession){
            //     config.updateUserSession('shop_online',this.state.currentTimeline.fiAdId);
            // }
        });
    }

    handleClose() {
        this.setState({ 'isOpenPopup': false })
    }

    openPopup() {
        this.setState({ 'isOpenPopup': true })
    }

    updateMarkAsRead() {
        this.setState({ isLoading: true });
        var requestParams = {
            "fsMobile_number": localStorage.getItem('PhonePrefix').trim() + localStorage.getItem('userPhone'),
            "fsAd_Id": this.state.currentTimeline.ad_id
        };
        var formBody = config.convertParams(requestParams);
        var request = config.getRequestHeaders();
        fetch(config.API_URL + 'Update_MarketingAdsUsedStatus&' + formBody, request)
            .then((response) => response.text())
            .then((responseText) => {
                // this.props.history.push({
                //     pathname: '/my-timeline',
                //     state:{ }
                // });

                this.withOutApiGoTimelineWithBillPopup();
            });
    }

    handlePasswordChange(e) {
        const value = e.target.value;
        if (value === this.state.currentTimeline.fsGiftCardUsedPassword) {
            this.setState({ isDisableMarkAsRead: false });
        } else {
            this.setState({ isDisableMarkAsRead: true });
        }
    }

    handlePhoneChange(phone) {
        phone = phone.replace('+', '-');
        if (phone === localStorage.getItem('userPhone')) {
            this.setState({ isDisableMarkAsRead: false });
        } else {
            this.setState({ isDisableMarkAsRead: true });
        }
    }

    goBack() {
        this.props.history.push({
            pathname: '/my-timeline'
        });
    }

    copyToClipboard(fsCouponCode) {
        fsCouponCode = fsCouponCode.trim();
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.copyToClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.setClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        }
        navigator.clipboard.writeText(fsCouponCode);
    }

    openLinkOnBrowser(fsCouponLink) {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.openInBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.openLinkOnBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }

        if (config.getProviderSetting().fbIsPWAOpenOnWeb) {
            window.open(fsCouponLink, '_blank');
        }
    }


    render() {

        /* No Internet */
        if (!navigator.onLine) {
            return (
                <InternetNotAvailable />
            )
        }

        if (this.state.isLoading) {
            return (
                <div className="apiCallLoader fullScreenLoader">
                    <CircularProgress color="secondary" />
                </div>
            );
        }

        var onlineOfflineText = 'Pay Food Bill';
        var shopOnlineText = 'EXTRA 5% OFF, COPY CODE TO PAY';
        // if(this.state.currentTimeline.fsPinColor==='red' && this.state.currentTimeline.fsMarkIsUsedFlow==='offline-hybrid-green'){
        //     shopOnlineText = 'PAY BILL NOW';
        // }
        if (this.state.currentTimeline.fsPinColor === 'green') {
            shopOnlineText = "Shop Now";
            if (this.state.currentTimeline.fbIsLabelOrderNow === 1) {
                shopOnlineText = "Order Now";
            }
            if (this.state.currentTimeline.fbIsLabelOrderNow === 2) {
                shopOnlineText = 'EXTRA 5% OFF, COPY CODE TO PAY';
            }
            if (this.state.currentTimeline.fbIsLabelOrderNow === 3) {
                shopOnlineText = "Mark as Used";
            }
        }

        var offerListHtml = '';
        var locationsListHtml = '';
        // if(this.state.currentTimeline.fsPinColor==='green'){
        if (this.state.currentTimeline.foCouponList.length > 0) {
            offerListHtml = <div className="offerListTabel">
                <div className="sub-title-of-offers">
                    <div>Some Other Offers In The Store</div>
                    {this.state.currentTimeline.fiAdCouponType === 0 ? <div>{config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} is the same {this.state.currentTimeline.fsFlatCashBack} Extra.</div> : ''}
                </div>
                {this.state.currentTimeline.foCouponList.map((offerItem, key) => {
                    var blinkClass = "";
                    if (this.state.activeLocation === "location-" + key) {
                        blinkClass = " ActiveBannerAd";
                    }
                    return (
                        <div className={blinkClass} key={key}>
                            <div className={"onlineOfferItem location-" + key}>
                                <div>{offerItem.fsCouponDescription}</div>
                                <div>
                                    {localStorage.getItem('fbIsMobileConnected') === 'true' ?
                                        <div className="offlineCouponLink" onClick={() => this.copyOfferToClipBoard(key)}>{offerItem.fsCouponName}</div>
                                        :
                                        <div className="offlineCouponLink" onClick={() => this.setState({ 'fbIsDevicePopupEnable': true, currentOffer: key })}>{offerItem.fsCouponName}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>;
        }
        // if(this.state.currentTimeline.fsPinColor==='red'){
        if (this.state.currentTimeline.foLocationList.length > 0) {
            locationsListHtml = <div className="nearByLocations">
                {this.state.currentTimeline.foLocationList.map((singleLocation, key) => {
                    var googleMapLink = 'https://maps.google.com/?q=' + singleLocation.ffLatitude + ',' + singleLocation.ffLongitude;
                    var locationLabel = '';
                    if (this.state.currentTimeline.fsMarkIsUsedFlow = 'offline-hybrid-green') {
                        locationLabel = 'NEAREST OUTLET';
                    } else {
                        locationLabel = 'NEARBY STORE';
                    }
                    return (
                        <div className="Coupon ShopOnline active" key={key}>
                            <a href={googleMapLink}
                                style={{ letterSpacing: '4px' }}
                                onClick={(e) => { e.preventDefault(); this.openLinkOnBrowser(googleMapLink); }}
                                rel="noopener noreferrer" target="_blank">{`${locationLabel} ${key + 1}`}</a>
                        </div>
                    )
                })}
            </div>
        }
        // }

        var shopOnlineLink = config.getTextFirstLink(this.state.currentTimeline.fsCouponDescription);
        if (this.state.currentTimeline.fsShopOnlineLink !== "") {
            shopOnlineLink = this.state.currentTimeline.fsShopOnlineLink;
        }

        if (localStorage.getItem('fbIsMobileConnected') === 'true' || this.state.currentTimeline.fsPinColor === 'red') {
            var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" target="_blank" onClick={this.blinkDisable}>{shopOnlineText}</div>;
        } else {
            var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" target="_blank" onClick={() => this.setState({ 'fbIsDevicePopupEnable': true })}>{shopOnlineText}</div>;
        }
        var shopClasses = 'Coupon ShopOnline box-shadow ';
        if (shopOnlineLink !== "") {
            shopClasses = shopClasses + 'active';
            if (this.state.isBlinkButton) {
                shopClasses = shopClasses + ' blink_me';
            }
        }

        var deliveryPopupMessage = '';
        var fsDinoutImg = config.getProviderSetting().fsDinoutImg;
        deliveryPopupMessage = '<img style="max-width:100%" src="' + fsDinoutImg + '?v=1"><br/>';
        // deliveryPopupMessage += '<div class="coupon-code-hybrid"><div class="couponCode">'+this.state.currentTimeline.fsCouponCode+'</div>';

        var shopAsUsualText = "SHOP AS USUAL";
        if (this.state.currentTimeline.fbIsLabelOrderNow === 1) {
            shopAsUsualText = "Order As Usual";
        }
        if (this.state.currentTimeline.fbIsLabelOrderNow === 2) {
            shopAsUsualText = "READY TO PAY";
        }
        if (this.state.currentTimeline.fbIsLabelOrderNow === 4) {
            shopAsUsualText = "READY TO PAY";
        }

        var shoppingText = '';
        var foRestaurentBanner = '';
        SwiperCore.use([Autoplay]);
        if (this.state.currentTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || this.state.currentTimeline.fsMarkIsUsedFlow === 'offline-hybrid-direct') {
            foRestaurentBanner =
                this.state.currentTimeline.foCouponList.length > 0 ? <div className="amazon-slider">
                    <Swiper autoplay={{ delay: 3000, disableOnInteraction: false }}>
                        {this.state.currentTimeline.foCouponList.map((offerItem, key) => {
                            if (offerItem.fsCouponBanner === "") { return <></> }
                            return (
                                <SwiperSlide key={key}>
                                    <div className="slideItems">
                                        <img src={offerItem.fsCouponBanner} />
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div> : '';
            shoppingText = <div>
                <div className="upto-other text-design">
                    <span>DEBIT ME</span>
                    <span>{this.state.currentTimeline.fsDiscountUpTo}</span>
                    <span>'{config.getGlobalCashbackString()}'</span>
                    <span>Payment</span>
                </div>
                <div className="max-cashback-amount" style={{ 'fontSize': '10px', 'fontWeight': 'bold' }}>APPLY CODE, GET EXTRA {this.state.currentTimeline.fsFlatCashBack} OFF</div>
            </div>
        } else {
            shoppingText = <div>
                <div className="upto-other text-design">
                    <span>EXACT</span>
                    <span>{this.state.currentTimeline.fsFlatCashBack}</span>
                    <span>SHOPPING</span>
                    <span>{config.getCashbackString(this.state.currentTimeline.fbIsMyCash).toUpperCase()}</span>
                </div>
                {/* <div className="max-cashback-amount" style={{'fontSize':'10px','fontWeight':'bold'}}>PAY YOUR BILL USNING {this.state.currentTimeline.fsWalletName.toUpperCase()}</div> */}
            </div>
        }

        var CashbackNote = '';
        var fsRemainDay = ''
        if (this.state.currentTimeline.fiAdCouponType === 0) {
            CashbackNote = <span>({config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} in your {this.state.currentTimeline.fsWalletName} in ~{this.state.currentTimeline.fiVirtualCashTransferDays} Days)</span>
        }
        if (this.state.currentTimeline.fsRemainDay !== "") {
            fsRemainDay = <div className="Coupon validDays bgBlack colorRed">{this.state.currentTimeline.fsRemainDay}</div>
        }
        if (this.state.currentTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green') {
            CashbackNote = '';
            fsRemainDay = '';
        }

        var giftcardPin =
            <div className="passwordInput">
                <input type="number" disabled={this.state.isBlinkButton} placeholder="Gift Card Pin" className="form-control customNomberInput" onChange={(event) => this.handlePasswordChange(event)} />
            </div>
        if (this.state.currentTimeline.fsPinColor = "red" && this.state.currentTimeline.fsMarkIsUsedFlow == 'offline-hybrid-green') {
            giftcardPin = ''
        }

        var OfferSubText = '';
        if (this.state.currentTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || this.state.currentTimeline.fsMarkIsUsedFlow === 'offline-hybrid-direct') {
            OfferSubText = `(The Extra ${this.state.currentTimeline.fsFlatCashBack} discount is max upto Rs ${this.state.currentTimeline.fiQuizReward})`
        }


        var CouponBg = config.getProviderSetting().fsCouponImg;
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >

                {this.state.fbIsDevicePopupEnable ? <DeviceConnectionPopup popupContent="To create your account for<br/>Cashback Credit" closeDevicePopup={this.closeDevicePopup} /> : ''}

                {this.state.isOpenPopup ? <MarkAsReadPopup updateMarkAsRead={this.updateMarkAsRead} handleClose={this.handleClose} popupContent={this.state.popupContent} openDialog={true} /> : ''}

                {this.state.isOpenContinuePopup ?
                    <ContinuePopup popupButtonText={this.state.fbShopOnlineLinkLoader ? 'Please wait...' : shopAsUsualText}
                        popupTitle={'Code Copied!'}
                        popupContent={deliveryPopupMessage}
                        secondLineText=""
                        handleClose={this.closePopupAndRedirectToLink} openDialog={true} /> : ''}

                <ToastContainer theme="colored" position="bottom-left" />
                {this.state.fbIsMyCashPopup ? <MoreCashbackPopup
                    handleClose={() => { this.setState({ fbIsMyCashPopup: false }) }}
                    popupButtonText="Ok"
                    cashbackType={'mycash'}
                    openDialog={true} /> : ''}
                <div className="AppDashboard markAsReadScreen">
                    <div className="screenPadding">
                        <div className="markAsReadBack">
                            <span style={{ 'fontSize': '24px', 'marginTop': '-2px' }} className="material-icons-outlined" onClick={this.goBack}>keyboard_backspace</span>
                        </div>
                        <div className="MainTitle">
                            <div className="onlineOfflineTitle">{onlineOfflineText}</div>



                            {/* <div>{this.state.currentTimeline.fsAdName}</div>
                            
                            <div className="discount-and-cashback">
                                <div>Upto {this.state.currentTimeline.fsDiscountUpTo} Discount</div>
                                {this.state.currentTimeline.fsFlatCashBack!==""?<div>Exact {this.state.currentTimeline.fsFlatCashBack} Cashback</div>:''}
                            </div> */}

                            {/* <div className="couponCodeInCouponScn">Cashback Rs {this.state.currentTimeline.fiQuizReward}</div> */}
                        </div>

                        <div className="newOnlineCouponDesign">
                            <img src={CouponBg} />
                            <img className="brandLogo" src={config.getImageUrl(this.state.currentTimeline.fsAdLogo)} />
                            <div className="offer-name">{this.state.currentTimeline.fsAdName}</div>
                            <div className="extra-text">+ USE {config.getGlobalCashbackString()}</div>
                            <div className="upto-dis text-design">
                                <span>EXACT</span>
                                <span>{this.state.currentTimeline.fsDiscountUpTo}</span>
                                <span>'INSTANT'</span>
                                <span>DISCOUNT</span>
                            </div>
                            {this.state.currentTimeline.fiAdCouponType === 1 ?
                                <div>
                                    <div className="upto-amazon text-design">
                                        <span>EXACT</span>
                                        <span>{this.state.currentTimeline.fsFlatCashBackAmazon}</span>
                                        <span>INSTANT</span>
                                        <span>DISCOUNT</span>
                                    </div>
                                    <div className="major-card">
                                        (On most Major Cards)
                                    </div>
                                </div>
                                : shoppingText
                            }
                        </div>
                        {foRestaurentBanner}
                        {this.state.currentTimeline.fiAdCouponType === 0 ?
                            <Card>
                                <div className="Description" dangerouslySetInnerHTML={{ __html: this.state.currentTimeline.fsOfferDetails }}>
                                </div>
                            </Card>
                            :
                            ''
                        }

                        {/* <div className="Coupon bgWhite">
                            {this.state.currentTimeline.fsCouponCode}
                        </div> */}

                        <div className="DontForgotUploadBill" style={{ "marginBottom": "-10px !important" }}>
                            {this.state.currentTimeline.fbIsMyCash === 'yes' ?
                                this.state.currentTimeline.fiAdCouponType === 0 ?
                                    <span style={{ "marginLeft": '-30px' }}>({config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} Credit After Tracking Message)
                                        <span style={{ "position": "absolute", "marginLeft": '10px' }} onClick={() => { this.setState({ 'fbIsMyCashPopup': true }) }} className="material-icons-outlined help-icon">help</span>
                                    </span> : ''
                                : CashbackNote
                            }
                            {/* CASHBACK paid in your <br/><b>{this.state.currentTimeline.fsWalletName}</b> IN <b>{this.state.currentTimeline.fiVirtualCashTransferDays}</b> DAYS. */}
                        </div>
                        <div className="offerListMain">
                            {locationsListHtml}
                            {/* {offerListHtml} */}
                        </div>

                        <div className={shopClasses}>
                            {shopOnlineLinkHtml}
                        </div>

                        <div className="offer-sub-text-1">
                            {OfferSubText}
                        </div>

                        {/* {this.state.currentTimeline.fsPinColor==='green'?'':
                            <div className={this.state.isBlinkButton?'passwordInput firstNameLastName disabledPassword':'passwordInput firstNameLastName'}>
                                {this.state.currentTimeline.fsGiftCardUsedPassword === "" ?
                                    <div className="OTPInput">
                                        <div>
                                            <PhoneInput
                                                country={'in'}
                                                autoFormat={false}
                                                countryCodeEditable={false}
                                                enableSearch={true}
                                                searchPlaceholder='Search Country'
                                                placeholder="Mobile No. to Mark as Used"
                                                onChange={(phone) => this.handlePhoneChange(phone)}
                                                disabled={this.state.isBlinkButton}
                                            />
                                        </div>
                                    </div>
                                    :
                                    giftcardPin
                                }
                            </div>
                        } */}

                        {/* <div className="submitButton">
                            {this.state.currentTimeline.fsPinColor=== 'green'?
                                <Button onClick={this.withOutApiGoTimelineWithBillPopup} classes={{root:this.state.isBlinkButton?'':'blink_me'}} disabled={this.state.isDisableMarkAsRead} variant="contained" color="secondary">
                                    <span className="btnLabel">Register<br/>Bill</span>
                                </Button>
                            :
                                <Button onClick={this.openPopup} classes={{root:this.state.isBlinkButton?'':'blink_me'}} disabled={this.state.isDisableMarkAsRead} variant="contained" color="secondary">
                                    {this.state.isLoading === true ?
                                        <CircularProgress color="secondary" />
                                        :
                                        <span className="btnLabel">Mark as Used</span>
                                    }
                                </Button>
                            }
                            <Button variant="contained" onClick={this.goBack} color="secondary">
                                    Back To My<br/>Coupons
                            </Button>
                        </div> */}

                        {/* <div className="otherDetails">
                            After Shopping, Find the Brand Coupon IN <br/>TIMELINE & Upload Bill Showing<br/>Amount/Date Your Moby Mobile.
                        </div> */}
                        {fsRemainDay}
                    </div>
                </div>
            </motion.div>
        )

    }
}