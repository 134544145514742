import ReactGA from 'react-ga';

/* Google Ga */
ReactGA.initialize('G-HMXJV455DD');

const config = {
    'offer_loaded':'PWA_offer_loaded',
    "save_profile":"PWA_save_profile",
    "select_deal":"PWA_select_deal",
    "shop_now":"PWA_shop_now",
    "get_offer":"PWA_get_offer",
    "cashback_activate_ok":"PWA_cashback_activate_ok",
    "view_and_shop":"PWA_view_and_shop",
    "picture_press":"PWA_picture_press",  // PWA Only
    "help_option":"PWA_help_option",
    "otp_verified_all":"PWA_otp_verified_all",
    "coupon_page_loaded":"PWA_coupon_page_loaded",
    "submit_quiz":"PWA_submit_quiz", // PWA Only
    "register_bill":"PWA_register_bill",
    "bill_upload_succeed":"PWA_bill_upload_succeed",

    'pageViewEvent':function(pathName){
        console.log('yesPage Viewed');
        ReactGA.set({ page: pathName });
        ReactGA.pageview(pathName);
    },
    'customActionEvent':function(eventName){
        var eventDetail = eventName;
        if(localStorage.getItem('userPhone')!==null){
            eventDetail = localStorage.getItem('userPhone');
        }
        console.log('GA Event',eventName,eventDetail);
        ReactGA.event({
            category: eventName,
            action: eventDetail,
        });
    },
}

export default config;