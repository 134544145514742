import React from "react";
import Card from '@material-ui/core/Card';

export default function MessageList(props){
    return (
        <div className="timelineList">
            {props.message.map((singleMessage,i)=>{
                var cardColor = "singleMessageCard singleCouponTimeLine";
                if(singleMessage.fdIsMessageSeen===false){
                    cardColor = cardColor+' redCard';
                }
                var hashUrl = '/messages#'+i;
                return(
                    <div key={singleMessage.fiMessageId} className={cardColor}>
                        <Card>
                            <div className="timeLineContent">
                                <h3 style={{'marginTop':'-4px'}}>{singleMessage.fsTitle}</h3>
                                <div className="colorGray">{singleMessage.fsContent.substr(0,120)}...</div>
                                <div className="readMore"><a href={hashUrl} onClick={()=>props.isOpenMessage(i)}>Read More</a></div>
                                <div style={{'padding':'0px'}} className="publishDate">{singleMessage.fdTimestamp}</div>
                            </div>
                        </Card>
                    </div>
                )
            })}
        </div>        
    )
}