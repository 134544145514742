import React, { Component } from 'react';
import config from './config';

class SplashSlider extends Component {
    render() {
        var AppLogo = config.getProviderSetting().fsLogo;
        var SplashImage = config.getProviderSetting().fsSplashImage;
        return (
            <div className="spashScreen">
                <div className="whiteBg">
                    <div className="AppLoginScreen">
                        <form noValidate autoComplete="off">
                            <div className="preLogo">
                                <img alt="Logo" src={AppLogo} style={{'maxWidth':'90px'}} />
                                <div className="appName">{config.appName}</div>
                            </div>
                            <div className="otpMessage">
                                <img style={{'maxWidth':'210px'}} alt="Logo" src={SplashImage} />
                            </div>
                            <div className="submitButton" dangerouslySetInnerHTML={{__html: config.spashScreenMessage}}>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default SplashSlider;