import React from "react";
import Card from '@material-ui/core/Card';
import { Link  } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import config from '../../component/config';
import AdSense from 'react-adsense';

export default function SingleMessage(props){
    var singleMessage = props.singleMessage;

    function moveDealOfTheDayPage(message) {
        if(message.fiCategory > 0){
            localStorage.setItem('fiLoadCategory', message.fiCategory);
        }
    }

    return (
        <div className="singleMessageCard singleCouponTimeLine">
            <Card>
                <div className="timeLineContent">
                    <h2 style={{'margin':'0','font-size':'20px','textTransform':'uppercase','margin-bottom':'6px'}}>{singleMessage.fsTitle}</h2>
                    <div><b>{singleMessage.fdTimestamp}</b></div>
                    <div dangerouslySetInnerHTML={{__html: config.convertLinkToRealLink(singleMessage.fsContent)}}></div>
                </div>
            </Card>  

            {singleMessage.fsImage!=""?<div style={{'marginTop':'15px'}}>
                <Link to={'/'} onClick={moveDealOfTheDayPage(singleMessage)} className="searchItemList">
                    <Card>
                        <img src={config.getImageUrl(singleMessage.fsImage)} style={{'width':'100%'}}/>
                    </Card>
                </Link>
            </div>:''}

            {/* <div className="" style={{'margin':'15px 0px'}}>
                <AdSense.Google
                    client={config.adsense.clientId}
                    slot={config.adsense.slotId}
                    style={{ display: 'block' }}
                    format="auto"
                    responsive="true"
                />
            </div> */}

            {singleMessage.fiUserId > 0 ?
                <div className="message-option-button">
                    <Card>
                        <Link to={'/'}>
                            <Button variant="contained" color="secondary">
                                Back To Offer
                            </Button>
                        </Link>
                        <Link to={'/wallet'}>
                            <Button variant="contained" color="secondary">
                                Back To Wallet
                            </Button>
                        </Link>
                    </Card> 
                </div>
            :''}
        </div>
    )
}