import React, { Component } from "react";
import config from './config';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

import GoBackPopup from './popup/GoBackPopup';
import AddEnaggedPopup from './popup/AddEnaggedPopup';

import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { LazyLoadImage } from '@tjoskar/react-lazyload-img';
import InternetNotAvailable from './InternetNotAvailable';

import Card from '@material-ui/core/Card';

export default class BankOfferDetails extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      adId: this.props.match.params.adId,
      locationId: this.props.match.params.locationId,
      isLoading: true,
      apiResponse: "",
      userDetails:JSON.parse(localStorage.getItem('userProfile'))
    }

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount(props) {
    var requestParams = {
      "fsAction":"getOfferDetails",
      "fiAdId": this.state.adId,
      "fiLocationId": this.state.locationId
    };
    config.fetchInformation(requestParams).then((responseJson)=>{
      var distance = 0;
      if(responseJson.foOfferDetails.flCoverageRadius !== ""){
        var userLat = localStorage.getItem('userLat');
        var userLong = localStorage.getItem('userLong');
        var adLat = responseJson.foOfferDetails.foLocationList[0].ffLatitude;
        var adLong = responseJson.foOfferDetails.foLocationList[0].ffLongitude;
        distance = config.getDistanceFromLatLonInKm(userLat,userLong,adLat,adLong);
      }
      
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
        adDistance:distance
      });
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {

    /* No Internet */
    if(!navigator.onLine){
      return(
        <InternetNotAvailable/>
      )
    }

    console.log(this.state.apiResponse);
    var isLoaderOrError = "";
    if (this.state.isLoading === false) {
      if (this.state.apiResponse.foOfferDetails.fbIsEngageLimitOver===true) {
        isLoaderOrError = <Alert severity="error">Sorry, No data found on timeline.</Alert>
      }
    } else {
      isLoaderOrError = <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
    }

    if (isLoaderOrError !== "") {
      return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
        >
          <div className="AppDashboard screenAdDetails">
            <div className="adDetailsImage" style={{ 'height': '50px' }}>
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="loaderOrError">
              {isLoaderOrError}
            </div>
          </div>
        </motion.div>
      )
    }

    var apiResponse = this.state.apiResponse.foOfferDetails;

    var screenClass = 'adDetailsImage colorYellow';
    var buttonClass = 'playQuiz quizDetails mt-10 colorYellow';

    var isOpenPopupHtml = "";
    
    var startQuizLink = 'https://maps.google.com/?q='+apiResponse.foLocationList[0].ffLatitude+','+apiResponse.foLocationList[0].ffLongitude;;
    var playQuizButton = <a href={startQuizLink} target="_blank">
      <Button variant="contained" color="secondary">
      Locate <span className="material-icons-outlined"  style={{'marginLeft':'10px','marginTop':'-3px'}}>my_location</span>
      </Button>
    </a>;
    
    var bodyClass = 'AppDashboard screenAdDetails yellowAdDetails';
    apiResponse.offer_details = apiResponse.fsOfferDetail.replace(/\n/g, "<br />")
    var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className={bodyClass} style={{'height':'calc(100vh - 53px)','overflow':'auto'}}>
          <div>
            <div className={screenClass}>
              <LazyLoadImage  defaultImage={LazyPlaceholder} image={apiResponse.fsAdBanner} />
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="adBrandDetails"  style={{'z-index':'0'}}>
            <div className="info">
              <h3>Offer : {apiResponse.fsDescription}</h3>
            </div>
          </div>
          </div>
          <div className="AppContent autoHeight isNormalView mb-10">
            <Card>
              <div className="online-offline-add-setting">
                <div className="desc1 colorRed">BANK OFFER</div>
              </div>
              <div className="mt-20">
                <div><b>Bank & Card Name</b></div>
                <div>{apiResponse.fsAdName} {apiResponse.fsCardType} {apiResponse.fsCardName}</div>
              </div>
              <div className="mt-20">
                <div><b>The Offer</b></div>
                <div>{apiResponse.fsDescription}</div>
              </div>
              <div className="mt-20">
                <div><b>Description</b></div>
                <div dangerouslySetInnerHTML={{__html: apiResponse.offer_details}}></div>
              </div>

              <div className="mt-20">
                <div><b>Store Address</b></div>
                <div>{apiResponse.foLocationList[0].fsLandmark}</div>
              </div>

              <div className="mt-20 mb-10">
                <div><b>Deals Terms & Conditions</b></div>
                <div><a href={apiResponse.fsTC} target="_blank">{apiResponse.fsTC}</a></div>
              </div>
            </Card>
          </div>

          {isOpenPopupHtml}

          <div className={buttonClass}>
            {playQuizButton}
          </div>

        </div>
      </motion.div>
    )
  }
}