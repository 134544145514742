import React, { Component } from "react";
import config from './../config';
import CreditCard from './CreditCards';

import Alert from '@material-ui/lab/Alert';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Card from '@material-ui/core/Card';

export default class ProfileForm extends Component {
    constructor(props) {
        super(props)

        const fullDetails = this.props.apiResponse;
        const profileParams = fullDetails.foUserDetails;
        const userCagory = fullDetails.foBankCategory;

        var haveDonate = fullDetails.foDonationDetails.fbIsPayToNGO;
        var currentDistance = parseInt(profileParams.fiBankOfferRadius > 0 ? profileParams.fiBankOfferRadius:1000);
        
        var gender = profileParams.fsGender==='female'?1:0;

        var hasTermCheck = true;
        if(localStorage.getItem('hasTermCheck')==="true"){
            hasTermCheck = true;
        }

        var foDonateMobyEarningTo = "";
        fullDetails.foDonationDetails.foNgoList.map((item)=>{
            if(item.seleted===true){
                foDonateMobyEarningTo = item.code_id;
            }
            return (<></>);
        });

        if(profileParams.fsReferrerCode===""){
            profileParams.fsReferrerCode = config.getProviderReferral();
        }

        var formValidation = {
            firstName:{
                rules:[],
                messages:['First name is required.'],
                error:'',
                value:profileParams.fsFirstName,
                isDisable:profileParams.fsFirstName===""?false:true
            },
            lastName:{
                rules:[],
                messages:['Last name is required.'],
                error:'',
                value:profileParams.fsLastName,
                isDisable:profileParams.fsLastName===""?false:true
            },
            dateOfBirth:{
                rules:['required'],
                messages:['Date of birth is required.'],
                error:'',
                value:profileParams.fdDob
            },
            referrelCode:{
                rules:['required'],
                messages:['Referrel Code is required.'],
                error:'',
                value:profileParams.fsReferrerCode
            },
            emailAddress:{
                rules:[],
                messages:['Email address is required.','Email must be valid.'],
                error:'',
                value:profileParams.fsUserEmail,
                isDisable:profileParams.fsUserEmail===""?false:true
            },
            UPILink : {
                rules:[],
                messages:['UPI address is required.'],
                error:'',
                value:fullDetails.foPaymentDetails.fsUPILink
            },
            fsPaytmMobile : {
                rules:[],
                messages:['Paytm mobile must be required.','Paytm mobile must be 10 digit only.'],
                error:'',
                value:fullDetails.foPaymentDetails.fsPaytmMobile
            },
            ffAcountNo : {
                rules:[],
                messages:['Account No is required.'],
                error:'',
                value:fullDetails.foPaymentDetails.fsAccountNo
            },
            fsIFSCCode : {
                rules:[],
                messages:['IFSC Code is required.'],
                error:'',
                value:fullDetails.foPaymentDetails.fsIFSCCode
            }
        };

        // if(profileParams.isNameEmailRequired===false){
        //     formValidation.firstName.rules = [];
        //     formValidation.lastName.rules = [];
        //     formValidation.emailAddress.rules = [];
        // }

        this.state = {
            tabValue: gender,
            paymentMethod:fullDetails.foPaymentDetails.fiPaymentMode,
            phone: localStorage.getItem('userPhone'),
            fullDetails:fullDetails,
            haveDonate : haveDonate,
            fiDonateMobyEarningTo:foDonateMobyEarningTo,
            isProfileSaved:false,
            isProfileSavedError:false,
            isTermCheck:hasTermCheck,
            formValidation:formValidation,
            categoryArray : userCagory,
            distanceBank: currentDistance
        }

        this.onUpdateProfile = this.onUpdateProfile.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.isValidValues = this.isValidValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onAddingItem = this.onAddingItem.bind(this);
        this.updateDistance = this.updateDistance.bind(this);
        this.isTermCheck = this.isTermCheck.bind(this);
        this.fiDonateMobyEarningTo = this.fiDonateMobyEarningTo.bind(this);
        this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
    }

    selectPaymentMethod(event){
        this.setState({
            paymentMethod:event.target.value
        })
    }

    fiDonateMobyEarningTo(event){
        this.setState({
            fiDonateMobyEarningTo:event.target.value
        })
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        var formValues = this.state.formValidation;
        formValues[name].value = value.trim();
        this.setState({ 'formValidation': formValues });
    }

    isValidValues(){
        var isItValid = true;
        var formValidation = this.state.formValidation;
        
        if(parseInt(this.state.paymentMethod)===2){
            formValidation['UPILink'].rules = ['required'];
            formValidation['fsPaytmMobile'].rules = [];
            formValidation['ffAcountNo'].rules = [];
            formValidation['fsIFSCCode'].rules = [];
        }   
        if(parseInt(this.state.paymentMethod)===1){
            formValidation['UPILink'].rules = [];
            formValidation['fsPaytmMobile'].rules = ['required','pmobile'];
            formValidation['ffAcountNo'].rules = [];
            formValidation['fsIFSCCode'].rules = [];
        }
        if(parseInt(this.state.paymentMethod)===4){
            formValidation['UPILink'].rules = [];
            formValidation['fsPaytmMobile'].rules = [];
            formValidation['ffAcountNo'].rules = ['required'];
            formValidation['fsIFSCCode'].rules = ['required'];
        }

        // console.log('paymentMethod',this.state.paymentMethod);
        // console.log('fsPaytmMobile',formValidation['fsPaytmMobile'].value);
        // console.log('UPILink',formValidation['UPILink'].value);
        // console.log(formValidation['UPILink'].rules);

        // isItValid = false;

        Object.entries(formValidation).forEach(entry => {
            const [fieldName, fieldValue] = entry;
            formValidation[fieldName].error="";
            
            fieldValue.rules.map((rules,i)=>{
                if(rules==='required' && (formValidation[fieldName].value==="" || formValidation[fieldName].value==="0000-00-00")){
                    formValidation[fieldName].error=<div className="error">{fieldValue.messages[i]}</div>;
                    isItValid = false;
                }else if(rules==='email' && formValidation[fieldName].error===""){
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if(!pattern.test(formValidation[fieldName].value)){
                        formValidation[fieldName].error=<div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }else if(rules=="pmobile" && formValidation[fieldName].value.length!=10){
                    formValidation[fieldName].error=<div className="error">{fieldValue.messages[i]}</div>;
                    isItValid = false;
                }
                return(<></>);
            });
            
        });
        // console.log('isItValid',isItValid);
        // isItValid = false;

        if(isItValid===true){
            return isItValid;
        }
        return formValidation;
    }

    handleChange(event, newValue){
        this.setState({"tabValue":newValue});
    }

    onUpdateProfile() {
        var validStatus = this.isValidValues();
        if(this.state.isTermCheck===false || this.state.isTermCheck===""){
            this.setState({'isProfileSavedError':true,"isProfileSaved":false,"isTermCheck":false});
        }else{
            if(validStatus===true){
                this.setState({'isProfileSaved':true,'isProfileSavedError':false});

                /* Update Profile */
                var passCategory = "";
                this.state.categoryArray.map((singleCategory)=>{
                    if(singleCategory.fbIsSelected===true){
                        if(passCategory===""){
                            passCategory = singleCategory.fiCatId;
                        }else{
                            passCategory = passCategory + "," + singleCategory.fiCatId;
                        }
                    }
                    return(<></>)
                });
                var requestParams = {
                    "fsAction":"saveFullProfile",
                    "fsBankCategories":passCategory,
                    "fiCurrentDistance":this.state.distanceBank,
                    "fbIsCreditCardCheck":"0",
                    "fsReferrerCode":this.state.formValidation.referrelCode.value,
                    "fbPrivacyCheched":this.state.isTermCheck===true?0:1,
                    "fdDOB":this.state.formValidation.dateOfBirth.value,
                    "fiSelectedNGOId":this.state.fiDonateMobyEarningTo,
                    "fsEmail":this.state.formValidation.emailAddress.value,
                    "fsGender":this.state.tabValue===0?'male':'female',
                    "fiPaymentMode":this.state.paymentMethod,
                    'fsUPILink':this.state.formValidation.UPILink.value,
                    'fsPaytmMobile':this.state.formValidation.fsPaytmMobile.value,
                    'fsAccountNo':this.state.formValidation.ffAcountNo.value,
                    'fsIFSCCode':this.state.formValidation.fsIFSCCode.value,
                    "fsFirstName":this.state.formValidation.firstName.value,
                    "fsLastName":this.state.formValidation.lastName.value
                };

                config.fetchInformation(requestParams).then((responseJson)=>{
                    if(responseJson.fbIsError){
                        console.error(responseJson.fsMessage);
                    }else{
                        localStorage.setItem('isProfileUpdated', true);
                        this.props.onUpdateProfile();
                    }
                })

            }else{
                this.setState({'formValidation': validStatus,'isProfileSavedError':true})
            }
        }
    }

    updateDistance(distanceBank){
        this.setState({ 'distanceBank': distanceBank })
    }

    onAddingItem = (i) => (event) => {
        console.log(i);
        var catArray = this.state.categoryArray;
        if(catArray[i].fbIsSelected===true){
            catArray[i].fbIsSelected = false;
        }else{
            catArray[i].fbIsSelected = true;
        }
        //catArray[i].status = !catArray[i].status;
        this.setState({ 'categoryArray': catArray })
    }

    isTermCheck = (event) => {
        localStorage.setItem('hasTermCheck',true);
        this.setState({ 'isTermCheck': event.target.checked })
    }
    

    haveNeedToDonate = (event) => {
        this.setState({ 'haveDonate': event.target.checked });
    };

    render() {
        // console.log('paymentMethod',this.state.paymentMethod);

        var categoryArray = this.state.categoryArray;
        var alreadyUpdatedProfile = "";
        
        alreadyUpdatedProfile = <div className="profileUpdatedSection">

                <Card>
                    <CreditCard fullDetails={this.state.fullDetails}/>
                </Card>

                <Card>
                <div className="categorySection">
                    <h3>Card Offers Categories I Want To See</h3>
                    <Grid container spacing={2}>
                        {categoryArray.map((singleArray,i) => {
                            return (
                                <Grid key={i} item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="hasDonate"
                                                color="primary"
                                                checked={singleArray.fbIsSelected}
                                                onChange={this.onAddingItem(i)}
                                                // checked="true"
                                            />
                                        }
                                        label={singleArray.fsCatName}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                </Card>
                <Card>
                    <div className="kmRadiusSetting">
                        <h3>I Want To See Bank Offer Within</h3>
                        <ul>
                            <li onClick={()=>this.updateDistance(250)} className={this.state.distanceBank===250?'active':''}>250M</li>
                            <li onClick={()=>this.updateDistance(500)} className={this.state.distanceBank===500?'active':''}>500M</li>
                            <li onClick={()=>this.updateDistance(1000)} className={this.state.distanceBank===1000?'active':''}>1KM</li>
                            <li onClick={()=>this.updateDistance(3000)} className={this.state.distanceBank===3000?'active':''}>3KM</li>
                            <li onClick={()=>this.updateDistance(5000)} className={this.state.distanceBank===5000?'active':''}>5KM</li>
                        </ul>
                    </div>
                </Card>
                        
                
                <Card>
                        <div className="categorySection profilePaymentMethods">
                        
                        <h3>Payment Transfer Setting</h3>
                        <Grid container spacing={2}>
                            {this.state.fullDetails.foWalletList.map((singleArray,i) => {
                                var key = 'paymnent'+i;
                                return (
                                    <Grid key={key} item xs={6}>
                                        <FormControlLabel value={singleArray.fiId}
                                            control={
                                                <Radio
                                                    name="hasPaymentMethod"
                                                    color="primary"
                                                    checked={parseInt(singleArray.fiId)===parseInt(this.state.paymentMethod)?true:false}
                                                    onChange={this.selectPaymentMethod}
                                                    // checked="true"
                                                />
                                            }
                                            label={singleArray.fsName}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>

                        {parseInt(this.state.paymentMethod)===1?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="upiPaymentMethod">
                                        <div className="withLabel">
                                                <TextField name="fsPaytmMobile" id="outlined-otp" label="Paytm Mobile" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                                defaultValue={this.state.formValidation.fsPaytmMobile.value} 
                                                />
                                                {this.state.formValidation.fsPaytmMobile.error}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        :''}
                        
                        {parseInt(this.state.paymentMethod)===2?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="upiPaymentMethod">
                                        <div className="withLabel">
                                                <TextField name="UPILink" id="outlined-otp" label="UPI Address" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                                defaultValue={this.state.formValidation.UPILink.value} 
                                                />
                                                {this.state.formValidation.UPILink.error}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        :''}

                        {parseInt(this.state.paymentMethod)===4?
                            <div className="fullWidthBank">
                                <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="upiPaymentMethod">
                                        <div className="withLabel">
                                                <TextField name="ffAcountNo" id="outlined-otp" label="Bank Account No" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                                defaultValue={this.state.formValidation.ffAcountNo.value} 
                                                />
                                                {this.state.formValidation.ffAcountNo.error}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="upiPaymentMethod">
                                        <div className="withLabel">
                                                <TextField name="fsIFSCCode" id="outlined-otp" label="IFSC Code" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                                defaultValue={this.state.formValidation.fsIFSCCode.value} 
                                                />
                                                {this.state.formValidation.fsIFSCCode.error}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        :''}

                    </div>
                    </Card>
                
            </div>
        var isLoginByPhone = {'display':'none'};
        if(config.getProviderSetting().fbIsDirectRegistration === false){
            isLoginByPhone = {'display':'block'};
        }
        return (
            <div className="updateProfileScreen removeAnimation" style={{'paddingBottom':'15px'}}>
                <form noValidate autoComplete="off">
                    <div className="firstNameLastName">
                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="userUniqueId">
                                        <label>Support ID : </label>
                                        <span>{this.state.fullDetails.foUserDetails.fiUserId}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="withLabel" data-disabled={this.state.formValidation.firstName.isDisable?true:false}>
                                        <TextField name="firstName" id="outlined-otp" label="First name" InputLabelProps={{ shrink: true }}  onChange={(event) => this.handleUserInput(event)}
                                        defaultValue={this.state.formValidation.firstName.value} 
                                        />
                                        {this.state.formValidation.firstName.error}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="withLabel" data-disabled={this.state.formValidation.lastName.isDisable?true:false}>
                                        <TextField name="lastName"  id="outlined-otp" label="Last name" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                        defaultValue={this.state.formValidation.lastName.value} 
                                        />
                                        {this.state.formValidation.lastName.error}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="mapListToggle genderMaleFemale" data-disabled={this.state.fullDetails.foUserDetails.fsGender!==""?true:false}>
                                        <Paper square>
                                            <Tabs
                                                value={this.state.tabValue}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={this.handleChange}
                                                aria-label="disabled tabs example"
                                                centered
                                            >
                                                <Tab label="Male" />
                                                <Tab label="Female" />
                                            </Tabs>
                                        </Paper>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="withLabel" data-disabled={this.state.fullDetails.foUserDetails.fdDob!=="0000-00-00"?true:false}>
                                        <TextField
                                            id="date"
                                            label="Birthday"
                                            type="date"
                                            onChange={(event) => this.handleUserInput(event)}
                                            name="dateOfBirth"
                                            InputLabelProps={{ shrink: true }}
                                            defaultValue={this.state.fullDetails.foUserDetails.fdDob==='0000-00-00'?'':this.state.fullDetails.foUserDetails.fdDob} 
                                        />
                                        {this.state.formValidation.dateOfBirth.error}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.phone!==null?
                                        <div style={isLoginByPhone} className="OTPInput" data-disabled={this.state.phone!==""?true:false}>
                                            <div>
                                                <label>Phone Number</label>
                                                <PhoneInput
                                                    country={'in'}
                                                    autoFormat={false}
                                                    onlyCountries={['in','us','gb','au','ae']}
                                                    // disableDropdown="true"
                                                    // disableCountryCode="true"
                                                    countryCodeEditable={false}
                                                    placeholder=""
                                                    value={this.state.phone}
                                                    onChange={phone => this.setState({ phone })}
                                                />
                                            </div>
                                        </div>
                                    :''}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="withLabel" data-disabled={this.state.formValidation.emailAddress.isDisable?true:false}>
                                        <TextField name="emailAddress" id="outlined-otp" label="Email Address" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                        defaultValue={this.state.formValidation.emailAddress.value} 
                                        />
                                        {this.state.formValidation.emailAddress.error}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="withLabel" data-disabled={true}>
                                        <TextField name="referrelCode" id="outlined-otp" label="Referrel Code" InputLabelProps={{ shrink: true }} onChange={(event) => this.handleUserInput(event)} 
                                        defaultValue={this.state.formValidation.referrelCode.value} 
                                        />
                                        {this.state.formValidation.referrelCode.error}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                        
                        {/* Condition for already updated profile */}
                        {alreadyUpdatedProfile}
                        {/* Condition for already updated profile */}

                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="acceptTerms">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="acceptTerms"
                                                    color="primary"
                                                    checked={this.state.isTermCheck}
                                                    onChange={this.isTermCheck}
                                                />
                                            }
                                            label={<span>I accept the <span onClick={this.props.acceptTermsAndCondition} className="termsLink">Terms & Conditions</span></span>}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>

                        {this.state.isProfileSaved === true ?
                            <Grid item xs={12}>
                                <Alert severity="success">Profile updated!</Alert>
                            </Grid>
                            : ''}
                        
                        {this.state.isProfileSavedError === true ?
                            <Grid item xs={12}>
                                <Alert severity="error">Error on Profile update.</Alert>
                            </Grid>
                            : ''}

                        {this.state.isTermCheck=== false ?
                            <Grid item xs={12}>
                                <Alert severity="error">You have need to check terms and condition.</Alert>
                            </Grid>
                        : ''}
                    </div>
                    <div className="submitButton">
                        <Button onClick={this.onUpdateProfile} variant="contained" color="secondary">
                                Save Profile
                        </Button>
                    </div>
                </form>
            </div>

        )
    }
}