import React, { Component } from 'react';
import config from './config';
import trackingEvent from './TrackingEvents';
import { motion } from "framer-motion";

import { Redirect  } from 'react-router-dom'; 

import TopBarDrawer from './TopBarDrawer';
import AdGrid from './AdGrid';
import MapList from './MapList';

export default class Dashboard extends Component{

    constructor(props) {
        super(props)

        var redirectTo = '';
        if(localStorage.getItem('redirectTo')!==undefined && localStorage.getItem('redirectTo')!==""){
            if(localStorage.getItem('redirectTo')==='timeline'){
                redirectTo = '/my-timeline/';
            }else if(localStorage.getItem('redirectTo')==='coupon'){
                redirectTo = '/ads-mark-as-read/'+localStorage.getItem('redirectId');
            }else if(localStorage.getItem('redirectTo')==='wallet'){
                redirectTo = '/wallet';
            }
            if(redirectTo!==""){
                this.props.history.push({
                    pathname: redirectTo,
                    state: { }
                });
            }
            localStorage.removeItem('redirectTo');
            localStorage.removeItem('redirectId');
        }

        trackingEvent.customActionEvent(trackingEvent.offer_loaded);
    }

    render(){ 
        var classNamesList = 'AppContent';
        if(this.props.location.pathname==='/map'){
            classNamesList = classNamesList + ' isMapView';
        }else{
            classNamesList = classNamesList + ' adGridView isInfiniteScroll';
        }

        // console.log(this.props.location.pathname);
        return(
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
                >
                <div className="AppDashboard">
                    <div className="topAndSideBar">
                        <TopBarDrawer isHome="true" strTitle="Dashboard"/>
                    </div>

                    <div className={classNamesList}>
                        {this.props.location.pathname==='/map'?
                            <MapList history={this.props.history}/>
                        :
                            <AdGrid history={this.props.history}/>
                        }
                    </div>
                </div>
            </motion.div>
        )
    }
}
