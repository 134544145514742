import React, { Component } from "react";

import config from './config';

import Alert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';

import ContinuePopup from './popup/ContinuePopup';

import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import AdSense from 'react-adsense';

import ReactGA from 'react-ga';

export default class LoginRegisterForm extends Component {
    constructor(props) {
        super(props)

        var formValidation = {
            dateOfBirth: {
                rules: ['required','minMax'],
                messages: ['Correct Age Will Ensure E-wallet Credit.','Correct Age Will Ensure E-wallet Credit.'],
                error: '',
                value: '30'
            },
            referrelCode: {
                rules: ['required'],
                messages: ['Referrel Code is required.'],
                error: '',
                value: ''
            },
            gender : {
                rules: ['required'],
                messages: ['Required For Gender Relevant Offers.'],
                error: '',
                value: "0"
            },
            fiPaymentMethod : {
                rules: ['required'],
                messages: ['Required For Gender Relevant Offers.'],
                error: '',
                value: '4'
            },
            UPILink : {
                rules: ['upi'],
                messages: ['Please enter valid upi.'],
                error: '',
                value: ''
            },
            fbUseSamePaytmNo : {
                rules:[],
                messages:[],
                error:'',
                value:true
            },
            fsPaytmMobile : {
                rules:['pmobile'],
                messages:['Paytm mobile must be 10 digit only.'],
                error:'',
                value:""
            },
            phone : {
                rules: ['required','phone'],
                messages: ['Please Enter Phone No.','Please Enter Valid Phone No.'],
                error: '',
                value: ''
            }
        };

        if(localStorage.getItem('OpenPWAUserPromo')!==null && localStorage.getItem('OpenPWAUserPromo')!==undefined && localStorage.getItem('OpenPWAUserPromo')!=="SELECTION"){
            formValidation.referrelCode.value = localStorage.getItem('OpenPWAUserPromo');
        }

        var genderValue = "0";
        var defaultMobile = localStorage.getItem('userPhone') || '';
        if(defaultMobile.length > 0){
            if(config.getProviderSetting().fbIsProvideMobile){
                defaultMobile = defaultMobile.substr(defaultMobile.length - 10);
            }else{
                formValidation.phone.value = defaultMobile;
            }
        }
        if(localStorage.getItem('fiGender')!==null && localStorage.getItem('fiGender')!==undefined){
            formValidation.gender.value = localStorage.getItem('fiGender');
            genderValue = localStorage.getItem('fiGender');
        }

        if(localStorage.getItem('fiAge')!==null && localStorage.getItem('fiAge')!==undefined){
            formValidation.dateOfBirth.value = localStorage.getItem('fiAge');
        }
        

        console.log('formValidation',formValidation);

        localStorage.setItem('isShownMiniProfile','false');
        localStorage.setItem('displayCreditImage','true');

        if(localStorage.getItem('fiLoadCategory')!==null){
            localStorage.setItem('isFirstTimeAppOpen',false);
        }

        var fiBannerId = 0;
        if(localStorage.getItem('fiBannerId')!==null && parseInt(localStorage.getItem('fiBannerId')) > 0){
        fiBannerId = parseInt(localStorage.getItem('fiBannerId'));
        }

        this.state = {
            tabValue: genderValue,
            phone: defaultMobile,
            isProfileSaved: false,
            isProfileSavedError: false,
            formValidation: formValidation,
            isTermCheck: true,
            isProfileSavedServerError: "",
            isShowTermsPopup: false,
            termsResponse: '',
            isLoader:false,
            isShowReferralSelection : formValidation.referrelCode.value===""?true:false,
            adImages:[],
            walletList:[],
            referrelList:[
                "GOMMT" , "PBCASH5000" , "HEROCASH" , "TIMBRECASH"
            ],
            fiBannerId:fiBannerId,

            inputSuggestions:{
                mobile: 'Required For Cashback Credit to E-wallet.',
                gender: 'To Show Gender Relevant Brand, Coupons & Deals',
                age: 'To Show Age Relevant Brand, Coupons & Deals',
            }
        }

        this.onUpdateProfile = this.onUpdateProfile.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.isValidValues = this.isValidValues.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isTermCheck = this.isTermCheck.bind(this);
        this.closeTermsPopup = this.closeTermsPopup.bind(this);
        this.openTermsPopup = this.openTermsPopup.bind(this);
    }

    getAgeOptions(){
        var elements = [];
        for(var i =15; i < 100; i++){
            if(i==parseInt(this.state.formValidation.dateOfBirth.value)){
                elements.push(<option selected="selected" value={i}>{i} Year</option>);
            }else{
                elements.push(<option value={i}>{i} Year</option>);
            }
        }
        return elements;
    }

    componentWillMount() {
        ReactGA.initialize(config.trackingId);

        var requestParams = { 'fsAction':'getUserProfile','fsDeviceId':'pwa-device' };
        config.fetchInformation(requestParams).then((responseJson)=>{
            if(responseJson.fbIsUserExist===true){
                this.props.onSaveProfile();
            }
            this.setState({
                adImages: JSON.parse(localStorage.getItem('foGlobalSetting')).foRegisterPageImages,
                walletList: responseJson.foWalletList
            });
        });
    }

    openTermsPopup() {
        this.setState({ isShowTermsPopup: true });
    }

    closeTermsPopup() {
        this.setState({ isShowTermsPopup: false });
    }

    isTermCheck = (event) => {
        localStorage.setItem('hasTermCheck', true);
        this.setState({ 'isTermCheck': event.target.checked });
    }

    handleUserInput(e) {
        console.log(e);
        const name = e.target.name;
        var value = e.target.value;
        if(name==="fbUseSamePaytmNo"){
            value = e.target.checked;
        }else{
            value = value.toString().trim();
        }

        if(name==="referrelCode"){
            if(value!==""){
                localStorage.setItem('OpenPWAUserPromo',value)
            }
        }
        console.log(value);
        var formValues = this.state.formValidation;
        formValues[name].value = value;
        this.setState({ 'formValidation': formValues });
    }

    isValidValues() {
        var isItValid = true;
        var formValidation = this.state.formValidation;
        console.log('this.state.phone',this.state.phone);
        if(config.getProviderSetting().fbIsProvideMobile && this.state.phone.length <= 10){
            formValidation.phone.value = '91'+this.state.phone.toString();
        }else{
            formValidation.phone.value = this.state.phone.toString();
        }
        Object.entries(formValidation).forEach(entry => {
            const [fieldName, fieldValue] = entry;
            formValidation[fieldName].error = "";
            fieldValue.rules.map((rules, i) => {
                if (rules === 'required' && (formValidation[fieldName].value === "" || formValidation[fieldName].value === "0000-00-00")) {
                    formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                    isItValid = false;
                } else if (rules === 'email' && formValidation[fieldName].error === "") {
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (!pattern.test(formValidation[fieldName].value)) {
                        formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }else if (rules === 'minMax' && formValidation[fieldName].error === "") {
                    if (parseInt(formValidation[fieldName].value) < 15 || parseInt(formValidation[fieldName].value) > 100) {
                        formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }else if (parseInt(formValidation.fiPaymentMethod.value)===2 && rules === 'upi' && formValidation[fieldName].error === "") {
                    if (/^[\w.-]+@[\w.-]+$/.test(formValidation[fieldName].value)===false) {
                        formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }else if (parseInt(formValidation.fiPaymentMethod.value)===1 && rules === 'pmobile' && formValidation["fbUseSamePaytmNo"].value === false) {
                    if (formValidation[fieldName].value.length!==10) {
                        formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }else if (rules === 'phone' && formValidation[fieldName].error === "") {
                    if (new RegExp(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/).test(formValidation[fieldName].value)===false || formValidation[fieldName].value.length < 12) {
                        formValidation[fieldName].error = <div className="error">{fieldValue.messages[i]}</div>;
                        isItValid = false;
                    }
                }
                return (<></>);
            })
        });
        if (isItValid === true) {
            return isItValid;
        }
        return formValidation;
    }

    handleChange = (event) =>  {
        console.log('event.target.value',event.target.value);
        var formValidation = this.state.formValidation;
        formValidation.gender.value = event.target.value;
        this.setState({ "tabValue": event.target.value,'formValidation':formValidation });
    }

    onUpdateProfile() {
        ReactGA.event({
            category: 'profile_saved',
            action: 'profile_saved',
            label: 'Profile Saved'
        });

        var validStatus = this.isValidValues();
        if (this.state.isTermCheck === false || this.state.isTermCheck === "") {
            this.setState({ 'isProfileSavedError': true, "isProfileSaved": false, "isTermCheck": false });
        } else {
            if (validStatus === true) {
                this.setState({isLoader:true}) 
                /* Update Profile */
                var foDeviceDetails = '';
                if(localStorage.getItem('foDeviceDetails')!==""){
                    foDeviceDetails = localStorage.getItem('foDeviceDetails');
                }
                if(this.state.formValidation.referrelCode.value==='MOBYTRIAL'){
                    localStorage.setItem('fbIsMobileConnected','false');
                }else{
                    // localStorage.setItem('fbIsMobileConnected','true');
                }
                if(this.state.phone!==""){
                    if(config.getProviderSetting().fbIsProvideMobile){
                        localStorage.setItem('userPhone','91'+this.state.phone.toString());
                    }else{
                        localStorage.setItem('userPhone',this.state.phone.toString());
                    }

                    localStorage.setItem('PhonePrefix',"");
                }

                var fsPaytmMobile = "";
                if (parseInt(this.state.formValidation.fiPaymentMethod.value)===1 && this.state.formValidation.fbUseSamePaytmNo.value === false) {
                    fsPaytmMobile = this.state.formValidation.fsPaytmMobile.value;
                }
                
                var requestParams = {
                    "fsAction":"saveMiniProfile",
                    "fsReferrerCode": this.state.formValidation.referrelCode.value,
                    'fsUserContact': localStorage.getItem('userPhone'),
                    "fiAge": this.state.formValidation.dateOfBirth.value,
                    "fsGender": this.state.tabValue === 0 || this.state.tabValue === "0" ? 'male' : 'female',
                    "fsPaytmMobile":fsPaytmMobile,
                    'Device-Details':foDeviceDetails,
                    "fiEWalletId":this.state.formValidation.fiPaymentMethod.value,
                    "fsUPILink":this.state.formValidation.UPILink.value,
                };
                
                config.fetchInformation(requestParams).then((responseJson)=>{

                    if (responseJson.fbIsError === false || responseJson.fbIsUserExist===true) {
                        var formBody = {fsAction:'getGlobalSetting'};
                        config.fetchInformation(formBody).then((responseJson)=>{
                            localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));

                            this.setState({
                                'isProfileSaved': true,
                                isLoader:false
                            })
                            // localStorage.setItem('AuthToken', responseJson.token);
                            localStorage.setItem('isProfileUpdated', 'true');
                            localStorage.setItem('hasTermCheck', 'true');
                            this.props.onSaveProfile();
                        });
                    } else {
                        this.setState({
                            isProfileSavedServerError: responseJson.fsMessage,
                            'isProfileSaved': false,
                            isLoader:false
                        })
                    }
                });
            } else {
                this.setState({ 'formValidation': validStatus, 'isProfileSavedError': true })
            }
        }
    }


    render() {
        var alertImages = this.state.adImages;
        var inputLabel = 'Mobile Number';
        var disableInput = '';
        var setImageFrom = 0;
        var foSetting = config.getProviderSetting();

        if(foSetting.fbIsInputDisable){
            disableInput = 'disable-input';
        }
        if(this.state.fiBannerId > 0){
            if(alertImages.length > 0){
                alertImages.forEach((item,index)=>{
                    if(parseInt(item.fiBannerId)===this.state.fiBannerId){
                        setImageFrom = index;
                        localStorage.setItem('fiBannerAdId',item.fiAdId);
                    }
                });
            }
        }else{
            if(alertImages.length > 0){
                localStorage.setItem('fiBannerId',alertImages[0].fiBannerId);

                var spiltArray = window.location.href.split('/category/');
                // console.log('spiltArray',spiltArray);
                localStorage.setItem('fiLoadCategory',alertImages[0].fiCategory);
                if(spiltArray[1]!==undefined){
                    localStorage.setItem('fiLoadCategory',spiltArray[1]);
                }

                localStorage.setItem('fiBannerAdId',alertImages[0].fiAdId);
            }
        }


        return (
            <div className="AppDashboardLogin">
                <div className="AppContent">

                    {this.state.isShowTermsPopup ?
                        <ContinuePopup
                            popupButtonText="Ok"
                            popupTitle="Terms & Condition"
                            popupContent={this.state.termsResponse.message}
                            handleClose={this.closeTermsPopup}
                            openDialog={this.state.isShowTermsPopup}

                        /> : ''}

                    <div className="updateProfileScreen pwa-mpokket  removeAnimation">
                        <form noValidate autoComplete="off">
                            <div className="firstNameLastName">
                            
                                <div className={this.state.phone===""?'firstParagraph':`firstParagraph ${disableInput}`}  style={{'padding':'6px 0px'}}>
                                    <div className="firstRow">
                                        {/* <span>My Age is </span> */}
                                        <div className="Phone">
                                            {/* <PhoneInput
                                                country={'in'}
                                                autoFormat={false}
                                                countryCodeEditable={false}
                                                enableSearch={true}
                                                searchPlaceholder='Search Country'
                                                // disabled={this.state.isAddedMobile === true?true:false}
                                                onlyCountries={['in']}
                                                disableDropdown="true"
                                                // disableCountryCode="true"
                                                placeholder="Phone Number"
                                                value={this.state.phone}
                                                onChange={phone => this.setState({ phone })}
                                            /> */}
                                            <div className="phone-prefix">+91</div>
                                            <div className="phone-input">
                                                <TextField id="standard-basic" defaultValue={this.state.phone} onChange={event => this.setState({ phone:event.target.value })} label={inputLabel} variant="standard" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>{this.state.formValidation.phone.error}</div>
                                    {/* <div style={{'marginTop':'8px'}}>I Want to see Offers for</div> */}
                                </div>

                                <div className={`secondParagraph ${disableInput}`}>
                                    
                                    <div className="age-and-gender"> 
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup aria-label="gender" name="gender1" value={this.state.tabValue} onChange={this.handleChange}>
                                                    <FormControlLabel value="1" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="0" control={<Radio />} label="Male" />
                                                </RadioGroup>
                                            </FormControl>
                                            <div>
                                                <select name="dateOfBirth" onChange={(event) => this.handleUserInput(event)}>
                                                    <option value="">Age</option>
                                                    {this.getAgeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>{this.state.formValidation.dateOfBirth.error}</div>
                                    <div>{this.state.formValidation.gender.error}</div>
                                </div>
                                
                                <div className="thirdParagraph payment-method" style={{'marginBottom':'7px'}}>
                                    <div style={{'marginTop':'-2px','marginBottom':'0px'}}>I Want Cashbacks In My </div>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-helper-label">Wallet</InputLabel>
                                        <Select
                                        labelId="fiPaymentMethod"
                                        value={this.state.formValidation.fiPaymentMethod.value}
                                        onChange={(event) => this.handleUserInput(event)}
                                        name="fiPaymentMethod"
                                        id="fiPaymentMethod"
                                        >
                                            {this.state.walletList.map((item)=>{
                                                if(item.fiId===3){
                                                    return <></>;
                                                }
                                                return (
                                                    <MenuItem key={item.fiId} value={item.fiId}>{item.fsName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    {/* <select name="fiPaymentMethod" onChange={(event) => this.handleUserInput(event)}>
                                        {this.state.walletList.map((item)=>{
                                            return (
                                                 <option key={item.fiId} value={item.fiId}>{item.fsName}</option>
                                            )
                                        })}
                                    </select> */}
                                    

                                    {/* {parseInt(this.state.formValidation.fiPaymentMethod.value)===1?
                                        <div className="upiPaymentMethod">
                                            <div><label>Paytm Mobile</label></div>
                                            <div><input type="text" name="fsPaytmMobile" onChange={(event) => this.handleUserInput(event)}/></div>
                                            <div>{this.state.formValidation.fsPaytmMobile.error}</div>
                                        </div>
                                    :''} */}

                                    {parseInt(this.state.formValidation.fiPaymentMethod.value)===2?
                                        <div className="upiPaymentMethod1">
                                            {/* <div><label>UPI Address( Make sure this is correct. )</label></div> */}
                                            <div>
                                                <TextField name="UPILink" id="standard-basic" onChange={(event) => this.handleUserInput(event)} label="UPI Address" variant="standard" />
                                                {/* <input type="text" name="UPILink" onChange={(event) => this.handleUserInput(event)}/> */}
                                            </div>
                                            <div>{this.state.formValidation.UPILink.error}</div>
                                        </div>
                                    :''}
                                    {this.state.isShowReferralSelection?<div className="paytm-wallet-selection">
                                        <FormControl>
                                            <InputLabel id="demo-simple-select-helper-label">Choose Your Code</InputLabel>
                                            <Select
                                            labelId="referrelCode"
                                            value={this.state.formValidation.referrelCode.value}
                                            onChange={(event) => this.handleUserInput(event)}
                                            name="referrelCode"
                                            id="referrelCode"
                                            >
                                                {this.state.referrelList.map((item)=>{
                                                    return (
                                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        <div>{this.state.formValidation.referrelCode.error}</div>
                                    </div>:''}
                                    {parseInt(this.state.formValidation.fiPaymentMethod.value)===1?
                                        <div>
                                            <div className="paytm-wallet-selection">
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox onChange={(event) => this.handleUserInput(event)} name="fbUseSamePaytmNo" defaultChecked={this.state.formValidation.fbUseSamePaytmNo.value} />} label="Yes, My above Mobile is registered with Paytm Wallet" />
                                                </FormGroup>
                                            </div>
                                            {this.state.formValidation.fbUseSamePaytmNo.value===false?
                                                <div className="paytm-mobile-no-cont">
                                                    <div><label>Paytm Mobile No</label></div>
                                                    <div>
                                                        {/* <input type="text" name="fsPaytmMobile" onChange={(event) => this.handleUserInput(event)}/> */}
                                                        <div className="Paytm-Phone">
                                                            <div className="phone-prefix">+91</div>
                                                            <div className="phone-input">
                                                                <TextField name="fsPaytmMobile" id="standard-basic" onChange={(event) => this.handleUserInput(event)} label="Paytm Mobile Number" variant="standard" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>{this.state.formValidation.fsPaytmMobile.error}</div>
                                                </div>
                                            :''}
                                        </div>
                                    :''}

                                </div>
                                
                                <div style={{'marginTop':'10px'}} className="submitButton introStep3 profileSaveLoader">
                                    <Button onClick={this.onUpdateProfile} variant="contained" color="secondary">
                                    {this.state.isLoader === true ? <CircularProgress color="secondary" />:'Save'}
                                    </Button>
                                </div>

                                <div className="fourthParagraph">
                                    {/* <img onClick={this.onUpdateProfile} src={config.getImageUrl('/images/mobile-ad-slider/1633957298.png')}/> */}
                                    {this.state.adImages.length > 0?<img onClick={this.onUpdateProfile} src={this.state.adImages[setImageFrom].fsImage}/>:''}
                                </div>
                                
                                <div className="alertMessages">
                                    {this.state.isProfileSaved === true ?<Alert severity="success">Profile updated!</Alert>: ''}
                                    {this.state.isProfileSavedError === true ? <Alert severity="error">Error on Profile update.</Alert>: ''}
                                    {this.state.isProfileSavedServerError !== "" ?<Alert severity="error">{this.state.isProfileSavedServerError}</Alert>: ''}
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    {/* <div className="">
                        <AdSense.Google
                            client={config.adsense.clientId}
                            slot={config.adsense.slotId}
                            style={{ display: 'block' }}
                            format="auto"
                            responsive="true"
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}