import React, { Component } from "react";
import config from '../config';
import { motion } from "framer-motion";
import Card from '@material-ui/core/Card';
import TopBarTitle from '../TopBarTitle';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from '@tjoskar/react-lazyload-img';

import MoreCashbackPopup from '../popup/MoreCashbackPopup';

import InternetNotAvailable from '../InternetNotAvailable';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdSense from 'react-adsense';

export default class MyCashHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      apiResponse: '',
      isOpenPopup: false,
      cashbackType: '',
      isDisplayMyCashHistory: false
    }

    this.goBack = this.goBack.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }


  componentWillMount() {
    var requestParams = { "fsAction": "getPendingCashHistory" };
    config.fetchInformation(requestParams).then((responseJson) => {
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
      });
    });
  }

  goBack() {
    this.props.history.push({
      pathname: '/wallet',
    });
  }

  openPopup(cashbackType) {
    if (cashbackType === 'instant-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'my-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'pending-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    }
  }

  closePopup() {
    this.setState({ 'isOpenPopup': false, cashbackType: '' });
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable />
      )
    }

    var totalCashAmount = 0;
    var totalVirtualAmount = 0;

    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
    var foPendingCashHistory = [];
    var fiTotalPendingCash = 0;
    if (this.state.apiResponse !== '') {
      foPendingCashHistory = this.state.apiResponse.foPendingCashHistory;
      fiTotalPendingCash = this.state.apiResponse.fiTotalPendingCash;
    }

    var NoDataFound = config.getProviderSetting().fsNoData;
    var reason = "No History Found.";
    var loader = <div className="apiCallLoader fullScreenLoader"><CircularProgress color="secondary" /></div>;

    return (
      <motion.div initial="initial" animate="in" exit="out" variants={config.pageAnimation} transition={config.pageTransition}>
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle={"Pending Cash" + " History"} goBack={this.goBack} />
          </div>
          {this.state.isLoading?loader:
            <div className="AppContent isNormalView isWalletPage isTopBarOnTop">
              {this.state.isOpenPopup ? <MoreCashbackPopup popupButtonText='Close'
                secondLineText=""
                handleClose={this.closePopup}
                cashbackType={this.state.cashbackType}
                openDialog={true} /> : ''}
              {(foPendingCashHistory.length > 0) ? <div className="timelineList walletList MyCashTimeLine">
                <div className="singleCouponTimeLine pendingcash">
                  <div className="timeLineContent">
                    <div className="items2 mycash-count">
                      <h3 style={{ margin: '0' }}>{"Pending Cashbacks"}</h3>
                      <div className="adsPrice textRight walletTime colorGreen" style={{ position: 'unset', marginTop: '0' }}>Rs. {fiTotalPendingCash}</div>
                    </div>
                    {foPendingCashHistory.map((singleTimeline, i) => {
                      var cardColor = 'singleCouponTimeLine ' + singleTimeline.fsPinColor + 'Card';
                      var fontColor = 'color' + singleTimeline.fsPinColor.charAt(0).toUpperCase() + singleTimeline.fsPinColor.slice(1);;
                      if (singleTimeline.fiTrasactionStatus === 0 || singleTimeline.fiTrasactionStatus === -1) {
                        totalVirtualAmount = totalVirtualAmount + parseInt(singleTimeline.fiQuizReward);
                      } else {
                        // if(singleTimeline.fiRewardCashback > 0){
                        totalCashAmount = totalCashAmount + parseInt(singleTimeline.fiRewardCashback);
                        // }else{
                        //     totalCashAmount = totalCashAmount + parseInt(singleTimeline.fiQuizReward);
                        // }
                      }

                      var cashStatus = "";
                      if (singleTimeline.fbIsVirtualCash) {
                        if (singleTimeline.fbIsVerifiedCash) {
                          cashStatus = 'Credited';
                          fontColor = 'colorGreen';
                        } else {
                          cashStatus = 'Pending';
                          fontColor = 'colorRed';
                        }
                      }

                      var cashTypeText = "Cash Reward";
                      if (singleTimeline.fbIsVirtualCash) {
                        cashTypeText = "Virtual Cash";
                      }
                      if (singleTimeline.fbIsCashback) {
                        cashTypeText = "Cash Back";
                        cashStatus = "Credited";
                        cardColor = 'singleCouponTimeLine cashBackCard';
                      }

                      var goToCouponLink = '/my-timeline/';
                      // if(singleTimeline.strGiftCardCoupon!==''){
                      goToCouponLink = '/my-timeline/' + singleTimeline.fiActivityId;
                      // }

                      var cashbackAmountText = "";
                      if (singleTimeline.fiQuizReward > 0) {
                        cashbackAmountText = <Link className="removeLine" to={goToCouponLink}><span className="cashback-title-name">Cashback Upto Rs. {singleTimeline.fiQuizReward} <span className="colorBlue">({cashTypeText})</span></span></Link>;
                      }


                      var trackedClass, valdatedClass, paidClass = "";
                      if (singleTimeline.fiTrasactionStatus === 0) {
                        trackedClass = "active";
                      } else if (singleTimeline.fiTrasactionStatus === 1) {
                        trackedClass = "active dashed";
                        valdatedClass = "active";
                      } else if (singleTimeline.fiTrasactionStatus === 2) {
                        trackedClass = "active dashed";
                        valdatedClass = "active dashed";
                        paidClass = "active";
                      } else if (singleTimeline.fiTrasactionStatus === 3) {
                        trackedClass = "active dashed";
                        valdatedClass = "active bg-red";
                      }
                      var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;
                      return (
                        <div className={cardColor}>
                          <Card>
                            <div className="timeLineContent">
                              <div className="wallet-item-with-image">
                                <div>
                                  <LazyLoadImage defaultImage={LazyPlaceholder} image={config.getImageUrl(singleTimeline.fsAdLogo)} />
                                </div>
                                <div>
                                  <div className="items2">
                                    <h3>{singleTimeline.fsAdName}</h3>
                                  </div>
                                  <div>
                                    {singleTimeline.fsWalletContent}
                                    {/* <span onClick={()=>{ props.openHelpPopup(singleTimeline)}} className="material-icons-outlined wallet-help-icon">help</span> */}
                                  </div>
                                </div>
                              </div>

                              <div className="pendingadsPrice" style={{ 'top': '0' }}>
                                {config.getProviderReferral() === 'MIDDAY' ?
                                  singleTimeline.fiAdCouponType === 0 ? <span className="maxCashback">Max {config.getCashbackString(singleTimeline.fbIsMyCash)} On Used Coupon</span> : ''
                                  :
                                  config.getCashbackString(singleTimeline.fbIsMyCash)} Rs. {singleTimeline.fiRewardCashback
                                }
                              </div>

                              <div className="track-v-cash">
                                <ul>
                                  <li className={trackedClass}>Tracked</li>
                                  {singleTimeline.fiTrasactionStatus === 3 ? <li className={valdatedClass}>Rejected</li> : <li className={valdatedClass}>Validated</li>}
                                  <li className={paidClass}>{singleTimeline.fiTrasactionStatus === 1 || singleTimeline.fiTrasactionStatus === 2 ? singleTimeline.fsEstimatePayDate : 'Cash Paid'}</li>
                                </ul>
                              </div>

                            </div>
                          </Card>
                        </div>
                      )
                    })}
                  </div>
                  {/* <div className="">
                    <AdSense.Google
                        client={config.adsense.clientId}
                        slot={config.adsense.slotId}
                        style={{ display: 'block' }}
                        format="auto"
                        responsive="true"
                    />
                </div> */}
                </div>
              </div> : <div style={{ padding: '15px' }}>
                <div className="NoDataFound">
                  <img src={NoDataFound} alt="No Data Found" />
                  <div>Oops!</div>
                  <div>
                    {reason}
                  </div>
                  {/* <div className="" style={{ 'marginTop':'50px' }}>
                      <AdSense.Google
                          client={config.adsense.clientId}
                          slot={config.adsense.slotId}
                          style={{ display: 'block' }}
                          format="auto"
                          responsive="true"
                      />
                  </div> */}
                </div>
              </div>}
            </div>
          }
        </div>
      </motion.div>
    )
  }
}