import React, { Component, forwardRef } from "react";
import config from "./config";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default class BillUploadPopup extends Component {
  constructor(props) {
    super(props);
    var minDate = props.fdEngageDate.split(", ")[0];
    minDate = minDate.split("-");

    var minDateSet = minDate[2] + "-" + minDate[1] + "-" + minDate[0];
    var maxDateSet = new Date().toISOString().split("T")[0];

    if (
      props.currentTimeline.fsPinColor !== undefined &&
      props.currentTimeline.fsPinColor === "green"
    ) {
      minDateSet = "1970-01-01";
      maxDateSet = "2050-01-01";
    }

    this.state = {
      adId: props.fiTrasactionId,
      tabValue: 0,
      fieldValues: {
        purchaseDate: new Date(),
        billAmount: 0,
        cardSelection: "0",
      },
      minMaxDate: {
        min: minDateSet,
        max: maxDateSet,
      },
      isLoading: false,
      userCards: "",
      isSumitResponse: "",

      fsFirstImage: "",
      fsSecondImage: "",
      isDisplayError: false,
      hasBillUploadImage: false,
    };

    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.uploadBillClickHandle = this.uploadBillClickHandle.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.removeBillImage = this.removeBillImage.bind(this);
    this.hasBillUploadImage = this.hasBillUploadImage.bind(this);
    this.changePurchaseDate = this.changePurchaseDate.bind(this);
  }

  hasBillUploadImage = (event) => {
    this.setState({ hasBillUploadImage: event.target.checked });
  };

  removeBillImage(removeImageName) {
    if (removeImageName === 1) {
      this.setState({ fsFirstImage: "" });
    } else {
      this.setState({ fsSecondImage: "" });
    }
  }

  changePurchaseDate(date) {
    const name = "purchaseDate";
    var formValues = this.state.fieldValues;
    formValues[name] = date;
    this.setState({ fieldValues: formValues });
  }

  uploadBillClickHandle() {
    var isDisplayError = false;
    if (this.state.fieldValues.purchaseDate === "") {
      isDisplayError = true;
    }
    if (this.state.fieldValues.billAmount === 0) {
      isDisplayError = true;
    }
    if (this.state.fsFirstImage === "" && this.state.fsSecondImage === "") {
      isDisplayError = true;
    }
    if (
      this.state.tabValue === 1 &&
      this.state.fieldValues.cardSelection === "0"
    ) {
      isDisplayError = true;
    }
    if (isDisplayError === true) {
      this.setState({ isDisplayError: true });
    } else {
      this.setState({ isDisplayError: false, isLoading: true });

      var requestParams = {
        fsAction: "uploadTrasactionBillPWA",
        fdTransactionDate: this.state.fieldValues.purchaseDate,
        fiAmount: this.state.fieldValues.billAmount,
        fiActivityId: this.state.adId,
        fsFirstImage: this.state.fsFirstImage,
        fsSecondImage: this.state.fsSecondImage,
      };

      config.fetchInformation(requestParams).then((responseJson) => {
        var requestParams1 = {
          fsAction: "couponMarkAsUsed",
          fiActivityId: this.state.adId,
        };
        config.fetchInformation(requestParams1).then((responseJson1) => {
          config.updateUserSession("bill_uploaded");
          this.setState(
            {
              isSumitResponse: responseJson,
              isLoading: false,
            },
            this.props.billUploaded()
          );
        });
      });
    }
  }

  componentWillMount() {
    // var requestParams = {
    // };
    // var formBody = config.convertParams(requestParams);
    // var request = config.getRequestHeaders();
    // fetch(config.API_URL+'GET_USER_CARD&'+formBody, request)
    // .then((response) => response.text())
    // .then((responseText) => {
    //   this.setState({
    //     userCards:JSON.parse(responseText),
    //   });
    // }).catch((error) => {
    //   console.error(error);
    // });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    var formValues = this.state.fieldValues;
    formValues[name] = value.trim();
    this.setState({ fieldValues: formValues });
  }

  handleChange(event, newValue) {
    this.setState({ tabValue: newValue });
  }

  goBack() {
    this.props.history.goBack();
  }

  handleImageUpload(e) {
    const [file] = e.target.files;
    var fileName = e.target.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //current.src = e.target.result;
        if (fileName === "fsFirstImage") {
          this.setState({ fsFirstImage: e.target.result });
        } else {
          this.setState({ fsSecondImage: e.target.result });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  render() {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <div className="refComponenetForDate" onClick={onClick} ref={ref}>
        {value}
      </div>
    ));

    console.log(this.state.minMaxDate);
    var profileForm = (
      <div className="updateProfileScreen removeAnimation">
        <form noValidate autoComplete="off">
          <div className="firstNameLastName billUploadForm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="withLabel">
                  <div className="transactionDate">Transaction Date</div>
                  <div className="changeFocusToHere">
                    <DatePicker
                      customInput={<ExampleCustomInput />}
                      onClick={this.changeFocusToHere}
                      dateFormat="dd/MM/yyyy"
                      withPortal
                      selected={this.state.fieldValues.purchaseDate}
                      onChange={(date) => this.changePurchaseDate(date)}
                    />
                  </div>
                  {/* <TextField
                                                id="date"
                                                label="Transaction Date"
                                                type="date"
                                                name="purchaseDate"
                                                defaultValue={this.state.fieldValues.purchaseDate}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(event) => this.handleUserInput(event)}
                                                InputProps={{ inputProps: { min: this.state.minMaxDate.min, max: this.state.minMaxDate.max }}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            /> */}
                  {this.state.isDisplayError &&
                  this.state.fieldValues.purchaseDate === "" ? (
                    <div className="error">Purchase date must be required</div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="withLabel">
                  <TextField
                    id="BillAmount"
                    label="Paid Amount"
                    type="number"
                    name="billAmount"
                    onChange={(event) => this.handleUserInput(event)}
                    InputLabelProps={{ shrink: true }}
                  />
                  {this.state.isDisplayError &&
                  this.state.fieldValues.billAmount === 0 ? (
                    <div className="error">Bill Amount must be required</div>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              <div
                className="mapListToggle genderMaleFemale"
                style={{ display: "none" }}
              >
                <div style={{ marginBottom: "4px" }}>
                  <label>Payment Type</label>
                </div>
                <Paper square>
                  <Tabs
                    value={this.state.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                    aria-label="disabled tabs example"
                    centered
                  >
                    <Tab label="Cash" />
                    <Tab label="Card" />
                  </Tabs>
                </Paper>
              </div>

              <div className="billUploadFaster">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hasBillUploadImage}
                        onChange={this.hasBillUploadImage}
                        name="isBillUpload"
                        color="primary"
                      />
                    }
                    label="I want to upload bill for faster verification."
                  />
                </Grid>
              </div>

              {this.state.hasBillUploadImage ? (
                <Grid item xs={12}>
                  <div>
                    <label>
                      <b>
                        Click Screenshot Of Bill/Order Or Upload from Photo
                        Gallery
                      </b>
                    </label>
                  </div>
                  <div className="billListContainer">
                    {this.state.fsFirstImage !== "" ? (
                      <div className="list uploadIcon">
                        <img src={this.state.fsFirstImage} alt="First Bill" />
                        <span
                          className="material-icons-outlined removeBillUploaded"
                          onClick={() => this.removeBillImage(1)}
                        >
                          clear
                        </span>
                      </div>
                    ) : (
                      <div className="list uploadIcon uploadFromGallery">
                        <span className="material-icons-outlined">add</span>
                        <input
                          type="file"
                          name="fsFirstImage"
                          accept="image/*"
                          onChange={this.handleImageUpload}
                        />
                      </div>
                    )}

                    {this.state.fsSecondImage !== "" ? (
                      <div className="list uploadIcon">
                        <img src={this.state.fsSecondImage} alt="Second Bill" />
                        <span
                          className="material-icons-outlined removeBillUploaded"
                          onClick={() => this.removeBillImage(2)}
                        >
                          clear
                        </span>
                      </div>
                    ) : (
                      <div className="list uploadIcon uploadFromGallery">
                        <span className="material-icons-outlined">add</span>
                        <input
                          type="file"
                          name="fsSecondImage"
                          accept="image/*"
                          onChange={this.handleImageUpload}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {this.state.isDisplayError &&
              this.state.fsFirstImage === "" &&
              this.state.fsSecondImage === "" ? (
                <Grid item xs={12}>
                  <div className="error" style={{ marginTop: "-10px" }}>
                    Bill image must be required
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </form>
      </div>
    );

    return (
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="mobyPopup continuePopupDesign billUploadPopup">
          <DialogTitle id="alert-dialog-slide-title">Regiter Bill</DialogTitle>

          <DialogContent>
            {/* <DialogContentText id="alert-dialog-slide-description"> */}
            <div className="bill-upload-popup">{profileForm}</div>
            {/* </DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.props.billUploadLater}
              color="secondary"
            >
              Later
            </Button>
            <Button
              variant="contained"
              onClick={this.uploadBillClickHandle}
              color="secondary"
            >
              {this.state.isLoading === true ? (
                <CircularProgress color="secondary" />
              ) : (
                <span>Register Now</span>
              )}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}
