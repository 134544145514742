import React, { Component } from 'react';
import config from './config';

import { motion } from "framer-motion";


import TopBarTitle from './TopBarTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InternetNotAvailable from './InternetNotAvailable';
import Card from '@material-ui/core/Card';

class Support extends Component {
    
    constructor() {
        super();
    
        this.state = {
            isLoading: true,
            apiResponse: {}
        }
    
        this.goBack = this.goBack.bind(this);
    }
    
    goBack() {
      this.props.history.push({
          pathname: '/',
      });
    }
    
    componentWillMount() {
      this.setState({
        isOpenTermsPopup:true
      });
      var requestParams = { "fsAction":"loadWebView", "fsPage":"support" };
      config.fetchInformation(requestParams).then((responseJson)=>{
        this.setState({
            apiResponse: responseJson,
            isLoading:false
          });
      });
    }
      render() {

        var message = "";
        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
        if(this.state.isLoading===false){
            message = this.state.apiResponse.fsMessage;
            message = message.replace('{{fiUserId}}',foGlobalSetting.fiUserId);
            message = message.replace('{{fsReferrerCode}}',foGlobalSetting.fsReferrerCode);
        }
        var supportBody = 'mailto:support@mobyads.in?subject=Support Request : '+foGlobalSetting.fiUserId+'('+foGlobalSetting.fsReferrerCode+')  &body=Your Comments';
        /* No Internet */
        if(!navigator.onLine){
          return(
            <InternetNotAvailable/>
          )
        }
    
        return (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={config.pageAnimation}
            transition={config.pageTransition}
          >
            <div className="AppDashboard">
              <div className="topAndSideBar">
                  <TopBarTitle strTitle="Support" goBack={this.goBack} />
              </div>
    
              <div className="AppContent isNormalView isTopBarOnTop">
                <div className="singleMessageCard singleCouponTimeLine">
                  <Card>
                      <div className="timeLineContent" style={{'paddingBottom':'0'}}>
                        <div>
                          {this.state.isLoading === true ?
                                <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                                :
                                <div className="termsDetails">
                                    <div dangerouslySetInnerHTML={{__html: message}}></div>
                                    <p class="markAsReadScreen"><a style={{'opacity':1,'textTransform':'uppercase'}} href={supportBody} className="Coupon ShopOnline support-button">Submit Request</a></p>
                                </div>
                            }
                        </div>
                      </div>
                  </Card>
                </div> 
              </div>
            </div>
          </motion.div>
        )
      }
}

export default Support;