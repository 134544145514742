import React, { Component } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ProviderInfo extends Component {

  constructor() {
    super();

    this.state = {
        
    }
}

componentWillMount() {
    if(this.props.match.params.fiCategoryId!==undefined && this.props.match.params.fiCategoryId!==""){
        localStorage.setItem('fiDirectLoadCategory',this.props.match.params.fiCategoryId);
    }
    this.props.history.push({
        pathname: '/',
    });
}
  render() {
    

    return (
      
        <div className="AppDashboard">
          <div className="AppContent isNormalView">
                <div className="singleMessageScreen">
                <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                </div>
            </div> 
        </div>
    )
  }
}