const firebaseConfig = {
    apiKey: "AIzaSyCppMFHNXmj9ZY54f7-kmM2287LSLCawyU",
    authDomain: "mobyads-ee40d.firebaseapp.com",
    databaseURL: "https://mobyads-ee40d.firebaseio.com",
    projectId: "mobyads-ee40d",
    storageBucket: "mobyads-ee40d.appspot.com",
    messagingSenderId: "335145993405",
    appId: "1:335145993405:web:21ab47c3c4cfbd199d23d4"
  };

export default firebaseConfig;