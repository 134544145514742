import React, { Component } from "react";
import config from './config';
import { motion } from "framer-motion";

import TopBarTitle from './TopBarTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InternetNotAvailable from './InternetNotAvailable';
import Card from '@material-ui/core/Card';

export default class TermsAndCondition extends Component {

  constructor() {
    super();

    this.state = {
        isLoading: true,
        apiResponse: {}
    }

    this.goBack = this.goBack.bind(this);
}

goBack() {
  this.props.history.push({
      pathname: '/',
  });
}

componentWillMount() {
  this.setState({
    isOpenTermsPopup:true
  });
  var requestParams = { "fsAction":"loadWebView", "fsPage":"tandc" };
  config.fetchInformation(requestParams).then((responseJson)=>{
    this.setState({
        apiResponse: responseJson,
        isLoading:false
      });
  });
}
  render() {
    
    /* No Internet */
    if(!navigator.onLine){
      return(
        <InternetNotAvailable/>
      )
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className="AppDashboard">
          <div className="topAndSideBar">
              <TopBarTitle strTitle="Our Policies" goBack={this.goBack} />
          </div>

          <div className="AppContent isNormalView isTopBarOnTop">
            <div className="singleMessageCard singleCouponTimeLine">
              <Card>
                  <div className="timeLineContent" style={{'paddingBottom':'0'}}>
                    <div>
                      {this.state.isLoading === true ?
                            <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                            :
                            <div className="termsDetails" dangerouslySetInnerHTML={{__html: this.state.apiResponse.fsMessage}}></div>
                        }
                    </div>
                  </div>
              </Card>
            </div> 
          </div>
        </div>
      </motion.div>
    )
  }
}