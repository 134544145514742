import React,{Component} from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Alert from '@material-ui/lab/Alert';

export default class MarkAsReadPopupRed extends Component {
    constructor(props){
      super(props);
      // this.props.currentTimeline.fiMarkIsUsedType = 1;
      this.state = {
        isDisableMarkAsRead : this.props.currentTimeline.fiMarkIsUsedType===1 || this.props.currentTimeline.fiMarkIsUsedType===2?true:false,
        billAmount : 0,
        storeCode : "",
        isError : false,
        errorText : "Bill amount that you have paid is required."
      }
      this.handlePhoneChange = this.handlePhoneChange.bind(this);
      this.handleUserInput = this.handleUserInput.bind(this);
      this.submittedMarkAsRead = this.submittedMarkAsRead.bind(this);
    }

    submittedMarkAsRead(){
      
      var errorText = "";
      if(this.state.billAmount <= 0){
        errorText = "Bill amount that you have paid is required.";
      }
      if(errorText=="" && this.props.currentTimeline.fiMarkIsUsedType==4){
          if(this.state.storeCode===""){
            errorText = "Store code is required, ask the cashier for it";
          }
          if(errorText=="" && this.state.storeCode!==this.props.currentTimeline.fsQrCodePasswordText){
            errorText = "Store code is invalid, Please contact store manager";
          }
      }

      
      // console.log(this.state.storeCode);
      // console.log(this.props.currentTimeline);
      if(errorText===""){
        console.log("Submit It");
        this.props.updateMarkAsRead(this.state.billAmount);
        this.setState({ isError : false });
      }else{
        this.setState({ isError : true, 'errorText':errorText });
      }

      // if(this.props.currentTimeline.fiMarkIsUsedType==4 && 
      //  (this.state.billAmount<=0 || this.state.storeCode=="") ){
      //   this.setState({ isError : true, 'errorText':'Bill amount & store code must be valid' });
      // }else if(this.state.billAmount <= 0){
      //   this.setState({ isError : true, 'errorText':'Bill Amount must be required' });
      // }else{
      //   if(this.props.currentTimeline.fsGiftCardUsedPassword)
      //   // console.log(this.state.billAmount,this.state.storeCode);
      //   this.props.updateMarkAsRead(this.state.billAmount,this.state.storeCode);
      //   this.setState({ isError : false });
      // }

      // if(this.state.billAmount > 0){
      //   this.props.updateMarkAsRead(this.state.billAmount);
      //   this.setState({ isError : false });
      // }else{
      //   this.setState({ isError : true });
      // }
      
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({billAmount: value} );
    }

    handlePhoneChange(phone) {
        phone = phone.replace('+','');
        if(phone===localStorage.getItem('userPhone').replace('+','')){
            this.setState({isDisableMarkAsRead:false});
        }else{
            this.setState({isDisableMarkAsRead:true});           
        }
    }

    render(){
      return (
        <Dialog
        classes={{root:'popupMaxWidth'}}
        open={this.props.openDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="mobyPopup continuePopupDesign markAsUsedRed">
            <div className="closePopup" onClick={this.props.handleClose}>
              <span className="material-icons-outlined">close</span>
            </div>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                
                <div className="MarkAsReadDetails">
                  {this.props.currentTimeline.fiMarkIsUsedType===1 || this.props.currentTimeline.fiMarkIsUsedType===2?
                    <div className="OTPInput">
                        <div>
                            <label>Phone No</label>
                            <PhoneInput
                                country={'in'}
                                autoFormat={false}
                                countryCodeEditable={false}
                                enableSearch={true}
                                searchPlaceholder='Search Country'
                                placeholder="Mobile No. to Mark as Used"
                                onChange={(phone) => this.handlePhoneChange(phone)}
                            />
                        </div>
                    </div>
                  :
                    <div className="coupon-code">
                      <div className="couponCode">
                        {this.props.currentTimeline.fsCouponCode}
                      </div>
                      <div>{this.props.currentTimeline.fbIsMyCash==="yes"?"SHARE COUPON CODE BEFORE YOU PAY":"SHARE COUPON CODE WITH CASHIER"}</div>
                    </div>
                  }

                  <div className="bill-amount-storecode">
                    <TextField
                            id="BillAmount"
                            placeholder={this.props.currentTimeline.fbIsMyCash==="yes"?"ENTER FINAL BILL AMOUNT":"ASK CASHIER FOR BILL AMT"}
                            type="number"
                            name="billAmount"
                            onChange={(event) => this.handleUserInput(event)}
                            InputLabelProps={{ shrink: true }}
                        />
                    {this.props.currentTimeline.fiMarkIsUsedType===4?<TextField
                          id="storeCode"
                          placeholder={this.props.currentTimeline.fbIsMyCash==="yes"?"ASK CASHIER ENTER STORE CODE":"ASK CASHIER FOR STORE CODE"}
                          type="text"
                          name="storeCode"
                          onChange={(event) => this.setState({ storeCode : event.target.value })}
                          InputLabelProps={{ shrink: true }}
                      />:""}
                  </div>
                
                  <div className="labelMessage">
                    {this.props.CashbackString} credit in chosen wallet after validation
                  </div>
                  

                  {this.state.isError?
                    <Alert style={{ 'textAlign':'left' }} severity="error">{this.state.errorText}</Alert>
                  :''}

                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className={this.state.isDisableMarkAsRead?'disableButton':'enableButton'} style={{'width':'100%'}}>
                <Button onClick={this.submittedMarkAsRead} variant="contained" color="primary">
                  Mark Coupon Used
                </Button>
              </div>
            </DialogActions>
        </div>
      </Dialog>
      );
      }
}