import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import config  from './config';

export default class TopBarTitle extends Component {

  constructor() {
    super();
  }

  
  
  render() {
    let moreAdLink = '/';
    let fbIsBuyMyCashEnable = JSON.parse(localStorage.getItem('foGlobalSetting')).fbIsBuyMyCashEnable;
    return (    
        <div className="TopBarWithBack">
            <span className="material-icons-outlined" onClick={this.props.goBack}>
                keyboard_backspace
            </span>
            <span>
                {this.props.strTitle}
            </span>
            {(function($this){
              if($this.props.strTitle==='Wallet' && fbIsBuyMyCashEnable===true){
                return <Link to={moreAdLink} className="searchItemList">
                  {/* <button type="button" className="buy-mycash-btn" onClick={()=>{
                    localStorage.setItem('fiLoadCategory', 69);
                    localStorage.setItem('fiDirectLoadCategory', 69);
                  }} >BUY {config.getGlobalCashbackString().toUpperCase()}</button> */}
                </Link>;
              }
            })(this)}
        </div>
    )
  }

}