import React from "react";
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function TimeLinePopUp(props) {
    var cashBackName = "MOBY Cashback";
    if(localStorage.getItem('OpenPWAUserPromo')==='FUTUREPAY'){
      cashBackName = "FPay Cashback";
    }
    return (

        <Dialog
          open={props.openDialog}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup timeLineLink">
            <DialogTitle id="alert-dialog-slide-title">{"Don’t like your coupons?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Search for your favorite brands,<br/>Win coupon, & Shop to Get upto<br/><br/>
                  50% off + Extra {cashBackName}<br/>Over & Above All Other Deals!<br/><br/>
                  Like to Shop Offline? Try Map view<br/>(Need 1 Verified Online Purchase)<br/>Yellow Pins - Real time Card Offers,<br/>Red Pins - All In-Store Coupons<br/>Shown Nearby you, But Pan India!
                </DialogContentText>
                <DialogContentText id="alert-dialog-slide-description">
                  <Button variant="contained" onClick={props.popupClose} color="primary">
                    Redeem Coupon
                  </Button>
                  <Button variant="contained" color="primary" onClick={props.handleBillVerifiedPopup}>
                  See Offline Offers
                  </Button>
                  <Button onClick={props.handleClose} variant="contained" color="primary">
                    Back To Online Offers
                  </Button>
                </DialogContentText>
              </DialogContent>
          </div>
        </Dialog>

    );
}