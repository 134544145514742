import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function ContinuePopup(props) {
    return (
        <Dialog
          classes={{root:'popupMaxWidth'}}
          open={props.openDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup continuePopupDesign">
            <DialogTitle id="alert-dialog-slide-title">{props.popupTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" dangerouslySetInnerHTML={{__html: props.popupContent}}>
                </DialogContentText>
                {props.secondLineText!==""?
                <DialogContentText id="alert-dialog-slide-description">
                  {props.secondLineText}
                </DialogContentText>
                :''}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose} variant="contained" color="primary">
                  {props.popupButtonText}
                </Button>
              </DialogActions>
          </div>
        </Dialog>
    );
}