import React, { Component } from "react";
import config from "./config";

import { motion } from "framer-motion";
import TopBarDrawer from "./TopBarDrawer";

export default class InternetNotAvailable extends Component {
  // constructor(props) {
  //     super(props)
  // }

  render() {
    var message = (
      <div className="NoDataFound">
        <div className="noIternetIcon">
          <span className="material-icons-outlined">wifi_off</span>
        </div>
        <div>Oops!</div>
        <div>You're offline right now. Check your internet connection.</div>
      </div>
    );

    if (this.props.isWithoutTopBar) {
      return <>{message}</>;
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarDrawer strTitle="No Internet" />
          </div>
          <div className="AppContent isNormalView">{message}</div>
        </div>
      </motion.div>
    );
  }
}
