import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from '@tjoskar/react-lazyload-img';
import config from './config';
import backgroundImage from '../component/images/coupon-bg-background.png'; // Proper import
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  contentOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  imageRowContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  savingsInfo: {
    textAlign: 'center',
  },
}));


export default function MapItemList(props) {
  var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

  const classes = useStyles();

  function getTotalSavePer(currentOffer) {
    // Get the total instant cash from localStorage
    let fiTotalInstantCash = JSON.parse(localStorage.getItem('foGlobalSetting')).fiTotalInstantCash;
    let fsDiscountUpTo = 0;

    // Calculate the discount percentage if conditions are met
    if (fiTotalInstantCash > 0 && currentOffer.fiDefVouDenomination > 0) {
        fsDiscountUpTo = parseInt(
            (fiTotalInstantCash * 100) /
            parseInt(currentOffer.fiDefVouDenomination)
        );
    }

    // Get flat cashback percentage
    let flatCashback = currentOffer.fsFlatCashBackPer;

    // Calculate the final percentage
    let finalper = flatCashback + fsDiscountUpTo;

    // Cap the final percentage at 99
    if (finalper > 99) {
        finalper = 99;
    }

    // Return the final percentage
    return finalper;
}

  const cash = config.getGlobalCashbackString()

  var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;

  let offer_type = props.marker.foOfferCardDetails.fsOfferType;
  let top_text = props.marker.foOfferCardDetails.fsTopText;
  let middel_text = props.marker.foOfferCardDetails.fsMiddleText;
  let bottom_text = props.marker.foOfferCardDetails.fsBottomText;
  let botton_text = props.marker.foOfferCardDetails.fsButtonName;

  
  if (props.marker.fsAdType === 'Bank Offer') {
    var moreAdLink = '/bank-offer-details/' + props.marker.fiAdId + '/' + props.marker.foLocationList[0].fiLocationId;
  } else if (props.marker.fsPinColor === "red-voucher") {
    var moreAdLink = '/voucher-details/' + props.marker.fiAdId + '/' + props.marker.foLocationList[0].fiLocationId;
  } else if (props.marker.fsPinColor === "green" && props.marker.fsMarkIsUsedFlow === 'buy-mycash') {
    var moreAdLink = '/buymycash-details/' + props.marker.fiAdId + '/' + props.marker.foLocationList[0].fiLocationId;
  } else {
    //add-details
    var moreAdLink = '/start-quiz/' + props.marker.fiAdId + '/' + props.marker.foLocationList[0].fiLocationId;
  }

  /* Green Ad Quiz Flow */
  let directMoveOnCouponScreen = true;
  if (props.marker.fsMarkIsUsedFlow === 'buy-mycash') {
    directMoveOnCouponScreen = false;
  }
  if (props.marker.fbIsQuizFlow || foGlobalSetting.fbIsQuizFlow) {
    directMoveOnCouponScreen = false;
  }

  if (props.marker.fsPinColor === 'red' || props.marker.fsMarkIsUsedFlow === 'offline-hybrid-green' || props.marker.fsMarkIsUsedFlow === 'offline-direct-green' || props.marker.fsMarkIsUsedFlow === 'offline') {
    if (props.marker.fbIsEnableRedPinQuiz) {
      directMoveOnCouponScreen = false;
    }
  }

  if (props.marker.fsPinColor === "red-voucher") {
    directMoveOnCouponScreen = false;
  }

  if (props.marker.fsAdType === 'Bank Offer') {
    directMoveOnCouponScreen = false;
  }


  let finalOutput = <div className='image-row-container'>
    <div className="background-container">
      <img className="background-image" src={backgroundImage} alt="Background" />
      <div className="logo-container">
        <LazyLoadImage defaultImage={LazyPlaceholder} image={props.marker.fsAdType !== 'Bank Offer' ? config.getImageUrl(props.marker.fsAdLogo) : props.marker.fsAdLogo} className="img" />
      </div>

      <div className="content-overlay">
      <h3>
    {props.marker.fsAdName.length > 14 
      ? props.marker.fsAdName.slice(0, 14) + '..' 
      : props.marker.fsAdName}
  </h3>
        <p>{props.marker.fsProductName}</p>
      </div>

      <div className="ad-type-container engaged">
        {offer_type}
      </div>

      <div className="savings-info">
        <p>{top_text}</p>
        <h1 className="discount">{middel_text}</h1>
        <p>{bottom_text}</p>
      </div>
      <div className="centered-content">
        <button className="redeem-button">{botton_text}</button>
      </div>
    </div>
  </div>

  return (
    
    directMoveOnCouponScreen ?
      <span onClick={() => props.directMoveOnCouponScreen(props.marker.fiAdId)}>{finalOutput}</span>
      :
      <Link to={moreAdLink} className="">{finalOutput}</Link>
    
      
  );
}
