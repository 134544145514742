import React, { Component } from "react";
import config from './config';
import { motion } from "framer-motion";

import TopBarTitle from './TopBarTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';
import MessageList from './Message/MessageList';
import SingleMessage from './Message/SingleMessage';

import InternetNotAvailable from './InternetNotAvailable';

export default class Messages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      apiResponse: '',
      isSingleMessage: false,
      singleMessage:''
    }

    this.isMessageGoBack = this.isMessageGoBack.bind(this);
    this.isOpenMessage = this.isOpenMessage.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
      this.props.history.push({
          pathname: '/',
      });
  }

  isMessageGoBack() {
    this.setState({
      isSingleMessage: false
    },()=>{ this.props.history.goBack(); });
 
  }

  isOpenMessage(messageNo) {
    var singleMessage = this.state.apiResponse.foMessageList[messageNo];

    if(singleMessage.fdIsMessageSeen===false){
      var apiResponse = this.state.apiResponse;

      var requestParams = {
        "fsAction":"updateMessageAsRead",
        "fiMessageId":singleMessage.fiMessageId
      };
      config.fetchInformation(requestParams).then((responseJson)=>{
        apiResponse.foMessageList[messageNo].fdIsMessageSeen = true;
          this.setState({
            isSingleMessage: true,
            singleMessage:singleMessage,
            apiResponse:apiResponse
          });
      });
    }else{
      this.setState({
        isSingleMessage: true,
        singleMessage:singleMessage
      });
    }
  }

  componentWillMount() {
    /* Browser Back */
    window.addEventListener("popstate", e => {
      if(this.state.isSingleMessage){
        this.state.isSingleMessage = false;
        this.props.history.go(1);
      }
    });
    
    var requestParams = {"fsAction":"getMessages"};
    config.fetchInformation(requestParams).then((responseJson)=>{
      this.setState({
          apiResponse: responseJson,
          isLoading:false
        });
    });
  }

  render() {

    /* No Internet */
    if(!navigator.onLine){
      return(
        <InternetNotAvailable/>
      )
    }
    
    var displayMessages = <div className="apiCallLoader"><CircularProgress color="secondary" /></div>;
    if (this.state.isLoading === false) {
      if (this.state.apiResponse.fbIsError === false && this.state.apiResponse.foMessageList.length > 0) {
        if (this.state.isSingleMessage) {
          displayMessages = <SingleMessage singleMessage={this.state.singleMessage}/>;
        } else {
          displayMessages = <MessageList isOpenMessage={this.isOpenMessage} message={this.state.apiResponse.foMessageList} />;
        }
      } else {
        displayMessages = <Alert severity="error">Sorry, No messages found on your account.</Alert>
      }
    }

    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div className="AppDashboard">
          <div className="topAndSideBar">
            {this.state.isSingleMessage ?
              <TopBarTitle strTitle="Messages" goBack={this.isMessageGoBack} />
              :
              <TopBarTitle strTitle="Messages" goBack={this.goBack} />
            }
          </div>

          <div className="AppContent isNormalView isTopBarOnTop">
            {displayMessages}
          </div>
        </div>
      </motion.div>
    )
  }
}