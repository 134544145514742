import React, { Component } from "react";
import config from "./config";
import MapItemList from "./MapItemList";

import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import ContinuePopup from "./popup/ContinuePopup";
import MoreCashbackPopup from "./popup/MoreCashbackPopup";
import AdListFirstTimePopup from "./popup/AdListFirstTimePopup";
import InternetNotAvailable from "./InternetNotAvailable";
import SearchForm from "./SearchForm";
import Button from "@material-ui/core/Button";

import Snackbar from "@material-ui/core/Snackbar";
import InfiniteScroll from "react-infinite-scroll-component";

// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "@material-ui/core/Card";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import AdSense from "react-adsense";

import $ from "jquery";
import { AlertTitle } from "@material-ui/lab";

export default class AdGrid extends Component {
  constructor(props) {
    super(props);

    var selectedCategory = 0;
    if (
      localStorage.getItem("fiLoadCategory") !== null &&
      parseInt(localStorage.getItem("fiLoadCategory")) > 0
    ) {
      selectedCategory = parseInt(localStorage.getItem("fiLoadCategory"));
      localStorage.removeItem("fiLoadCategory");
    }

    var fiBannerId = 0;
    if (
      localStorage.getItem("fiBannerId") !== null &&
      parseInt(localStorage.getItem("fiBannerId")) > 0
    ) {
      fiBannerId = parseInt(localStorage.getItem("fiBannerId"));
      localStorage.removeItem("fiBannerId");

      // Enable Rotation if visited same banner
      // var visitedBanners = localStorage.getItem('visitedBanners');
      // if(visitedBanners!==null){
      //     if(!visitedBanners.split(',').includes(fiBannerId.toString())){
      //       visitedBanners = visitedBanners+','+fiBannerId;
      //       localStorage.setItem('visitedBanners',visitedBanners);
      //     }else{
      //       fiBannerId = 0;
      //     }
      // }else{
      //   localStorage.setItem('visitedBanners',fiBannerId);
      // }
    }

    /* Disable popup for FUTURPAY users */
    var isOpenFirstTiimePopup = localStorage.getItem("isFirstTimeAppOpen");
    var displayCreditImage = localStorage.getItem("displayCreditImage");
    var isVisibleDealOftheDay = true;
    if (isOpenFirstTiimePopup === "true") {
      isOpenFirstTiimePopup = true;
      // isVisibleDealOftheDay = true;
    }
    if (localStorage.getItem("isShownMiniProfile") === "true") {
      localStorage.setItem("isShownMiniProfile", false);
      isOpenFirstTiimePopup = false;
    }

    if (displayCreditImage === null) {
      localStorage.setItem("displayCreditImage", "true");
    }

    if (localStorage.getItem("displayCreditImage") === "true") {
      displayCreditImage = true;
    }

    if (localStorage.getItem("isFirstTimeAppOpen") === "true") {
      config.updateUserSession("current_session_time");
    }

    var firstTimeAlertImageNo = 0;
    if (localStorage.getItem("firstTimeAlertImageNo") != null) {
      firstTimeAlertImageNo = parseInt(
        localStorage.getItem("firstTimeAlertImageNo")
      );
    }

    var foGlobalSetting = localStorage.getItem("foGlobalSetting");
    if (foGlobalSetting === null) {
      foGlobalSetting = {};
    } else {
      foGlobalSetting = JSON.parse(foGlobalSetting);
      if (foGlobalSetting.fbIsUserExist === false) {
        this.logoutToUser();
      }
    }

    this.state = {
      apiResponse: [],
      isLoading: isOpenFirstTiimePopup ? false : true,
      selectedMarker: false,
      isLocationEnabled: true,

      isContinuePopup: isOpenFirstTiimePopup,
      displayCreditImage: displayCreditImage,
      firstTimeAlertImageNo: firstTimeAlertImageNo,

      lat: "",
      mapZoom: config.mapZoom,
      long: "",

      getAdsTimeInterval: 1000,
      currentCallTime: new Date().getTime(),
      isLocationSnackBar: false,
      isVisibleDealOftheDay: isVisibleDealOftheDay,

      selectedCategory: selectedCategory,
      fiBannerId: fiBannerId,
      searchValue: "",

      foGlobalSetting: foGlobalSetting,
      isDisplayFormOnLoader: false,
      searchFormToggle: false,

      isErrorPopup: "",
      currentPage: 1,
      hasMore: false,
      /* 1:Online, 2:Offline, 0:All */
      isOnlineOfflineAllFilter:
        this.getPageViewTypeBasedOnCategory(selectedCategory),

      isOpenMyCashPopup: false,
      cashbackType: "cashback",
    };

    this.closeContinuePopup = this.closeContinuePopup.bind(this);
    this.closeContinuePopupSetAdId = this.closeContinuePopupSetAdId.bind(this);
    this.getAdsLists = this.getAdsLists.bind(this);
    this.logoutToUser = this.logoutToUser.bind(this);
    this.getAdsBasedOnConfig = this.getAdsBasedOnConfig.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.goDetailsScreenWithData = this.goDetailsScreenWithData.bind(this);
    this.enableYourLocation = this.enableYourLocation.bind(this);
    this.openLink = this.openLink.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleSearchAndCategory = this.handleSearchAndCategory.bind(this);
    this.searchFormToggle = this.searchFormToggle.bind(this);
    this.goToTimeline = this.goToTimeline.bind(this);
    this.popupRedirection = this.popupRedirection.bind(this);
    this.updateOnlineOffLineStatus = this.updateOnlineOffLineStatus.bind(this);
    this.moveDealOfTheDayPage = this.moveDealOfTheDayPage.bind(this);
    this.directMoveOnCouponScreen = this.directMoveOnCouponScreen.bind(this);
    this.openMyCashPopup = this.openMyCashPopup.bind(this);
  }

  openMyCashPopup(type) {
    if (type === "cashback") {
      localStorage.setItem("fbIsHideCashbackHelp", "true");
    }
    this.setState({ isOpenMyCashPopup: true, cashbackType: type });
  }
  directMoveOnCouponScreen(adId) {
    this.setState({ isLoading: true });
    // console.log('adId',adId);
    var requestParams = {
      fsAction: "directSubmitQuiz",
      fiAdId: adId,
      device_unique_id: 3,
    };
    config.fetchInformation(requestParams).then((responseJson) => {
      if (responseJson.fbIsError === true) {
        this.setState({ isLoading: false });
      } else {
        var goToCouponLink = "/my-timeline/" + responseJson.fsLastActivityId;
        localStorage.setItem(
          "fiHighlightActivity",
          responseJson.fsLastActivityId
        );
        this.props.history.push({
          pathname: goToCouponLink,
        });
      }
    });
  }

  updateOnlineOffLineStatus(value) {
    this.setState(
      {
        isOnlineOfflineAllFilter: value,
        currentPage: 1,
        isLoading: true,
        isDisplayFormOnLoader: true,
        selectedCategory: 0,
      },
      () => {
        this.getAdsBasedOnConfig();
      }
    );
  }

  searchFormToggle() {
    // this.props.history.push({ pathname: '/search', state: { screen:'search' }});
    localStorage.setItem("isSearchBlink", "false");
    var setStatus = true;
    if (this.state.searchFormToggle) {
      setStatus = false;
      if (this.state.searchValue !== "") {
        this.handleSearchAndCategory(
          "",
          this.state.selectedCategory,
          this.state.isOnlineOfflineAllFilter
        );
      }
    }
    this.setState({ searchFormToggle: setStatus });
  }

  getPageViewTypeBasedOnCategory(Category) {
    var faOfflineCat = [66, 64, 67];
    return faOfflineCat.includes(Category) ? /* Offline */ 2 : /* Online */ 1;
  }

  handleSearchAndCategory(searchVal, Category, isOnlineOfflineAllFilter) {
    // var isOnlineOfflineAllFilter = this.getPageViewTypeBasedOnCategory(Category);
    this.setState(
      {
        selectedCategory: Category,
        searchValue: searchVal,
        currentPage: 1,
        isLoading: true,
        // 'isVisibleDealOftheDay':false,
        isDisplayFormOnLoader: true,
        isOnlineOfflineAllFilter: isOnlineOfflineAllFilter,
      },
      () => {
        this.getAdsBasedOnConfig();
      }
    );
  }

  closeSnackBar() {
    this.setState({
      isLocationSnackBar: false,
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  popupRedirection(CategoryId) {
    localStorage.setItem("isFirstTimeAppOpen", false);

    // Force redirect on exclusive offer is user is thankyou page
    let fsReferFrom = localStorage.getItem("fsReferFrom");
    if (fsReferFrom !== undefined && fsReferFrom !== null) {
      CategoryId = 70;
    }

    if (CategoryId === -1) {
      this.props.history.push({
        pathname: "/map",
      });
    } else {
      // localStorage.setItem('fiLoadCategory',CategoryId);
      this.setState(
        { isContinuePopup: false, selectedCategory: CategoryId },
        () => {
          this.getAdsBasedOnConfig();
          setTimeout(function () {
            var scroller = $(document).find(".searchCategoryScroller");
            var fi = window.innerWidth / 2 - 25;
            var leftOffset =
              $(document)
                .find("#category_" + CategoryId)
                .position()?.left - fi;
            scroller.animate({ scrollLeft: leftOffset }, 100);
          }, 6000);
        }
      );
    }
  }

  goToTimeline() {
    this.closeContinuePopup();
    this.props.history.push({
      pathname: "/my-timeline",
    });
  }

  goDetailsScreenWithData(adDetail) {
    console.log(adDetail);
    localStorage.setItem("isAdDetailBackToMap", true);
    this.props.history.push({
      pathname:
        "/start-quiz/" +
        adDetail.ad_id +
        "/" +
        adDetail.location[0].location_id,
      state: { marketingAd: adDetail },
    });
  }

  logoutToUser() {
    localStorage.removeItem("isUserLoggedIn");
    localStorage.removeItem("isProfileUpdated");
    localStorage.removeItem("AppLabels");
    localStorage.removeItem("userPhone");
    localStorage.removeItem("hasTermCheck");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("isShowMarketingAd");
    localStorage.removeItem("isOpenFirstProfile");
    localStorage.removeItem("firstTimeAlertImageNo");
    localStorage.removeItem("isFirstTimeAppOpen");
    window.location.reload();
  }

  closeContinuePopup() {
    localStorage.setItem("isFirstTimeAppOpen", false);
    this.setState({ isContinuePopup: false });
  }

  moveDealOfTheDayPage(dealDeatils) {
    var updateState = { isLoading: true };
    // updateState.isVisibleDealOftheDay = false;
    localStorage.setItem("fiBannerAdId", dealDeatils.fiAdId);
    updateState.fiBannerId = dealDeatils.fiBannerId;
    updateState.selectedCategory =
      dealDeatils.fiCategory > 0 ? dealDeatils.fiCategory : 0;
    console.log(updateState);
    this.setState(updateState, () => {
      $(document)
        .find("#category_" + updateState.selectedCategory)
        .trigger("click");

      setTimeout(function () {
        var scroller = $(document).find(".searchCategoryScroller");
        var fi = window.innerWidth / 2 - 25;
        var leftOffset =
          $(document)
            .find("#category_" + updateState.selectedCategory)
            .position()?.left - fi;
        scroller.animate({ scrollLeft: leftOffset }, 100);
      }, 6000);

      setTimeout(function () {
        localStorage.removeItem("fiBannerAdId");
      }, 8000);
    });
  }

  closeContinuePopupSetAdId(imageNo) {
    localStorage.setItem("isFirstTimeAppOpen", false);
    var updateState = { isContinuePopup: false, isLoading: true };
    var alertImages = this.state.foGlobalSetting.foAdvertisementList;
    if (alertImages[imageNo].fiCategory > 0) {
      localStorage.setItem("fiBannerAdId", alertImages[imageNo].fiAdId);
      updateState.fiBannerId = alertImages[imageNo].fiBannerId;
      updateState.selectedCategory = alertImages[imageNo].fiCategory;
      updateState.isOnlineOfflineAllFilter =
        this.getPageViewTypeBasedOnCategory(updateState.selectedCategory);
    } else {
      updateState.selectedCategory = 0;
    }

    if (
      localStorage.getItem("fiDirectLoadCategory") !== null &&
      parseInt(localStorage.getItem("fiDirectLoadCategory")) > 0
    ) {
      updateState.selectedCategory = parseInt(
        localStorage.getItem("fiDirectLoadCategory")
      );
      localStorage.removeItem("fiDirectLoadCategory");
    }

    if (imageNo === 0) {
      updateState.selectedCategory = 0;
    }

    this.setState(updateState, () => {
      this.getAdsBasedOnConfig();
      setTimeout(function () {
        var scroller = $(document).find(".searchCategoryScroller");
        var fi = window.innerWidth / 2 - 25;
        var leftOffset =
          $(document)
            .find("#category_" + alertImages[imageNo].fiCategory)
            .position()?.left - fi;
        scroller.animate({ scrollLeft: leftOffset }, 100);
      }, 5000);

      setTimeout(function () {
        localStorage.removeItem("fiBannerAdId");
      }, 8000);
    });
  }

  getAdsBasedOnConfig(newLatLong = null) {
    // console.log('Re Enable Location');
    if (config.isProd === false || this.state.isOnlineOfflineAllFilter == 1) {
      // /*  Offfice Location */
      var parseLat = 21.2346964;
      var parseLong = 72.8619283;
      this.setState({ lat: parseLat, long: parseLong });
      localStorage.setItem("userLat", parseLat);
      localStorage.setItem("userLong", parseLong);
      this.getAdsLists(parseLat, parseLong, false);
    } else {
      /* Native Enable Location */
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            var parseLat = position.coords.latitude;
            var parseLong = position.coords.longitude;
            var updateLatLong = {
              lat: parseLat,
              long: parseLong,
              isLocationEnabled: true,
            };
            localStorage.setItem("userLat", parseLat);
            localStorage.setItem("userLong", parseLong);

            if (newLatLong !== null) {
              parseLat = newLatLong.lat;
              parseLong = newLatLong.lng;
            }
            this.setState(updateLatLong);
            this.getAdsLists(parseLat, parseLong, false);
          },
          (error) => {
            this.setState({
              isLocationEnabled: false,
              isLoading: false,
              apiResponse: [],
              lat: "",
              long: "",
            });
          }
        );
      }

      // console.log('triggerGPS');
      //  try {
      //    if(localStorage.getItem('fiDeviceType')==='0'){
      //      window.Android.triggerGPS();
      //    }else if(localStorage.getItem('fiDeviceType')==='1'){
      //      window.webkit.messageHandlers.callbackHandler.postMessage("triggerGPS");
      //    }
      //  } catch (e) {
      //    console.log(e);
      //  }
    }
  }

  enableYourLocation() {
    this.setState(
      {
        apiResponse: [],
        isLoading: true,
        isLocationSnackBar: true,
      },
      () => {
        this.getAdsBasedOnConfig();
      }
    );
  }

  getAdsLists(parseLat, parseLong, isInfiniteScroll) {
    /* Ad Api */
    var foDeviceDetails = "";
    if (localStorage.getItem("foDeviceDetails") !== "") {
      foDeviceDetails = localStorage.getItem("foDeviceDetails");
    }

    var isMarketing = "false";
    // if(localStorage.getItem('isShowMarketingAd')==='true'){
    //   isMarketing = 'true';
    //   localStorage.setItem('isShowMarketingAd','false')
    // }

    var currentPage = this.state.currentPage;
    if (isMarketing === "false" && isInfiniteScroll) {
      if (isInfiniteScroll) {
        currentPage++;
      } else {
        currentPage = 1;
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: true });
    }

    var requestParams = {
      fsAction: "getOfferList",
      fsDeviceId: localStorage.getItem("userPhone"),
      ffUserLat: parseLat,
      ffUserLng: parseLong,
      isMarketingAds: isMarketing,
      fiPageViewType: this.state.isOnlineOfflineAllFilter,
      fbIsMapView: false,
      foFilterParams: {
        fiBannerId: this.state.fiBannerId,
        fsSearchString: this.state.searchValue.trim(),
        fiCurrentPage: currentPage,
        fiCategoryId: this.state.selectedCategory,
        fiHighlighOfferId:
          parseInt(localStorage.getItem("fiBannerAdId")) > 0
            ? localStorage.getItem("fiBannerAdId")
            : 0,
      },
    };

    config.fetchInformation(requestParams).then((responseJson) => {
      var responseText = JSON.stringify(responseJson);
      if (isMarketing === "true") {
        if (JSON.parse(responseText).fbIsError === false) {
          this.goDetailsScreenWithData(JSON.parse(responseText).foOfferList[0]);
        } else {
          this.getAdsLists(parseLat, parseLong, false);
          return false;
        }
      }

      var isErrorPopup = "";
      if (
        JSON.parse(responseText).fbIsError === true &&
        JSON.parse(responseText).fbIsLogout === true
      ) {
        isErrorPopup = JSON.parse(responseText).fsMessage;
      }

      var responseJson = JSON.parse(responseText);
      var appendJson = this.state.apiResponse;
      /* Load more */
      var hasMore = false;
      if (responseJson.foOfferList.length > 0) {
        hasMore = true;
      }

      if (isInfiniteScroll) {
        if (appendJson !== "" && appendJson.foOfferList.length > 0) {
          if (responseJson.foOfferList.length > 0) {
            appendJson.foOfferList = appendJson.foOfferList.concat(
              responseJson.foOfferList
            );
          }
        } else {
          appendJson = responseJson;
        }
      } else {
        appendJson = responseJson;
      }

      this.setState({
        apiResponse: appendJson,
        isLoading: false,
        isErrorPopup: isErrorPopup,
        currentPage: currentPage,
        hasMore: hasMore,
        getAdsTimeInterval: parseInt(
          JSON.parse(responseText).getAdsTimeInterval
        ),
        mapZoom: parseInt(JSON.parse(responseText).fIPWAZoom),
      });
    });
  }

  componentDidMount() {
    /* Get User Details If Not Exist */
    if (this.state.isContinuePopup === true) {
    } else {
      this.getAdsBasedOnConfig();
    }

    // this.props.history.index=0

    /* Disable Ad List Screen Back Event */
    // console.log(window.location.href);
    // if(window.location.href==='/'){
    //   window.history.replaceState(null, null, "/");
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener('popstate', function (event)
    //   {
    //     window.history.pushState(null, document.title, window.location.href);
    //   });
    // }
    /* Disable Ad List Screen Back Event */

    /* Call Again if Native Permission Provide */
    // document.updateGPSCall = () => {
    //   console.log('Location Permission Given');
    //   this.getAdsBasedOnConfig();
    // }
  }
  handleClick = (marker) => {
    // console.log({ marker })
    this.setState({ selectedMarker: marker });
  };

  openLink(link) {
    this.props.history.push({
      pathname: link,
    });
  }

  render() {
    var NoDataFound = config.getProviderSetting().fsNoData;

    /* No Internet */
    if (!navigator.onLine) {
      return <InternetNotAvailable isWithoutTopBar={true} />;
    }

    var varFirstTimePopupHtml = "";
    var loaderHtml = "";
    if (this.state.isLoading) {
      loaderHtml = (
        <div className="apiCallLoader fullScreenLoader">
          <CircularProgress color="secondary" />
        </div>
      );
    }

    if (this.state.isContinuePopup === true) {
      /*  First Time Alert */
      if (this.state.foGlobalSetting.foAdvertisementList !== undefined) {
        var alertImages = this.state.foGlobalSetting.foAdvertisementList;
        var firstTimeAlertImageNo = this.state.firstTimeAlertImageNo;

        /* Set New Index on storage */
        if (firstTimeAlertImageNo >= alertImages.length - 1) {
          var setFirstTimeAlertImageNo = 0;
        } else {
          var setFirstTimeAlertImageNo = parseInt(firstTimeAlertImageNo) + 1;
        }

        if (firstTimeAlertImageNo > alertImages.length - 1) {
          firstTimeAlertImageNo = 0;
        }

        var isAdCountUpdate = true;
        if (this.state.fiBannerId > 0) {
          alertImages.forEach((item, index) => {
            if (parseInt(item.fiBannerId) === this.state.fiBannerId) {
              firstTimeAlertImageNo = index;
              localStorage.setItem("fiBannerAdId", item.fiAdId);
              isAdCountUpdate = false;
            }
          });
        }

        if (isAdCountUpdate) {
          localStorage.setItem(
            "firstTimeAlertImageNo",
            setFirstTimeAlertImageNo
          );
        }
        if (localStorage.getItem("displayCreditImage") === "true") {
          firstTimeAlertImageNo = 0;
        }
        varFirstTimePopupHtml = (
          <AdListFirstTimePopup
            popupButtonText="Find More"
            popupTitle="CASHBACK "
            popupContent={this.state.foGlobalSetting.fsFirstTimeAlertMsg}
            displayCreditImage={this.state.displayCreditImage}
            fsCreditImage={this.state.foGlobalSetting.fsCreditImage}
            secondLineText=""
            // foGlobalSetting.foAdvertisementList[firstTimeAlertImageNo].fsImage
            fsAdImage={
              this.state.foGlobalSetting.foAdvertisementList.length > 0
                ? this.state.foGlobalSetting.foAdvertisementList
                : ""
            }
            handleClose={(i) => this.closeContinuePopupSetAdId(i)}
            goToTimeline={this.goToTimeline}
            openDialog={true}
            popupRedirection={this.popupRedirection}
          />
        );
        return varFirstTimePopupHtml;
      }
    } else {
      if (this.state.foGlobalSetting.foAdvertisementList !== undefined) {
        var alertImages = this.state.foGlobalSetting.foAdvertisementList;
        if (this.state.fiBannerId > 0) {
          alertImages.forEach((item, index) => {
            if (parseInt(item.fiBannerId) === this.state.fiBannerId) {
              localStorage.setItem("fiBannerAdId", item.fiAdId);
            }
          });
        }
      }

      /* Handle Ads View */
      var mapListToggle = "mapListToggle isPositionStatic";
      var searchFormToggleFixed = "searchFormToggleFixed";
      var searchToggleHandler = "searchToggleHandler";
      if (localStorage.getItem("isSearchBlink") !== "false") {
        searchFormToggleFixed += " blink_me";
      }
      if (this.state.searchFormToggle) {
        searchFormToggleFixed += " close_icon";
      }

      var searchForm = (
        <div className="searchToggleHandler">
          <SearchForm
            searchCategories={this.state.foGlobalSetting.foCategoryList}
            handleSearchAndCategory={this.handleSearchAndCategory}
            updateOnlineOffLineStatus={this.updateOnlineOffLineStatus}
            currentAdType={this.state.isOnlineOfflineAllFilter}
            searchValue={this.state.searchValue}
            selectedCategory={this.state.selectedCategory}
            searchFormToggle={this.state.searchFormToggle}
          />
          <div
            className={searchFormToggleFixed}
            onClick={this.searchFormToggle}
          >
            <span className="material-icons-outlined">
              {this.state.searchFormToggle ? "close" : "search"}
            </span>
          </div>
        </div>
      );

      var displayError = "";
      /*Need display loader or error*/
      if (this.state.lat === "" && this.state.isLocationEnabled === false) {
        displayError = (
          <div style={{ padding: "15px" }}>
            <div className="NoDataFound" style={{ marginTop: "60px" }}>
              <img src={NoDataFound} alt="No Data Found" />
              <div>Disable Location!</div>
              <div>
                These are “In store” Coupons for brand stores Near you. Please
                allow location to view.
              </div>
              <div className="enableYourLocation">
                {this.state.isLoading ? (
                  <div className="apiCallLoader">
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Button
                    onClick={this.enableYourLocation}
                    variant="contained"
                    color="primary"
                  >
                    <span class="material-icons-outlined">
                      location_searching
                    </span>{" "}
                    Enable Location
                  </Button>
                )}
              </div>
            </div>
            {this.state.isLocationSnackBar &&
            this.state.isLocationEnabled === false ? (
              <Snackbar
                open={true}
                autoHideDuration={6000}
                onClose={this.closeSnackBar}
              >
                <Alert onClose={this.closeSnackBar} severity="error">
                  Please enable location setting manually.
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        if (
          this.state.isLoading === false &&
          this.state.isContinuePopup === false
        ) {
          if (this.state.apiResponse.fbIsError === false) {
            //console.log('Ads',this.state.apiResponse.message);
            //console.log('Location',this.state.markerLocations);
          } else {
            var reason = this.state.apiResponse.fsMessage;

            var LogOutPopup = "";
            if (this.state.isErrorPopup !== "") {
              LogOutPopup = (
                <ContinuePopup
                  popupButtonText="Logout"
                  popupTitle="Mobile Deleted"
                  popupContent={this.state.isErrorPopup}
                  secondLineText=""
                  handleClose={this.logoutToUser}
                  openDialog={true}
                />
              );
            }
            displayError = (
              <div style={{ padding: "15px" }}>
                <div className="NoDataFound" style={{ marginTop: "15px" }}>
                  {LogOutPopup}
                  <img src={NoDataFound} alt="No Data Found" />
                  <div>Oops!</div>
                  <div>{reason}</div>
                </div>
              </div>
            );
          }
        }
      }

      var paddingTop = 85;
      if (this.state.searchFormToggle) {
        paddingTop = 168;
      }
      if (config.isReferreFromExist()) {
        paddingTop = paddingTop + 37;
      }

      paddingTop = paddingTop + "px";

      SwiperCore.use([Autoplay]);

      var dealOfTheDayHtml = "";
      if (this.state.isVisibleDealOftheDay) {
        if (
          this.state.foGlobalSetting.foDealOfTheDays !== undefined &&
          this.state.foGlobalSetting.foDealOfTheDays.length > 0
        ) {
          dealOfTheDayHtml = (
            <div className="dealOfTheDays">
              {/* <img onClick={()=>this.moveDealOfTheDayPage(this.state.foGlobalSetting.foDealOfTheDays[0])} src={this.state.foGlobalSetting.foDealOfTheDays[0].fsImage}/> */}
              <div
                className="close-deal-of-the-day"
                onClick={() => this.setState({ isVisibleDealOftheDay: false })}
              >
                <span className="material-icons-outlined">highlight_off</span>
              </div>
              <Swiper
                spaceBetween={50}
                autoHeight={true}
                slidesPerView={1}
                navigation
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: false }}
                scrollbar={{ draggable: true }}
                dots={false}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                {/* <SwiperSlide key={0}>
                    <div className="slideItems">
                        <AdSense.Google
                            client={config.adsense.clientId}
                            slot={config.adsense.slotId}
                            style={{ display: 'block' }}
                            format="auto"
                            responsive="true"
                        />
                    </div>
                  </SwiperSlide> */}
                {this.state.foGlobalSetting.foDealOfTheDays.map((single, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="slideItems">
                        <img
                          onClick={() => this.moveDealOfTheDayPage(single)}
                          src={single.fsImage}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          );
        }
      }

      if (displayError !== "") {
        return (
          <div className="isNormalView" style={{ paddingTop: paddingTop }}>
            {this.state.isDisplayFormOnLoader ? (
              <div
                className="searchFormSpaceHandler"
                style={{ padding: "0px 15px" }}
              >
                {searchForm}
              </div>
            ) : (
              ""
            )}
            {displayError}
          </div>
        );
      } else {
        var fbIsExpiration =
          parseInt(this.state.foGlobalSetting.fiExpirationId) > 0 ? (
            <div
              onClick={() => {
                this.props.history.push({
                  pathname: "wallet",
                });
              }}
              class="searchFormToggleFixed my-cash-expiration zoom-in-zoom-out"
            >
              <img src={config.getProviderSetting().fsMyCashExpiringImage} />
            </div>
          ) : (
            ""
          );

        return (
          <div className="map2partsDevider">
            <div
              className="isLeftRightSpace"
              style={{ paddingTop: paddingTop }}
            >
              {this.state.isOpenMyCashPopup ? (
                <MoreCashbackPopup
                  handleClose={() => {
                    this.setState({ isOpenMyCashPopup: false });
                  }}
                  popupButtonText="Got It"
                  cashbackType={this.state.cashbackType}
                  openDialog={true}
                />
              ) : (
                ""
              )}
              {fbIsExpiration}
              {searchForm}
              {loaderHtml}
              {dealOfTheDayHtml}
              {this.state.apiResponse.foOfferList !== undefined &&
              this.state.apiResponse.foOfferList.length > 0 ? (
                <div className="adsListContainer">
                  <InfiniteScroll
                    dataLength={this.state.apiResponse.foOfferList.length - 15}
                    next={() =>
                      this.getAdsLists(this.state.lat, this.state.long, true)
                    }
                    hasMore={this.state.hasMore}
                    loader={
                      <div
                        className="apiCallLoader"
                        style={{ marginBottom: "10px" }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                    }
                  >
                    {this.state.apiResponse.foOfferList.map((marker, i) => {
                      var cardClass = "redCard";
                      if (marker.fsPinColor === "green") {
                        cardClass = "greenCard";
                      } else if (marker.fsPinColor === "yellow") {
                        cardClass = "yellowCard";
                      } else if (marker.ad_type === "ENGAGED") {
                        cardClass = "grayCard";
                      } else {
                        cardClass = "redCard";
                      }
                      // console.log(marker);

                      return (
                        <MapItemList
                          directMoveOnCouponScreen={
                            this.directMoveOnCouponScreen
                          }
                          openMyCashPopup={this.openMyCashPopup}
                          key={i}
                          marker={marker}
                          cardClass={cardClass}
                          fiBannerId={this.state.fiBannerId}
                        ></MapItemList>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }
    }

    return loaderHtml;
  }
}
