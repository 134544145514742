import React, { Component } from "react";
import config from './config';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import { motion } from "framer-motion";

import InternetNotAvailable from './InternetNotAvailable';
import MarkAsReadPopupRed from './popup/MarkAsReadPopupRed';
import ContinuePopup from './popup/ContinuePopup';

import DeviceConnectionPopup from './popup/DeviceConnectionPopup';

import Card from '@material-ui/core/Card';
import QrReader from "react-qr-reader";
import Alert from '@material-ui/lab/Alert';

export default class AdsMarkAsReadRedOffer extends Component {
    constructor(props) {
        super(props)

        var currentTimeline = "";
        var isBlinkShopOnline = true;
        var fiActivityId = 0;
        fiActivityId = this.props.match.params.fiActivityId;
        localStorage.setItem('fiHighlightActivity',fiActivityId);
        this.state = {
            fiActivityId : fiActivityId,
            currentTimeline: currentTimeline,
            fsPassoword: '',
            fbShopOnlineLinkLoader:false,
            isDisableMarkAsRead:isBlinkShopOnline,
            isLoading : true,
            isBlinkButton : isBlinkShopOnline,
            isOpenContinuePopup:false,
            currentOffer : 1000,
            isOpenPopup:false,
            isDisplayLocations:false,
            popupContent:"",
            scanError : "",
            isOpenScanner: false,
            fbIsDevicePopupEnable : false
        }

        this.goBack = this.goBack.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        
        this.updateMarkAsRead = this.updateMarkAsRead.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.blinkDisable = this.blinkDisable.bind(this);
        this.copyOfferToClipBoard = this.copyOfferToClipBoard.bind(this);
        this.withOutApiGoTimelineWithBillPopup = this.withOutApiGoTimelineWithBillPopup.bind(this);
        this.closeDevicePopup = this.closeDevicePopup.bind(this);
        this.openMarkAsUsedTypeDialog = this.openMarkAsUsedTypeDialog.bind(this);

        this.scanResultFound = this.scanResultFound.bind(this);
        this.scanErrorFound = this.scanErrorFound.bind(this);
    }

    /* Found Scan result if scanner scan data */
    scanResultFound = data => {
        console.log('Scan Result Data',data);
        if(data===this.state.currentTimeline.fsQrCodePasswordText){
            this.setState({
                scanError : "",
                isOpenPopup:true
            })
        }else{
            /* If data null then may be user camera not proper for scan other wise some data will be recieved */
            this.setState({
                scanError: data==null?"Try to scan with center of camera screen":"The QR code is invalid.",
                isOpenScanner:false
            });    
        }
    }

    scanErrorFound() {
        this.setState({
          scanError: "You have issue on camera permission.",
          isOpenScanner:false
        });
    }

    openMarkAsUsedTypeDialog(){
        if(this.state.currentTimeline.fiMarkIsUsedType===2 || this.state.currentTimeline.fiMarkIsUsedType===3){
            this.setState({ isOpenScanner : true })
        }else{
            this.setState({ isOpenPopup : true })
        }
    }

    closeDevicePopup(mutipleDeviceOpenStatus){
        if(mutipleDeviceOpenStatus===1){
            this.goBack();
        }else if(mutipleDeviceOpenStatus===2){
            this.setState({'fbIsDevicePopupEnable':false,'isOpenContinuePopup':true})
        }else{
            this.setState({'fbIsDevicePopupEnable':false})
        }
    }

    componentDidMount() {
        var requestParams = {
            'fsAction':'getActivityDetails',
            'fiActivityId':this.state.fiActivityId
            };
            config.fetchInformation(requestParams).then((responseJson)=>{
            this.setState({
                isLoading: false,
                isBlinkButton:responseJson.foActivity.fbIsBlinkShopOnline,
                currentTimeline:responseJson.foActivity
            });
        });
    }

    withOutApiGoTimelineWithBillPopup(){
        this.props.history.push({
            pathname: '/my-timeline',
            state:{ currentTimeline:this.state.currentTimeline,openBillUploadPopup:true }
        });
    }

    copyOfferToClipBoard(key){
        this.setState({
            isOpenContinuePopup:true,
            currentOffer:key
        });

        config.updateUserSession('offer_online',this.state.currentTimeline.fiAdId);
    }

    blinkDisable(isShopOnlineSession=true){
        
        var requestParams = {
            "fsAction":"updateShopOnlineBlink",
            "fiActivityId" : this.state.currentTimeline.fiActivityId
        };
        config.fetchInformation(requestParams).then((responseJson)=>{
            console.log('Blonk Updated',responseJson);
        });
        
        var isDisableMarkAsRead = true;
        var enableReadPinPopup = false;
        // if(this.state.currentTimeline.fsPinColor==='green'){
        //     isDisableMarkAsRead = false;
        //     var enableReadPinPopup = true;
        // }
        this.setState({ isDisableMarkAsRead:isDisableMarkAsRead,'isBlinkButton':false,'isDisplayLocations':true,isOpenContinuePopup:enableReadPinPopup,currentOffer:1000 });
        if(isShopOnlineSession){
            config.updateUserSession('shop_online',this.state.currentTimeline.fiAdId);
        }
    }

    handleClose(){
        this.setState({ 'isOpenPopup':false })
    }

    openPopup(){
        this.setState({ 'isOpenPopup':true })
    }

    updateMarkAsRead(billAmount){
        var requestParams = {
            "fsAction":"couponMarkAsUsed",
            "fiActivityId" : this.state.currentTimeline.fiActivityId,
            "fiAmount":billAmount
        };
        config.fetchInformation(requestParams).then((responseJson1)=>{
            config.updateUserSession('bill_uploaded');
            this.goBack();
            this.setState({
                isOpenPopup:false,
                isLoading:false
            });
        });
       
    }

    handlePasswordChange(e) {
        const value = e.target.value;
        if(value===this.state.currentTimeline.fsGiftCardUsedPassword){
            this.setState({isDisableMarkAsRead:false});
        }else{
            this.setState({isDisableMarkAsRead:true});   
        }
    }

    goBack() {
        this.props.history.push({
            pathname: '/my-timeline'
        });
    }

    openLinkOnBrowser(fsCouponLink){
        var fsProvider = config.getProviderReferral();
        if(fsProvider==='M91APP'){
            try {
                window.JSBridgePlugin.openInBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }else if(fsProvider==='FATAKP'){
            try {
                window.ReactNativeWebView.postMessage(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }else{
            try {
                window.app.openLinkOnBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }

        if(config.getProviderSetting().fbIsPWAOpenOnWeb){
            window.open(fsCouponLink,'_blank');
        }
    }


    render() {

        /* No Internet */
        if(!navigator.onLine){
            return(
                <InternetNotAvailable/>
            )
        }

        if(this.state.isLoading){
            return(
                <div className="apiCallLoader fullScreenLoader">
                    <CircularProgress color="secondary" />
                </div>
            );
        }

        var onlineOfflineText = 'Offline';
        var shopOnlineText = 'Locate Nearby Stores';

        var offerListHtml = '';
        var locationsListHtml = '';
        // if(this.state.currentTimeline.fsPinColor==='green'){
        if(this.state.currentTimeline.foCouponList.length > 0){
            offerListHtml = <div className="offerListTabel">
                {this.state.currentTimeline.foCouponList.map((offerItem,key)=>{
                    var couponCode = "Copy Code";
                    if(offerItem.fsCouponName===""){
                        couponCode = 'Get Offer'
                    }
                    return (
                        <Card key={key}>
                            <div className="onlineOfferItem">
                                <div>{offerItem.fsCouponDescription}</div>
                                <div>
                                    {localStorage.getItem('fbIsMobileConnected')==='true'?
                                        <div className="offlineCouponLink" onClick={this.openMarkAsUsedTypeDialog}>{couponCode}</div>
                                    :
                                        <div className="offlineCouponLink" onClick={()=>this.setState({'fbIsDevicePopupEnable':true,currentOffer:key})}>{couponCode}</div>
                                    }   
                                </div>
                            </div>
                        </Card>
                    )
                })}
            </div>;
        }
        // console.log(this.state.currentTimeline.foLocationList);
        if(this.state.currentTimeline.foLocationList.length > 0){
            var displayStatus = 'none';
            if(this.state.isDisplayLocations){
                displayStatus = "block";
            }
            locationsListHtml = <div className="nearByLocations" style={{ 'display':displayStatus }}>
                {this.state.currentTimeline.foLocationList.map((singleLocation,key)=>{
                    var googleMapLink = 'https://maps.google.com/?q='+singleLocation.ffLatitude+','+singleLocation.ffLongitude;
                    return (
                        <div className="Coupon ShopOnline active" key={key}>
                            <a href={googleMapLink} onClick={(e)=>{e.preventDefault();this.openLinkOnBrowser(googleMapLink);}} rel="noopener noreferrer" target="_blank">NEARBY STORE {key+1}</a>
                        </div>
                    )
                })}
            </div>
        }

        var shopOnlineLink = config.getTextFirstLink(this.state.currentTimeline.fsCouponDescription);
        if(this.state.currentTimeline.fsShopOnlineLink!==""){
            shopOnlineLink = this.state.currentTimeline.fsShopOnlineLink;
        }
        
        // if(localStorage.getItem('fbIsMobileConnected')==='true' || this.state.currentTimeline.fsPinColor==='red'){
            var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" onClick={this.blinkDisable}>{shopOnlineText}</div>;
        // }else{
        //     var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" target="_blank" onClick={()=>this.setState({'fbIsDevicePopupEnable':true})}>{shopOnlineText}</div>;
        // }
        var shopClasses='Coupon ShopOnline box-shadow ';
        if(shopOnlineLink!==""){
            shopClasses = shopClasses + 'active';
            if(this.state.isBlinkButton){
                shopClasses = shopClasses + ' blink_me';
            }
        }

        var markAsReadButtonType = "View Coupon To Mark";
        if(this.state.currentTimeline.fiMarkIsUsedType===2 || this.state.currentTimeline.fiMarkIsUsedType===3){
            markAsReadButtonType = "Scan QR Code @ Cashier";
        }

        var CouponBg = config.getProviderSetting().fsCouponImg;
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >

                {this.state.fbIsDevicePopupEnable?<DeviceConnectionPopup popupContent={"To create your account for<br/>"+config.getCashbackString(this.state.currentTimeline.fbIsMyCash)+" Credit"} closeDevicePopup={this.closeDevicePopup}/>:''}
 
                {this.state.isOpenPopup?<MarkAsReadPopupRed currentTimeline={this.state.currentTimeline} updateMarkAsRead={this.updateMarkAsRead} handleClose={this.handleClose} popupContent={this.state.popupContent} openDialog={true} CashbackString={config.getCashbackString(this.state.currentTimeline.fbIsMyCash)}/>:''}

                <div className="AppDashboard markAsReadScreen">
                    <div className="screenPadding">
                        <div className="markAsReadBack">
                            <span style={{'fontSize':'24px','marginTop':'-2px'}} className="material-icons-outlined" onClick={this.goBack}>keyboard_backspace</span>
                        </div>
                        <div className="MainTitle">
                            <div className="onlineOfflineTitle">{onlineOfflineText} Store COUPON</div>
                            {/* <div>{this.state.currentTimeline.fsAdName}</div> */}
                            
                            {/* <div className="discount-and-cashback">
                                <div>Upto {this.state.currentTimeline.fsDiscountUpTo} Discount - At Store</div>
                                {this.state.currentTimeline.fsFlatCashBack!==""?<div>Exact {this.state.currentTimeline.fsFlatCashBack} Cashback - From Us</div>:''}
                            </div> */}
                        </div>

                        <div className="newOnlineCouponDesign">
                                <img src={CouponBg}/> 
                                <img className="brandLogo" src={config.getImageUrl(this.state.currentTimeline.fsAdLogo)}/> 
                                <div className="offer-name">{this.state.currentTimeline.fsAdName}</div>
                                <div className="extra-text">{this.state.currentTimeline.fsAdditionLabel}</div>
                                <div className="upto-dis text-design">
                                    <span>Upto</span>
                                    <span>{this.state.currentTimeline.fsDiscountUpTo}</span>
                                    <span>Discount</span>
                                </div>
                                {this.state.currentTimeline.fiAdCouponType===1?
                                    <div>
                                        <div className="upto-amazon text-design">
                                            <span>Upto</span>
                                            <span>{this.state.currentTimeline.fsFlatCashBackAmazon}</span>
                                            <span>Instant</span>
                                            <span>Discount</span>
                                        </div> 
                                        <div className="major-card">
                                            (On most Major Cards)
                                        </div>
                                    </div>
                                    : 
                                    <div>
                                        <div className="upto-other text-design">
                                            <span>Exact</span>
                                            <span>{this.state.currentTimeline.fsFlatCashBack}</span>
                                            <span>{config.getCashbackString(this.state.currentTimeline.fbIsMyCash)}</span>
                                        </div>
                                        { this.state.currentTimeline.fbIsMyCash==="no"?<div className="max-cashback-amount">Max {config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} Rs {this.state.currentTimeline.fiQuizReward}</div>:""}
                                    </div>
                                }
                            </div>
                        
                        {this.state.isOpenScanner?
                            <QrReader
                                delay={2500}
                                scanDelay={2500}
                                legacyMode={false}
                                onError={this.scanErrorFound}
                                onScan={this.scanResultFound}
                                style={{ width: '100%' }}
                            />
                        :
                            <div className="">
                                <Card>
                                    <div className="Description" dangerouslySetInnerHTML={{__html: this.state.currentTimeline.fsOfferDetails}}>
                                    </div>
                                </Card>

                                <div className={shopClasses} style={{'pointerEvents':'unset','opacity':'1'}}>
                                    {shopOnlineLinkHtml}
                                </div>

                                <div className="offerListMain">
                                    {locationsListHtml}
                                    <div className="Coupon ShopOnline active" onClick={this.openMarkAsUsedTypeDialog}><div>{markAsReadButtonType}</div></div>
                                    <div style={{'marginBottom':'10px'}}>(Check at Store Cashier )</div>
                                    {this.state.scanError!==""?<div style={{'marginBottom':'10px'}}><Alert severity="error">{this.state.scanError}</Alert></div>:""}
                                    {offerListHtml}
                                </div>
                                
                                {/* <div className="otherDetails">
                                    After Shopping, Find the Brand Coupon IN <br/>TIMELINE & Upload Bill Showing<br/>Amount/Date Your Moby Mobile.
                                </div> */}
                                {this.state.currentTimeline.fiAdCouponType===0?<div className="DontForgotUploadBill">
                                    {this.state.currentTimeline.fbIsMyCash==='no'?
                                        <>You will get {config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} in your<br/> {this.state.currentTimeline.fsWalletName} in {this.state.currentTimeline.fiVirtualCashTransferDays} Days.</>
                                    :<>You will get mycash in your wallet in {this.state.currentTimeline.fiVirtualCashTransferDays} days.</>
                                    }
                                </div>:''}
                                {this.state.currentTimeline.fsRemainDay!==""?<div className="Coupon validDays bgBlack colorRed">{this.state.currentTimeline.fsRemainDay}</div>:''}
                            </div>
                        }
                    </div>
                </div>
            </motion.div>
        )

    }
}