import React, { Component } from 'react';
import config from './config';

import { motion } from "framer-motion";
import TopBarDrawer from './TopBarDrawer';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CircularProgress from '@material-ui/core/CircularProgress';

class Help extends Component {
    
    constructor() {
        super();
    
        this.state = {
            isLoading: true,
            apiResponse: {}
        }
        this.goBack = this.goBack.bind(this);
    }
    
    goBack() {
        this.props.history.push({
            pathname: '/',
        });
    }

    componentWillMount() {
        var requestParams = { "fsAction":"loadWebView", "fsPage":"faqs" };
        config.fetchInformation(requestParams).then((responseJson)=>{
            this.setState({
                apiResponse: responseJson,
                isLoading:false
            });
        });
    }

    render() {

        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >
                <div className="AppDashboard">
                    <div className="topAndSideBar">
                        <TopBarDrawer strTitle="Help"/>
                    </div>

                    <div className="AppContent isNormalView" >
                        <div className="OfferSliderScreen">
                            <div>
                                {this.state.isLoading === true ?
                                    <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                                    :
                                    this.state.isLoading !== true && this.state.apiResponse.fbIsError === false?
                                    <div className="faq-accordination">
                                        {this.state.apiResponse.foFAQLists.map((item,index)=>{
                                            return(
                                                <Accordion key={index}>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    >
                                                    <Typography className={'help-heading'}>{item.fsTitle}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <Typography>
                                                        <div className="help-content" dangerouslySetInnerHTML={{__html: item.fsHtmlContent}}></div>
                                                    </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                    :''
                                }
                           </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Help;