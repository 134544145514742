import React, { Component } from 'react';
import config from './config';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

//https://swiperjs.com/react/
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class PWASetpsIos extends Component {

    render() {
        return (
            <div className="spashScreen">
                <div className="whiteBg">
                    <div className="AppLoginScreen">
                        
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{ clickable: false }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        >
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/ios/0.jpg?v=1'} title="0" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/ios/1.jpg'} title="1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/ios/2.jpg'} title="2" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/ios/3.jpg'} title="3" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slideItems">
                                    <img src={config.BASE_URL+'/assets/images/ios/4.jpg?v=1'} title="4" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        )
    }
}

export default PWASetpsIos;