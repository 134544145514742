import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export default function MarkAsReadPopup(props) {
    return (
        <Dialog
          open={props.openDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup continuePopupDesign">
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" 
                dangerouslySetInnerHTML={{__html: props.popupContent}}>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="uploadNowBtnsActions">
                <Button className="uploadNowBtn" onClick={props.updateMarkAsRead} variant="contained" color="primary">
                  Upload Now
                </Button>
                <Button className="uploadLaterBtn" onClick={props.handleClose} variant="contained" color="primary">
                  Upload Later
                </Button>
              </DialogActions>
          </div>
        </Dialog>
    );
}