import React, { Component } from "react";
import config from "./config";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import GoBackPopup from "./popup/GoBackPopup";
import AddEnaggedPopup from "./popup/AddEnaggedPopup";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { LazyLoadImage } from "@tjoskar/react-lazyload-img";
import InternetNotAvailable from "./InternetNotAvailable";
import Card from "@material-ui/core/Card";

export default class AddDetails extends Component {
  constructor(props) {
    super(props);
    var isQuizSubmitted = false;
    var quizResponse = "";
    if (this.props.location.state !== undefined) {
      // console.log(this.props.location.state);
      if ("marketingAd" in this.props.location.state) {
        localStorage.setItem("isAdDetailBackToMap", true);
      } else {
        isQuizSubmitted = true;
        quizResponse = this.props.location.state.response;
      }
    }
    // console.log('construct',this.props.location.state);
    this.state = {
      adId: this.props.match.params.adId,
      locationId: this.props.match.params.locationId,
      isLoading: true,
      apiResponse: "",
      isAbleToEngage: true,
      adDistance: 0,

      isQuizSubmitted: isQuizSubmitted,
      quizResponse: quizResponse,
      userDetails: JSON.parse(localStorage.getItem("userProfile")),
    };

    this.goBack = this.goBack.bind(this);
    this.popupGoBack = this.popupGoBack.bind(this);
    this.closeAdResponsePopup = this.closeAdResponsePopup.bind(this);
    this.openNotOnRadiusPopup = this.openNotOnRadiusPopup.bind(this);
  }

  closeAdResponsePopup() {
    this.setState({
      isQuizSubmitted: false,
    });
  }

  openNotOnRadiusPopup() {
    this.setState({
      isAbleToEngage: false,
    });
  }

  componentDidMount(props) {
    var requestParams = {
      fsAction: "getOfferDetails",
      fiAdId: this.state.adId,
      fiLocationId: this.state.locationId,
    };

    config.fetchInformation(requestParams).then((responseJson) => {
      var distance = 0;
      if (responseJson.foOfferDetails.flCoverageRadius !== "") {
        var userLat = localStorage.getItem("userLat");
        var userLong = localStorage.getItem("userLong");
        var adLat = responseJson.foOfferDetails.foLocationList[0].ffLatitude;
        var adLong = responseJson.foOfferDetails.foLocationList[0].ffLongitude;
        distance = config.getDistanceFromLatLonInKm(
          userLat,
          userLong,
          adLat,
          adLong
        );
      }

      this.setState({
        apiResponse: responseJson,
        isLoading: false,
        adDistance: distance,
      });
    });
  }

  goBack() {
    // this.props.history.goBack();
    this.props.history.push({
      pathname: "/",
    });
  }

  popupGoBack() {
    this.props.history.push({
      pathname: "/",
    });
  }

  render() {
    /* No Internet */
    if (!navigator.onLine) {
      return <InternetNotAvailable />;
    }

    var startQuizLink =
      "/start-quiz/" + this.state.adId + "/" + this.state.locationId;
    var isLoaderOrError = "";
    if (this.state.isLoading === false) {
      if (this.state.apiResponse.foOfferDetails.fbIsEngageLimitOver === true) {
        isLoaderOrError = (
          <Alert severity="error">Sorry, No data found on timeline.</Alert>
        );
      }
    } else {
      isLoaderOrError = (
        <div className="apiCallLoader">
          <CircularProgress color="secondary" />
        </div>
      );
    }

    if (isLoaderOrError !== "") {
      return (
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={config.pageAnimation}
          transition={config.pageTransition}
        >
          <div className="AppDashboard screenAdDetails">
            <div className="adDetailsImage" style={{ height: "50px" }}>
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
            <div className="loaderOrError">{isLoaderOrError}</div>
          </div>
        </motion.div>
      );
    }

    var apiResponse = this.state.apiResponse.foOfferDetails;

    var screenClass = "adDetailsImage colorGray";
    var buttonClass = "playQuiz quizDetails mt-10 colorGray";
    if (apiResponse.ad_type !== "ENGAGED") {
      screenClass = "adDetailsImage colorRed";
      buttonClass = "playQuiz quizDetails mt-10 colorRed";
    }

    var isOpenPopupHtml = "";
    if (this.state.isQuizSubmitted === true) {
      var quizResponseDetails = this.state.quizResponse;
      var quizMessage = quizResponseDetails;
      var popupMessageContent = quizMessage.fsDisplayAdsMessage;

      // New Designed Message for Virtual Cash
      if (quizMessage.fsDisplayAdsMessage) {
        popupMessageContent = quizMessage.fsDisplayAdsMessage.fsMessage;
        console.log(popupMessageContent);
        isOpenPopupHtml = (
          <AddEnaggedPopup
            quizResponse={quizMessage}
            isDisplaySecondButton={
              quizMessage.fsGiftCardCoupon !== "" ? true : false
            }
            addedActivityId={quizMessage.fiAddedActivityId}
            popupButtonText="Win Coupons"
            popupTitle={quizMessage.fsDisplayAdsMessage.fsTitle}
            popupContent={popupMessageContent}
            handleClose={this.popupGoBack}
            openDialog={true}
          />
        );
      } else {
        if (quizResponseDetails.isAdCancel) {
          if (quizResponseDetails.isAdCancel) {
            isOpenPopupHtml = (
              <AddEnaggedPopup
                quizResponse={quizMessage}
                isDisplaySecondButton={
                  quizMessage.fsGiftCardCoupon !== "" ? true : false
                }
                addedActivityId={quizMessage.fiAddedActivityId}
                popupButtonText="Win Coupons"
                popupTitle="Congratulations!"
                popupContent={popupMessageContent}
                handleClose={this.popupGoBack}
                openDialog={true}
              />
            );
          } else {
            isOpenPopupHtml = (
              <AddEnaggedPopup
                quizResponse={quizMessage}
                isDisplaySecondButton={
                  quizMessage.fsGiftCardCoupon !== "" ? true : false
                }
                addedActivityId={quizMessage.fiAddedActivityId}
                popupButtonText="Win Coupons"
                popupTitle="Congratulations!"
                popupContent={popupMessageContent}
                handleClose={this.closeAdResponsePopup}
                openDialog={true}
              />
            );
          }
        } else {
          if (parseInt(quizMessage.fiCreditAmount) > 0) {
            var ngoOrAmountMessage = "";
            if (quizMessage.fsNgoMessage === "") {
              ngoOrAmountMessage =
                "<div><b>Rs. XXX</b></br></div> <b>Credited to your PAYTM A/C by Moby Money App.</b></br></br>No PAYTM? Register & See Your CASH Credit!</br></br>Did u spend ANYTHING to get this Real Cash? <b>No</b></br>Any Restrictions to spend this reward Cash?<b>No</b></br></br>All others Apps will make you spend FIRST to get Any Rewards, Not MOBY..!";
              ngoOrAmountMessage = ngoOrAmountMessage.replace(
                "XXX",
                quizMessage.fiCreditAmount
              );
            } else {
              ngoOrAmountMessage = quizMessage.fsNgoMessage;
            }
            isOpenPopupHtml = (
              <AddEnaggedPopup
                quizResponse={quizMessage}
                isDisplaySecondButton={
                  quizMessage.fsGiftCardCoupon !== "" ? true : false
                }
                addedActivityId={quizMessage.fiAddedActivityId}
                popupButtonText="Win Coupons"
                popupTitle="Congratulations!"
                popupContent={ngoOrAmountMessage}
                handleClose={this.popupGoBack}
                openDialog={true}
              />
            );
          } else {
            isOpenPopupHtml = (
              <AddEnaggedPopup
                quizResponse={quizMessage}
                isDisplaySecondButton={
                  quizMessage.fsGiftCardCoupon !== "" ? true : false
                }
                addedActivityId={quizMessage.fiAddedActivityId}
                popupButtonText="Win Coupons"
                popupTitle="Congratulations!"
                popupContent={popupMessageContent}
                handleClose={this.popupGoBack}
                openDialog={true}
              />
            );
          }
        }
      }
    }

    if (isOpenPopupHtml === "" && this.state.isAbleToEngage === false) {
      var popupMessage =
        "You need to be within " +
        apiResponse.flCoverageRadius +
        " Mtrs of the ads pin to answer the Quiz and Get INSTANT CASH, COUPONS & CASHBACK.";
      isOpenPopupHtml = (
        <GoBackPopup
          popupButtonText="Ok"
          popupTitle="Oops!"
          popupContent={popupMessage}
          goBack={this.popupGoBack}
          openDialog={true}
        />
      );
    }

    var addMtrToKm = parseFloat(apiResponse.flCoverageRadius) / 1000;
    var playQuizButton = (
      <Link to={startQuizLink}>
        <Button variant="contained" color="secondary">
          WIN COUPON{" "}
          <span
            className="material-icons-outlined"
            style={{ marginLeft: "10px", marginTop: "-3px" }}
          >
            trending_flat
          </span>
        </Button>
      </Link>
    );

    if (addMtrToKm <= parseFloat(this.state.adDistance)) {
      playQuizButton = (
        <Button
          variant="contained"
          onClick={this.openNotOnRadiusPopup}
          color="secondary"
        >
          WIN COUPON{" "}
          <span
            className="material-icons-outlined"
            style={{ marginLeft: "10px", marginTop: "-3px" }}
          >
            trending_flat
          </span>
        </Button>
      );
    }

    var bodyClass = "AppDashboard screenAdDetails";
    if (apiResponse.fsPinColor === "green") {
      bodyClass += " greenAdDetails";
    } else {
      bodyClass += " redAdDetails";
    }
    var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={config.pageAnimation}
        transition={config.pageTransition}
      >
        <div
          className={bodyClass}
          style={{ height: "calc(100vh - 53px)", overflow: "auto" }}
        >
          <div>
            <div className={screenClass}>
              <LazyLoadImage
                defaultImage={LazyPlaceholder}
                image={config.getImageUrl(apiResponse.fsAdBanner)}
              />
              <span className="material-icons-outlined" onClick={this.goBack}>
                keyboard_backspace
              </span>
            </div>
          </div>
          <div className="AppContent autoHeight isNormalView">
            <Card>
              <div className="adBrandDetails">
                <div>
                  {/* <img alt={apiResponse.ad_name} src={config.convertHttps(apiResponse.company_logo)} /> */}
                  <LazyLoadImage
                    defaultImage={LazyPlaceholder}
                    image={config.getImageUrl(apiResponse.fsAdLogo)}
                  />
                </div>
                <div className="info">
                  <h3>{apiResponse.fsAdName}</h3>
                  {apiResponse.fiAdCouponType === 0 ? (
                    <div>
                      <b>Rs.{apiResponse.fiQuizReward}</b> (Max Cashback)
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="online-offline-add-setting">
                <div className="desc1">{apiResponse.fsDescription1}</div>
                <div
                  className="desc2"
                  dangerouslySetInnerHTML={{
                    __html: apiResponse.fsDescription2,
                  }}
                ></div>
                <div
                  className="desc3"
                  dangerouslySetInnerHTML={{
                    __html: apiResponse.fsDescription3,
                  }}
                ></div>
                <div
                  className="desc4"
                  dangerouslySetInnerHTML={{
                    __html: apiResponse.fsDescription4,
                  }}
                ></div>
              </div>

              {/* {apiResponse.fsPerOffMessage!=""?
              <div className="mt-20 offerMessage">
                <div>{config.getDynamicLabel("QuestionAnswer","offer_messge").replace('XXX',apiResponse.fsPerOffMessage)}</div>
              </div>
              :''} */}
            </Card>
            <div style={{ height: "12px" }}></div>
          </div>

          {isOpenPopupHtml}

          <div className={buttonClass}>
            {apiResponse.fsAdType !== "ENGAGED" ? (
              playQuizButton
            ) : (
              <Button variant="contained" color="secondary">
                Already Engaged
              </Button>
            )}
          </div>
        </div>
      </motion.div>
    );
  }
}
