import React,{ Component } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import config from './../config';

export default class LoginRegisterForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoader:true,
            isUpdateButtonLoader :false,
            apiResponse:{}
        }

        this.onAddingItem = this.onAddingItem.bind(this);
        this.updateCards = this.updateCards.bind(this);
    }

    updateCards(){
        this.setState({'isUpdateButtonLoader':true});

        var passCards = "";
        var userCards = "";
        this.state.apiResponse.foUserCardsList.map((singleCard)=>{
            if(singleCard.fbIsChecked){
                if(passCards===""){
                    passCards = singleCard.fiCardId;
                    userCards = singleCard.fiUserCardId;
                }else{
                    passCards = passCards + "," + singleCard.fiCardId;
                    userCards = userCards + "," + singleCard.fiUserCardId;
                }
            }
            return(<></>)
        });

        var requestParams = { fsCards:passCards,fsUserCards:userCards };
        var formBody = config.convertParams(requestParams);
        var request = config.getRequestHeaders();
        fetch(config.API_URL + 'BULK_UPDATE_USER_CARDS&' + formBody, request)
        .then((response) => response.text())
        .then((responseText) => {
            var responseJson = JSON.parse(responseText);
            if(responseJson.error){
                this.setState({'isUpdateButtonLoader':false});
                alert(responseJson.message);
            }else{
                this.props.updateCards(true);
                this.setState({'isUpdateButtonLoader':false});
            }
        });
    }

    onAddingItem = (i) => (event) => {
        console.log(i);
        var apiResponse = this.state.apiResponse;
        if(apiResponse.foUserCardsList[i].fbIsChecked){
            apiResponse.foUserCardsList[i].fbIsChecked = false;
        }else{
            apiResponse.foUserCardsList[i].fbIsChecked = true;
        }
        this.setState({ 'apiResponse': apiResponse })
    }

    componentWillMount() {
        var requestParams = {};
        var formBody = config.convertParams(requestParams);
        var request = config.getRequestHeaders();
        fetch(config.API_URL + 'GET_ALL_CARD_WITH_STATUS&' + formBody, request)
        .then((response) => response.text())
        .then((responseText) => {
            this.setState({
                apiResponse: JSON.parse(responseText),
                isLoader:false
            });
        });
    }

    render() {
        var printHtml = <div className="apiCallLoader">
            <CircularProgress color="secondary" />
        </div>;
        if(!this.state.isLoader){
            printHtml = <ul>
                {this.state.apiResponse.foUserCardsList.map((singleArray,i) => {
                    var key = 'paymnent'+i;
                    return (
                        <li key={key}>
                            <FormControlLabel value={singleArray.ID}
                                control={
                                    <Checkbox
                                        name="hasCardSelected"
                                        color="primary"
                                        checked={singleArray.fbIsChecked?true:false}
                                        onChange={this.onAddingItem(i)}
                                    />
                                }
                                label={singleArray.fsTitle}
                            />
                        </li>
                    )
                })}
            </ul>;
        }
        return (
            <Dialog
            open={true}
            classes={{root:'cardSelectionFullWidth'}}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <div className="mobyPopup popupButtonTextBlue">
                <DialogTitle id="alert-dialog-slide-title" >NEARBY OFFLINE OFFERS <br/>&<br/>BANK CARD OFFERS</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">We have selected all Major Bank<br/>Cards, You can edit Now OR later<br/>in Profile Page for Banks & Range.
</DialogContentText>
                </DialogContent>
                <div className="userCardItemsListsPopup">
                    {printHtml}
                </div>
                <DialogActions>
                    <div className="submitButton profileSaveLoader">
                        <Button variant="contained" onClick={this.updateCards} color="primary">
                        {this.state.isUpdateButtonLoader === true ? <CircularProgress color="secondary" />:'Check Offers'}
                        </Button>
                    </div>
                </DialogActions>
            </div>
            </Dialog>
        )
    }
}
