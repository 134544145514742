import React, { Component } from 'react';
import { 
	BrowserRouter as Router, 
    Route, 
	Switch 
} from 'react-router-dom'; 

import SplashSlider from './SplashSlider';
import Dashboard from './Dashboard';
import MobileLogin from './MobileLogin';
import MyTimeLine from './MyTimeLine';
import Messages from './Messages';
import Wallet from './Wallet';
import UpdateProfile from './UpdateProfile';
import AddDetails from './AddDetails';
import VoucherDetails from './VoucherDetails';
import BuyMyCashDetails from './BuyMyCashDetails';
import BankOfferDetails from './BankOfferDetails';
import StartQuiz from './StartQuiz';
import BillUpload from './BillUpload';
import Search from './Search';
import TermsAndCondition from './TermsAndCondition';
import AdsMarkAsRead from './AdsMarkAsRead';
import AdsMarkAsReadVoucher from './AdsMarkAsReadVoucher';
import AdsMarkAsReadInStore from './AdsMarkAsReadRedOffer';
import AdsMarkAsReadInstall from './AdsMarkAsReadInstall';
import AdsMarkAsReadHybridGreen from './AdsMarkAsReadHybridGreen';

import ProviderInfo from './ProviderInfo';
import CategoryInfo from './CategoryInfo';

import InstantCashHistory from './Wallet/_instant_cash';
import MyCashHistory from './Wallet/_my_cash';
import PendingCashHistory from './Wallet/_pending_cash';

import ProviderSpashScreen from './ProviderSpashScreen';
import Offers from './Offers';
import Help from './Help';
import Support from './Support';

import config from './config';

import {osVersion,osName,browserVersion,browserName,mobileVendor,mobileModel,getUA} from "react-device-detect";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import $ from "jquery";

import { AnimatePresence } from 'framer-motion';

const fpPromise = FingerprintJS.load();

class AppScreen extends Component{
    
    constructor(){
        super();
        
        localStorage.setItem('isFirstTimeAppOpen', true);
        localStorage.setItem('openMapViewPopup', true);
        
        var fbIsLoggedIn = false;
        var isUserStarted = false;
        var isUserLoggedIn = false;
        
        var fsProviderName = config.getProviderNameFromUrl(); 
        
        config.storeDeviceInfo(fpPromise).then((fsDeviceId)=>{
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            
            var foDeviceDetails = {
                "deviceDateTime":year+"/"+month+"/"+day+" "+hours+":"+minutes+":"+seconds,
                "manufacturer":mobileVendor+' '+mobileModel,
                "deviceId":MediaDeviceInfo.deviceId,
                "fsOs":osName+' '+osVersion,
                'fsBrowser':browserName+' '+browserVersion,
                "platformId":3,
                "deviceId":fsDeviceId,
                'userAgent':getUA
            };
            localStorage.setItem('foDeviceDetails',JSON.stringify(foDeviceDetails));

            if(fsProviderName!==""){
                localStorage.setItem('OpenPWAUserPromo',fsProviderName);
                this.verifyingProvider();
            }else{
                /* Override global setting if user exist with direct */
                // if(localStorage.getItem('userPhone')!==null ){
                //     this.storeGlobalSettingForNormalPWA();
                // }else{
                //     isUserStarted = true;
                // }
                this.storeGlobalSettingForNormalPWA();
            }
        });

        /* Ignore wait on inner page refresh */
        if(window.location.pathname.indexOf("ads-mark-as-read") > -1){
            console.log(window.location.pathname);
            isUserStarted = true;
            fbIsLoggedIn = "true";
            isUserLoggedIn = true;
        }

        this.state = {
            fbIsProvider : fsProviderName!==""?true:false,
            fbIsProviderWait : true,
            fsProviderName : fsProviderName,
            fbIsProviderMiniProfile:false,
            isUserLoggedIn : isUserLoggedIn,
            fbIsUserLoggedIn : fbIsLoggedIn==="true"?true:false,
            isUserStarted:isUserStarted
        }

        this.openDashboard = this.openDashboard.bind(this);
        this.storeDeviceType = this.storeDeviceType.bind(this);
        this.verifyingProvider = this.verifyingProvider.bind(this);
        this.storeGlobalSettingForNormalPWA = this.storeGlobalSettingForNormalPWA.bind(this);

        this.storeDeviceType();
    }

    storeDeviceType() {
        const ua = window.navigator.userAgent;
        const webkit = !!ua.match(/WebKit/i);
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i)
        const isIOS = isIPad || isIPhone;
        const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
        if(isIOS){
            localStorage.setItem('fiDeviceType',1); 
        }else{
            localStorage.setItem('fiDeviceType',0); 
        }
        // return {isIOS, isSafari};
    }

    componentDidMount() {

        /* Get Global Setting  */
        var urlInfo = config.getProviderDataFromUrl();

        // this.timeout = setTimeout(() => {
        //     this.setState({
        //         isUserStarted:true
        //     });
        // }, config.spashSliderMSecond);


        window.addEventListener('appinstalled', function(){
            localStorage.setItem('isPWAInstalled',true);
        });

        if(!localStorage.getItem('AppLabels')){
            config.fatchAppStaticLabels();
        }

        /* Toggle More icon */
        $(document).on('click','.adsListContainer .singleMapCard .displayFlex',function(){
            var $this = $(this);
            var singleCardObj = $this.closest('.singleMapCard');
            singleCardObj.find('.otherDetailsToggle').slideToggle('fast');
            if($this.find('.moreDetailsArrow span').html()==='keyboard_arrow_right'){
                $this.find('.moreDetailsArrow span').html('keyboard_arrow_down'); 
            }else{
                $this.find('.moreDetailsArrow span').html('keyboard_arrow_right');
            }
        });
        /* Toggle More icon */

    }
    
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    openDashboard(){
        localStorage.setItem('isUserLoggedIn', true);
        this.setState({
            'isUserLoggedIn':true
        });
    }

    storeGlobalSettingForNormalPWA(){
        var formBody = {fsAction:'getGlobalSetting'};
        config.fetchInformation(formBody).then((responseJson)=>{
            localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));

            config.existingUserConnected(responseJson);
            if(responseJson.fbIsUserExist===true){
                localStorage.setItem('isUserLoggedIn', true);
                this.setState({ isUserLoggedIn : true ,isUserStarted:true });
            }else{
                this.setState({ isUserLoggedIn : false ,isUserStarted:true });
            }
        });
    }

    verifyingProvider(){
        var urlInfo = config.getProviderDataFromUrl();
        
        /* Provider mobile no store on storage */
        
        if(urlInfo.fsMobile!=="" && urlInfo.fsMobile!==undefined){
            var formBody = { fsAction:'checkIsUserExist',fsUserContact:urlInfo.fsMobile };
            config.fetchInformation(formBody).then((checkExist)=>{
                
                if(checkExist.fsStrictMessage!=="" && checkExist.fsStrictMessage!==undefined){
                    alert(checkExist.fsStrictMessage);
                }else{
                    if(checkExist.fbIsUserExist){
                        var formBody = {fsAction:'getGlobalSetting',fsUserContact:urlInfo.fsMobile};
                        config.fetchInformation(formBody).then((responseJson)=>{
                            localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));

                            config.existingUserConnected(responseJson);

                            localStorage.setItem('isProfileUpdated', 'true');
                            localStorage.setItem('hasTermCheck', 'true');
                            localStorage.setItem('isProfileUpdated', true);
                            localStorage.setItem('fbIsMobileConnected','true');
                            localStorage.setItem('isShownMiniProfile',false);

                            localStorage.setItem('isUserLoggedIn', true);
                            this.setState({ isUserLoggedIn : true ,isUserStarted:true });
                        });
                    }else{
                        var foSetting = config.getProviderSetting();
                        if(foSetting.fbIsDirectRegistration === false){
                            var formBody = {fsAction:'getGlobalSetting'};
                            config.fetchInformation(formBody).then((responseJson)=>{
                                localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));
                                localStorage.setItem('userPhone',urlInfo.fsMobile);
                                localStorage.setItem('PhonePrefix',"");
                                this.setState({ isUserLoggedIn : false, 'fbIsProviderWait':false ,isUserStarted:true });
                            });
                        }else{    
                            var foDeviceDetails = '';
                            if(localStorage.getItem('foDeviceDetails')!==""){
                                foDeviceDetails = localStorage.getItem('foDeviceDetails');
                            }

                            var fsGender = localStorage.getItem('fiGender')==="1" || localStorage.getItem('fiGender')===1?'female':'male';
                            var fiAge = localStorage.getItem('fiAge')!==undefined && localStorage.getItem('fiAge')!==null?localStorage.getItem('fiAge'):30;
                            var fsEmail = localStorage.getItem('fsEmail')!==undefined?localStorage.getItem('fsEmail'):"";
                            var fsFirstName = localStorage.getItem('fsFirstName')!==undefined?localStorage.getItem('fsFirstName'):"";
                            var fsLastName = localStorage.getItem('fsLastName')!==undefined?localStorage.getItem('fsLastName'):"";

                            var requestParams = {
                                "fsAction":"saveMiniProfile",
                                "fsFirstName":fsFirstName==null?"":fsFirstName,
                                "fsLastName":fsLastName==null?"":fsLastName,
                                "fsEmail":fsEmail==null?"":fsEmail,
                                "fsReferrerCode": urlInfo.fsProviderName,
                                'fsUserContact': urlInfo.fsMobile,
                                "fiAge": fiAge,
                                "fsGender": fsGender,
                                "fsPaytmMobile":"",
                                'Device-Details':foDeviceDetails,
                                "fiEWalletId":config.getProviderSetting().fiUserWallet,
                                "fsUPILink":''
                            };
                            
                            config.fetchInformation(requestParams).then((savedProfile)=>{
                                var formBody = {fsAction:'getGlobalSetting',fsUserContact:urlInfo.fsMobile};
                                config.fetchInformation(formBody).then((responseJson)=>{
                                    localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));

                                    config.existingUserConnected(responseJson);

                                    localStorage.setItem('isProfileUpdated', 'true');
                                    localStorage.setItem('hasTermCheck', 'true');
                                    localStorage.setItem('isProfileUpdated', true);
                                    localStorage.setItem('fbIsMobileConnected','true');
                                    localStorage.setItem('isShownMiniProfile',true);

                                    localStorage.setItem('isUserLoggedIn', true);
                                    this.setState({ isUserLoggedIn : true ,isUserStarted:true });
                                });
                            });
                        }
                    }
                }
            
            });
        }else{
            var formBody = {fsAction:'getGlobalSetting'};
            config.fetchInformation(formBody).then((responseJson)=>{
                if( localStorage.getItem("isUserLoggedIn")===true || localStorage.getItem("isUserLoggedIn")==="true"){
                    this.openDashboard();
                }else{    
                    localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));
                    localStorage.setItem('userPhone',"");
                    localStorage.setItem('PhonePrefix',"");
                    this.setState({ isUserLoggedIn : false, 'fbIsProviderWait':false ,isUserStarted:true });   
                }
            });
        }

    }

    render(){
        var NoInternet = config.getProviderSetting().fsNoInternet;
        // console.log('this.state.isUserLoggedIn',this.state.isUserLoggedIn);
        /* Is Offline */
        if(this.state.isOnline===false){
            return (
                <div className="spashScreen">
                    <div className="whiteBg">
                        <div className="AppLoginScreen">
                            <div className="NoDataFound">
                                <img style={{'marginTop':'0'}} src={NoInternet} alt="No Data Found"/>
                                <div>Oops!</div>
                                <div>You're offline right now. Check your internet connection.</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(this.state.isUserLoggedIn===true){
            if(this.state.fbIsProvider===false && this.state.isUserStarted===false){
                return(<SplashSlider isStarted={this.isStarted}/>)
            }
            return(
                <Router> 
                    <AnimatePresence>
                        <Switch> 
                            <Route exact path='/' component={Dashboard}></Route>
                            <Route exact path='/map' component={Dashboard}></Route>
                            <Route exact path='/wallet' component={Wallet}></Route> 
                            <Route exact path='/search' component={Search}></Route> 
                            <Route exact path='/messages' component={Messages}></Route> 
                            <Route exact path='/update-profile' component={UpdateProfile}></Route> 
                            <Route exact path='/my-timeline/:activityId?' component={MyTimeLine}></Route>
                            <Route exact path='/terms-and-condition' component={TermsAndCondition}></Route>
                            <Route exact path='/bill-upload/:adId' component={BillUpload}></Route>
                            <Route exact path='/bank-offer-details/:adId/:locationId' component={BankOfferDetails}></Route> 
                            <Route exact path='/add-details/:adId/:locationId' component={AddDetails}></Route> 
                            <Route exact path='/voucher-details/:adId/:locationId' component={VoucherDetails}></Route>
                            <Route exact path='/buymycash-details/:adId/:locationId' component={BuyMyCashDetails}></Route>
                            <Route exact path='/start-quiz/:adId/:locationId' component={StartQuiz}></Route> 
                            <Route exact path='/publisher/:name/' component={ProviderInfo}></Route> 
                            <Route exact path='/category/:fiCategoryId/' component={CategoryInfo}></Route>
                            <Route exact path='/ads-mark-as-read/:fiActivityId' component={AdsMarkAsRead}></Route>
                            <Route exact path='/ads-mark-as-read-voucher/:fiActivityId' component={AdsMarkAsReadVoucher}></Route>
                            <Route exact path='/ads-mark-as-read-in-store/:fiActivityId' component={AdsMarkAsReadInStore}></Route>
                            <Route exact path='/ads-mark-as-read-hybrid/:fiActivityId' component={AdsMarkAsReadHybridGreen}></Route>
                            <Route exact path='/ads-mark-as-install/:fiActivityId' component={AdsMarkAsReadInstall}></Route>

                            <Route exact path='/offers' component={Offers}></Route>
                            <Route exact path='/help' component={Help}></Route>
                            <Route exact path='/support' component={Support}></Route>
                            
                            <Route exact path='/history-instant-cash' component={InstantCashHistory}></Route>
                            <Route exact path='/history-mycash' component={MyCashHistory}></Route>
                            <Route exact path='/history-pending-cash' component={PendingCashHistory}></Route>
                            
                        </Switch>
                    </AnimatePresence>
                </Router>
            )
        }else{
           if(this.state.fbIsProvider){
                if(this.state.fbIsProviderWait){
                    return(
                        <ProviderSpashScreen/>
                    ) 
                }else{
                    return(
                        <MobileLogin fbIsProvider={true} openDashboard={this.openDashboard}/>
                    )
                }
           }else{
                if(this.state.isUserStarted){
                    return(
                        <MobileLogin fbIsProvider={true} openDashboard={this.openDashboard}/>
                    )
                }else{
                    return(<ProviderSpashScreen isStarted={this.isStarted}/>)
                }
           }
        }
    }
}

export default AppScreen;