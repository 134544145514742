
import React from "react";
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function AddEnaggedPopup(props) {
    var goToCouponLink = '/my-timeline/';

    localStorage.setItem('fiHighlightActivity',props.addedActivityId);
    
    // if(props.isDisplaySecondButton){
    //   goToCouponLink = '/my-timeline/'+props.addedActivityId;
    // }

    var EnagePopupButtons = 'EnagePopupButtons';
    if(props.quizResponse.isMarketingAd===true){
      EnagePopupButtons = EnagePopupButtons + ' fullWithButton';
    }
    return (
        <Dialog
          open={props.openDialog}
          classes={{root:'adEnaggedFullHeight'}}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup continuePopupDesign adEnagedContinuePopup">
            <DialogTitle id="alert-dialog-slide-title">{props.popupTitle}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description" dangerouslySetInnerHTML={{__html: props.popupContent}}>
                  </DialogContentText>
                  {props.secondLineText!==""?
                  <DialogContentText id="alert-dialog-slide-description">
                    {props.secondLineText}
                  </DialogContentText>
                  :''}
              </DialogContent>
              <div className={EnagePopupButtons}>
                  {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                    <div className="buttonTwoLine">
                      <div>Show my Coupons</div>
                    </div>
                  </Button> */}
                    { props.quizResponse.isMarketingAd===true?'':
                    <Link to={goToCouponLink} className='backBlue'>
                      <Button onClick={props.handleClose} variant="contained" color="primary">
                        <div className="buttonTwoLine">
                          <div>Show my Coupons</div>
                        </div>
                      </Button>
                    </Link>}
              </div>
          </div>
        </Dialog>
    );
}