import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import config from "./../config";

import firebaseConfig from "./../FireBaseConfig";
import firebase from "firebase/app";
import "firebase/auth";

import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import "react-phone-input-2/lib/style.css";

export default class DeviceConnectionPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoader: false,
      phone: localStorage.getItem("userPhone") || "",
      otp: new Array(6).fill(''),
      isAddedMobile: false,
      fsError: "",
      showSendOtpLoader: false,
      showVerifyOtpLoader: "",
      isShowDeviceSelectionPopup: false,
      foDevicesList: [],
      fiSelectedDevice: 0,
      showProceedLoader: false,
    };

    this.verifyMobileAndSendOTP = this.verifyMobileAndSendOTP.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.changeDeviceSelection = this.changeDeviceSelection.bind(this);
    this.proceedWithDevice = this.proceedWithDevice.bind(this);
  }

  changeDeviceSelection = (event) => {
    this.setState({ fiSelectedDevice: parseInt(event.target.value) });
  };

  proceedWithDevice(mutipleDeviceOpenStatus) {
    this.setState({ showProceedLoader: true });
    var formBody = {
      fsAction: "proceedWithDevice",
      fsUserContact: this.state.phone,
      fiUserId: this.state.foDevicesList[this.state.fiSelectedDevice].fiUserId,
    };
    config.fetchInformation(formBody).then((responseJson) => {
      localStorage.setItem("fbIsMobileConnected", "true");
      this.props.closeDevicePopup(mutipleDeviceOpenStatus);
      // this.setState({'showProceedLoader':false},()=>{
      //     this.props.closeDevicePopup();
      // });
    });
  }

  verifyOTP() {
    console.log("this.state.otp", this.state.otp);
    this.setState({ showVerifyOtpLoader: true });

    var formBody = {
      fsAction: "sendOrVerifyOtp",
      fsEnteredOtp: this.state.otp.join(''),
      fbIsSentOtpCall: false,
    };
    config.fetchInformation(formBody).then((responseJson) => {
      if (responseJson.fbIsError === false) {
        var updateState = { showVerifyOtpLoader: false, fsError: "" };
        updateState.foDevicesList = responseJson.foDevicesList;
        if (responseJson.foDevicesList.length > 1) {
          updateState.isShowDeviceSelectionPopup = true;
          this.setState(updateState);
        } else {
          updateState.showVerifyOtpLoader = true;
          this.setState(updateState);
          this.proceedWithDevice(2);
        }
      } else {
        this.setState({
          showVerifyOtpLoader: false,
          showSendOtpLoader: false,
          fsError: "OTP Verification was failed.",
        });
      }
    });

    // this.state.confirmResult
    //   .confirm(this.state.otp)
    //   .then((result) => {
    //     // var user = result.user;
    //     window.verifyingCode = false;
    //     window.confirmationResult = null;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     window.verifyingCode = false;
    //     this.setState({
    //       showVerifyOtpLoader: false,
    //       showSendOtpLoader: false,
    //       fsError: "OTP Verification was failed.",
    //     });
    //   });
  }

  handleChange = (otp) => this.setState({ otp });

  verifyMobileAndSendOTP() {
    console.log(this.state.phone);
    if (this.state.phone === "" || this.state.phone.length < 10) {
      this.setState({
        fsError: "Phone no must be 10 to 12 characters.",
      });
    } else {
      this.setState({ showSendOtpLoader: true });
      var phoneNumber = this.state.phone;
      phoneNumber = config.mobilePrefix.trim() + phoneNumber;

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            //submitPhoneNumberAuth();
          },
        }
      );
      var requestParams = {
        fsAction: "sendOrVerifyOtp",
        fsEnteredOtp: null,
        fbIsSentOtpCall: true,
      };
      config.fetchInformation(requestParams).then((responseJson) => {
        if (responseJson.fbIsError === false) {
          this.setState({
            isMobileError: "",
            isAddedMobile: true,
            confirmResult: responseJson,
          });
          localStorage.setItem("userPhone", this.state.phone);
          localStorage.setItem("PhonePrefix", config.mobilePrefix);
        } else {
          this.setState({
            // isMobileError: error.message,
            showSendOtpLoader: false,
          });
        }
      });
      //   firebase
      //     .auth()
      //     .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      //     .then((confirmationResult) => {
      //       this.setState({
      //         fsError: "",
      //         isAddedMobile: true,
      //         confirmResult: confirmationResult,
      //       });
      //       localStorage.setItem("userPhone", this.state.phone);
      //       localStorage.setItem("PhonePrefix", config.mobilePrefix);
      //     })
      //     .catch((error) => {
      //       this.setState({
      //         isMobileError: error.message,
      //         showSendOtpLoader: false,
      //       });
      //     });
    }
  }

  componentDidMount() {
    if (!firebase.apps.length) {
      console.log("App created with ", firebaseConfig);
      firebase.initializeApp(firebaseConfig);
    }
  }

  render() {
    var mobileInput = (
      <PhoneInput
        country={"in"}
        autoFormat={false}
        countryCodeEditable={false}
        enableSearch={true}
        searchPlaceholder="Search Country"
        disabled={localStorage.getItem("userPhone") === null ? false : true}
        onlyCountries={["in", "us", "gb", "au", "ae"]}
        disableDropdown="true"
        // disableCountryCode="true"
        // disabled={true}
        placeholder="Phone Number"
        value={this.state.phone}
        onChange={(phone) => this.setState({ phone })}
      />
    );

    if (this.state.isShowDeviceSelectionPopup) {
      return (
        <Dialog
          open={true}
          classes={{ root: "popupMaxWidth" }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup popupButtonTextRed closeIconHandle">
            <div
              className="closePopup"
              onClick={() => this.props.closeDevicePopup(3)}
            >
              <span className="material-icons-outlined">close</span>
            </div>
            <DialogTitle id="alert-dialog-slide-title">
              Found Multiple Devices
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div class="text-left mt--10 mb--20">
                  Your Phone nomber is connected with multiple devices, so
                  please select any one from them and make sure other device
                  data will be removed permanently.
                </div>
              </DialogContentText>
            </DialogContent>
            <div className="mobileVerificationDetails">
              {console.log(this.state.fiSelectedDevice)}
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="deviceSelection"
                  name="deviceSelection1"
                  value={this.state.fiSelectedDevice}
                  onChange={this.changeDeviceSelection}
                >
                  {this.state.foDevicesList.map((singleDevice, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        value={i}
                        control={<Radio />}
                        label={singleDevice.fsDeviceName}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>
            <DialogActions>
              <div className="submitButton profileSaveLoader">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.proceedWithDevice(1)}
                >
                  {this.state.showProceedLoader ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Save & Continue"
                  )}
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      );
    }

    return (
      <Dialog
        open={true}
        classes={{ root: "popupMaxWidth" }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="mobyPopup popupButtonTextRed closeIconHandle">
          <div
            className="closePopup"
            onClick={() => this.props.closeDevicePopup(3)}
          >
            <span className="material-icons-outlined">close</span>
          </div>
          <DialogTitle id="alert-dialog-slide-title">Verify Phone</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              dangerouslySetInnerHTML={{ __html: this.props.popupContent }}
            ></DialogContentText>
          </DialogContent>
          <div className="mobileVerificationDetails">
            <div className="mobileInput">{mobileInput}</div>
            {this.state.isAddedMobile ? (
              <div className="OTPInput2">
                <div className="title">
                  <h2>OTP Verification</h2>
                </div>
                <div className="otpInputContainer">
                  <div className="otp-container">
                    <div className="otp-inputs">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="tel"
                        id={`sign_otp_${index}`}
                        name={`sign_otp_${index}`}
                        className="sign_otp_form_control"
                        maxLength="1"
                        autoComplete="one-time-code"
                        autoFocus={index === 0} // Autofocus only on the first input
                        value={this.state.otp[index] || ''}
                        onChange={(event) => {
                          const value = event.target.value;

                          // Allow only a single digit or empty string
                          if (/^\d?$/.test(value)) {
                            const newOtp = [...this.state.otp];
                            newOtp[index] = value;

                            // Move focus to the next input if a value is entered
                            if (value && index < 5) {
                              const nextInput = document.getElementById(`sign_otp_${index + 1}`);
                              nextInput.focus();
                            }

                            this.setState({ otp: newOtp });
                          }
                        }}
                        onKeyDown={(event) => {
                          // Handle backspace
                          if (event.key === 'Backspace' && !this.state.otp[index]) {
                            const prevIndex = index - 1;
                            if (prevIndex >= 0) {
                              const prevInput = document.getElementById(`sign_otp_${prevIndex}`);
                              prevInput.focus(); // Move focus to the previous input
                            }
                          }
                        }}
                        onPaste={(event) => {
                          event.preventDefault();
                          const pastedData = event.clipboardData.getData('text');

                          // Only proceed if the pasted data is numeric
                          if (/^\d+$/.test(pastedData)) {
                            const newOtp = [...this.state.otp];

                            // Spread the pasted characters across the inputs
                            pastedData.split('').forEach((char, pasteIndex) => {
                              if (index + pasteIndex < 6) {
                                newOtp[index + pasteIndex] = char;
                              }
                            });

                            this.setState({ otp: newOtp }, () => {
                              // Focus the last filled input
                              const lastFilledIndex = Math.min(index + pastedData.length - 1, 5);
                              const lastInput = document.getElementById(`sign_otp_${lastFilledIndex}`);
                              lastInput.focus();
                            });
                          }
                        }}
                      />
                    ))}

                    </div>
                    <div className="autofill-otp-suggestion">
                      (Copy & Paste, Autofill may not work)
                    </div>
                    {/* {this.state.fsError && <span className="mobileError">{this.state.fsError}</span>} */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.fsError !== "" ? (
              <span className="mobileError" style={{'marginBottom':'10px', 'textAlign':'center'}}>{this.state.fsError}</span>
            ) : (
              ""
            )}
          </div>
          <DialogActions>
            <div className="submitButton profileSaveLoader">
              <div id="recaptcha-container"></div>
              {this.state.isAddedMobile ? (
                <Button
                  onClick={this.verifyOTP}
                  variant="contained"
                  color="primary"
                >
                  {this.state.showVerifyOtpLoader ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Verify OTP"
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={this.verifyMobileAndSendOTP}
                  color="primary"
                >
                  {this.state.showSendOtpLoader ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              )}
            </div>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}
