import React, { Component } from "react";
import { compose } from "recompose";
import config from './config';
import MapItemList from './MapItemList';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import RedPin from './images/red.png';
import GreenPin from './images/green.png';
import YellowPin from './images/yellow.png';
import GrayPin from './images/gray.png';
import CurrentLocation from './images/current-location.png';

import ContinuePopup from './popup/ContinuePopup';
import InternetNotAvailable from './InternetNotAvailable';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import CardSelectionPopup from './popup/CardSelectionPopup';
import { LazyLoadImage } from '@tjoskar/react-lazyload-img';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps"

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
  var pinColor = RedPin;

  const defaultMapOptions = {
    fullscreenControl: false
  };

  var markerCounter = 1;

  return (
    <GoogleMap key={1} ref={props.googleMapRef}
      defaultClickableIcons={false}
      mapTypeControl={false}
      defaultZoom={config.mapZoom}
      defaultOptions={defaultMapOptions}
      defaultCenter={{ lat: props.lat, lng: props.long }}
      onDragEnd={props.getAdsBasedOnDrag}>
      {props.isShowMarker ? props.markers.map((marker, markerCounter) => {
        const onClick = props.onClick.bind(this)

        if (marker.fsPinColor === 'green') {
          pinColor = GreenPin;
        } else if (marker.fsPinColor === 'yellow' || marker.fsAdType === 'Bank Offer') {
          pinColor = YellowPin;
        } else if (marker.fsAdType === 'ENGAGED') {
          pinColor = GrayPin;
        } else {
          pinColor = RedPin;
        }

        let iconMarker = new window.google.maps.MarkerImage(
          pinColor,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(21, 30)
        );


        return (
          <>
            {marker.foLocationList.map((singleLocation, i) => {

              var infoMoreDetails = ''
              if (marker.fsAdType === 'Bank Offer') {
                infoMoreDetails = <div>
                  <h3>Bank Offer</h3>
                  <div>{singleLocation.fsLandmark}</div>
                  <span className="material-icons-outlined">
                    keyboard_arrow_right
                  </span>
                </div>
              } else if (marker.fsMarkIsUsedFlow === "offline-hybrid-green" || marker.fsMarkIsUsedFlow === "offline-direct-green") {
                infoMoreDetails = <div>
                  <div>{marker.fsDiscountUpTo} Discount</div>
                  {marker.fsFlatCashBack !== "" ? <div>{marker.fsFlatCashBack} Extra Off</div> : ''}
                  <span className="material-icons-outlined">
                    keyboard_arrow_right
                  </span>
                </div>
              } else if (marker.fsMarkIsUsedFlow === "voucher-red") {
                infoMoreDetails = <div>
                  <div>{marker.fsDiscountUpTo} Discount</div>
                  {marker.fsFlatCashBack !== "" ? <div>{marker.fsFlatCashBack} Instant Cashback</div> : ''}
                  <span className="material-icons-outlined">
                    keyboard_arrow_right
                  </span>
                </div>
              } else {
                infoMoreDetails = <div>
                  <div>{marker.fsDiscountUpTo} Discount</div>
                  {marker.fsFlatCashBack !== "" ? <div>{marker.fsFlatCashBack} {config.getCashbackString(marker.fbIsMyCash)}</div> : ''}
                  <span className="material-icons-outlined">
                    keyboard_arrow_right
                  </span>
                </div>
              }

              var moreLink = '/start-quiz/' + marker.fiAdId + '/' + singleLocation.fiLocationId;
              if (marker.fsAdType === 'Bank Offer') {
                moreLink = '/bank-offer-details/' + marker.fiAdId + '/' + singleLocation.fiLocationId;
              }
              if (marker.fsPinColor === 'red-voucher') {
                moreLink = '/voucher-details/' + marker.fiAdId + '/' + singleLocation.fiLocationId;
              }

              var myLocation = '';
              if (markerCounter === 0 && i === 0) {
                let currentLocationMarker = new window.google.maps.MarkerImage(
                  CurrentLocation,
                  null, /* size is determined at runtime */
                  null, /* origin is 0,0 */
                  null, /* anchor is bottom center of the scaled image */
                  new window.google.maps.Size(35, 35)
                );
                myLocation = <Marker
                  key={111111111}
                  icon={currentLocationMarker}
                  position={{ lat: parseFloat(localStorage.getItem('userLat')), lng: parseFloat(localStorage.getItem('userLong')) }}
                ></Marker>
              }

              if (marker.fsAdType === "ENGAGED") {
                marker.fsPinColor = 'gray';
              }
              var pinInfoWindowClass = 'mapPopupWindowItem border-' + marker.fsPinColor;

              // if(marker.fsAdType==='Bank Offer'){
              //   marker.fsAdName = 'Bank Offer';
              // }
              var LazyPlaceholder = config.getProviderSetting().fsLazyLoad;
              return (
                <>
                  {myLocation}
                  <Marker
                    key={i}
                    icon={iconMarker}
                    onClick={() => onClick(singleLocation.fiLocationId)}
                    position={{ lat: singleLocation.ffLatitude, lng: singleLocation.ffLongitude }}
                  >
                    {props.selectedMarker === singleLocation.fiLocationId &&
                      <InfoWindow onCloseClick={props.onToggleOpen}>
                        <div className={pinInfoWindowClass}>
                        <span class="material-icons-outlined close_icon" onClick={()=> onClick(null)}>close</span>
                          <a className="moreDetailsonMarkerClick" onClick={() => props.openLink(moreLink, marker)}>
                            <div className="top-details" style={{'minWidth':'12.5rem'}}>
                              <div>
                                {/* <img alt="Componey" src={pinColor}/>  */}
                                <LazyLoadImage defaultImage={LazyPlaceholder} image={marker.fsAdType === 'Bank Offer' ? marker.fsAdLogo : config.getImageUrl(marker.fsAdLogo)} />
                              </div>
                              <div className="infoMoreDetails" style={{'flex':'1 0 auto'}}>
                                {infoMoreDetails}
                              </div>
                            </div>
                            <div class="bottom-details">
                              <h3>{marker.fsAdName}</h3>
                            </div>
                          </a>
                        </div>
                      </InfoWindow>
                    }
                  </Marker>
                </>
              )

            })}
          </>
        )
      }) : ''}
    </GoogleMap>
  )
})

export default class MapList extends Component {
  constructor(props) {
    super(props)

    var isOpenCardSelection = localStorage.getItem('isOpenCardSelectionPopup');
    var isOpenCardPopup = false;
    if (isOpenCardSelection === 'true') {
      isOpenCardPopup = true;
    }

    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

    this.state = {
      apiResponse: [],
      isLoading: true,
      isDragLoader: false,
      selectedMarker: false,
      isLocationEnabled: true,
      isContinuePopup: localStorage.getItem('openMapViewPopup'),
      lat: "",
      mapZoom: foGlobalSetting.fIPWAZoom,
      long: "",
      tabValue: 0,

      getAdsTimeInterval: foGlobalSetting.fiQuizTimeInterval,
      currentCallTime: new Date().getTime(),
      isLocationSnackBar: false,

      isErrorPopup: '',

      isOpenCardPopup: isOpenCardPopup,
      isMapPopup: false
    }

    localStorage.setItem('openMapViewPopup', false)

    this.closeContinuePopup = this.closeContinuePopup.bind(this);
    this.onToggleOpen = this.onToggleOpen.bind(this);
    this.getAdsLists = this.getAdsLists.bind(this);
    this.logoutToUser = this.logoutToUser.bind(this);
    this.getAdsBasedOnConfig = this.getAdsBasedOnConfig.bind(this);
    this.getAdsBasedOnDrag = this.getAdsBasedOnDrag.bind(this);
    this.googleMapRef = this.googleMapRef.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.mapPanTo = this.mapPanTo.bind(this);
    this.goDetailsScreenWithData = this.goDetailsScreenWithData.bind(this);
    this.enableYourLocation = this.enableYourLocation.bind(this);
    this.openLink = this.openLink.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.updateCards = this.updateCards.bind(this);
  }

  updateCards(status) {
    if (status) {
      localStorage.setItem('isOpenCardSelectionPopup', 'false');
      this.setState({ 'isOpenCardPopup': false });
      this.getAdsBasedOnConfig();
    }
  }

  closeSnackBar() {
    this.setState({
      isLocationSnackBar: false
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
      * and you may want to customize it to your needs
      */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  goDetailsScreenWithData(adDetail) {
    console.log(adDetail);
    localStorage.setItem('isAdDetailBackToMap', true);
    this.props.history.push({
      pathname: '/start-quiz/' + adDetail.ad_id + '/' + adDetail.location[0].location_id,
      state: { marketingAd: adDetail }
    });
  }

  logoutToUser() {
    localStorage.removeItem('isUserLoggedIn');
    localStorage.removeItem('isProfileUpdated');
    localStorage.removeItem('AppLabels');
    localStorage.removeItem('userPhone');
    localStorage.removeItem('hasTermCheck');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('OpenPWAUserPromo');
    window.location.reload();
  }

  closeContinuePopup() {
    localStorage.setItem('isFirstTimeAppOpen', false);
    this.setState({ isContinuePopup: false })
  }

  onToggleOpen() {
    this.setState({ selectedMarker: false })
  }
  googleMapRef(map) {
    this._map = map;
    // console.log(this._map);
  }

  mapPanTo() {
    var panToLatLong = {
      lat: parseFloat(localStorage.getItem('userLat')),
      lng: parseFloat(localStorage.getItem('userLong'))
    };
    this._map.panTo(panToLatLong);
    // this._map.setZoom(config.mapZoom);
    var callTime = this.state.getAdsTimeInterval + this.state.currentCallTime;
    var currentTime = new Date().getTime();
    this.setState({ 'isDragLoader': true });
    this.getAdsBasedOnConfig(panToLatLong);
    this.setState({ currentCallTime: currentTime });
  }

  getAdsBasedOnDrag() {
    var callTime = this.state.getAdsTimeInterval + this.state.currentCallTime;
    var currentTime = new Date().getTime();
    if (currentTime > callTime) {
      var newLatLong = {
        lat: this._map.getCenter().lat(),
        lng: this._map.getCenter().lng()
      }
      this.setState({ 'isDragLoader': true });
      this.getAdsBasedOnConfig(newLatLong);
      this.setState({ currentCallTime: currentTime });
    }
  }

  getAdsBasedOnConfig(newLatLong = null) {
    // console.log('Re Enable Location');
    if (config.isProd === false) {
      // /*  Offfice Location */
      var parseLat = 21.2346964;
      var parseLong = 72.8619283;

      this.setState({ lat: parseLat, long: parseLong });
      localStorage.setItem('userLat', parseLat);
      localStorage.setItem('userLong', parseLong);
      this.getAdsLists(parseLat, parseLong);
    } else {

      /* Native Enable Location */
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            var parseLat = position.coords.latitude;
            var parseLong = position.coords.longitude;
            var updateLatLong = {
              lat: parseLat,
              long: parseLong,
              isLocationEnabled: true
            }
            localStorage.setItem('userLat', parseLat);
            localStorage.setItem('userLong', parseLong);

            if (newLatLong !== null) {
              parseLat = newLatLong.lat;
              parseLong = newLatLong.lng;
            }
            this.setState(updateLatLong);
            this.getAdsLists(parseLat, parseLong);
          }, (error) => {
            this.setState({
              isLocationEnabled: false,
              isLoading: false
            });
          });
      }

      // console.log('triggerGPS');
      //  try {
      //    if(localStorage.getItem('fiDeviceType')==='0'){
      //      window.Android.triggerGPS();
      //    }else if(localStorage.getItem('fiDeviceType')==='1'){
      //      window.webkit.messageHandlers.callbackHandler.postMessage("triggerGPS");
      //    }
      //  } catch (e) {
      //    console.log(e);
      //  }

    }
  }

  enableYourLocation() {
    this.setState({
      apiResponse: [],
      isLoading: true,
      isLocationSnackBar: true
    }, () => { this.getAdsBasedOnConfig() });
  }

  getAdsLists(parseLat, parseLong) {
    /* Ad Api */
    var foDeviceDetails = '';
    if (localStorage.getItem('foDeviceDetails') !== "") {
      foDeviceDetails = localStorage.getItem('foDeviceDetails');
    }

    var requestParams = {
      "fsAction": "getOfferList",
      "ffUserLat": parseLat,
      "ffUserLng": parseLong,
      "isMarketingAds": "false",
      "fiPageViewType": 2,
      "fbIsMapView":true,
      'Device-Details': foDeviceDetails
    }

    config.fetchInformation(requestParams).then((responseJson) => {
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
        isDragLoader: false
      });
    });
    /* Ad Api */
  }

  handleChange = (newValue) => {
    this.setState({ 'tabValue': newValue });
  };

  componentDidMount() {

    this.getAdsBasedOnConfig();

    /* Call Again if Native Permission Provide */
    // document.updateGPSCall = () => {
    //   console.log('Location Permission Given');
    //   this.getAdsBasedOnConfig();
    // }

  }
  handleClick = (marker) => {
    // console.log({ marker })
    this.setState({ selectedMarker: marker })
  }

  openLink(link, marker) {
    console.log(marker);
    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
    var isQuizEnable = false;
    if (marker.fsPinColor === "green" && (marker.fbIsQuizFlow || foGlobalSetting.fbIsQuizFlow)) {
      isQuizEnable = true;
    }
    if (marker.fsPinColor === "red" && marker.fbIsEnableRedPinQuiz) {
      isQuizEnable = true;
    }
    if (marker.fsAdType === 'Bank Offer') {
      isQuizEnable = true;
    }
    // if (marker.fsPinColor === 'red-voucher') {
    //   isQuizEnable = true;
    // }
    if (isQuizEnable) {
      this.props.history.push({
        pathname: link
      });
    } else {
      this.setState({ 'isDragLoader': true });
      // console.log('adId',adId);

      localStorage.setItem('fiBannerAdId', marker.fiAdId);
      localStorage.setItem('fiLoadCategory', marker.fiOfferCategory);
      this.props.history.push({
        pathname: '/',
      });
      // var requestParams = {
      //   "fsAction":"directSubmitQuiz",
      //   "fiAdId": marker.fiAdId,
      //   "device_unique_id":3,
      // };
      // config.fetchInformation(requestParams).then((responseJson)=>{
      //     if (responseJson.fbIsError === true) {
      //       this.setState({ 'isDragLoader':false });
      //     }else{
      //       localStorage.setItem('fiHighlightActivity',responseJson.fsLastActivityId);
      //       var goToCouponLink = '/my-timeline/';
      //       this.props.history.push({
      //         pathname: goToCouponLink,
      //       });
      //     }
      // });
    }
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable isWithoutTopBar={true} />
      )
    }

    var mapUrl = "https://maps.googleapis.com/maps/api/js?key=" + config.mapAPIKey + "&v=3.exp";
    if (config.isProd === false) {
      mapUrl = "https://maps.googleapis.com/maps/api/js?&v=3.exp";
    }

    var mapListToggle = "mapListToggle";
    if (this.state.tabValue === 0) {
      mapListToggle = mapListToggle + ' isPositionAbsolute';
    } else {
      mapListToggle = mapListToggle + ' isPositionStatic';
    }

    /* Add Condition for not found ads */

    /* Load Card Popup If User Comes First Time */
    if (this.state.isOpenCardPopup) {
      // return(
      //   <CardSelectionPopup updateCards={this.updateCards}/>
      // )
    }

    /*Need display loader or error*/
    var isDisplayErrorOrLoader = false;
    var mapLoaderOrError =
      <div className="preGoogleMap">
        <div className="mapCanvas-100vh">
          <MapWithAMarker key="1" mapZoom={this.state.mapZoom} googleMapRef={this.googleMapRef}
            selectedMarker={this.state.selectedMarker}
            markers={{}}
            isShowMarker={false}
            lat={this.state.lat}
            long={this.state.long}
            getAdsBasedOnDrag={this.getAdsBasedOnDrag}
            onClick={this.handleClick}
            onToggleOpen={this.onToggleOpen}
            googleMapURL={mapUrl}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <div className="apiCallLoader fullScreenLoader">
          <CircularProgress color="secondary" />
        </div>
      </div>;
    var dragLoader = <div className="apiCallLoader">
      <CircularProgress color="secondary" />
    </div>;
    var NoDataFound = config.getProviderSetting().fsNoData;
    if (this.state.lat === "" && this.state.isLocationEnabled === false) {
      mapLoaderOrError = <div style={{ padding: '15px' }}>
        <div className="NoDataFound">
          <img src={NoDataFound} alt="No Data Found" />
          <div>Disable Location!</div>
          <div>View Store Coupons & Bank Rewards in Real Time Near You ! Please Allow Location.</div>
          <div className="enableYourLocation">
            {this.state.isLoading ?
              <div className="apiCallLoader">
                <CircularProgress color="secondary" />
              </div>
              : <Button onClick={this.enableYourLocation} variant="contained" color="primary"><span class="material-icons-outlined">location_searching</span> Enable Location</Button>
            }
          </div>
        </div>
        {this.state.isLocationSnackBar && this.state.isLocationEnabled === false ?
          <Snackbar open={true} autoHideDuration={6000} onClose={this.closeSnackBar}>
            <Alert onClose={this.closeSnackBar} severity="error">
              Please enable location setting manually.
            </Alert>
          </Snackbar> : ''}
      </div>
      isDisplayErrorOrLoader = true;
    } else {
      if (this.state.isLoading === false) {
        if (this.state.apiResponse.fbIsError === false) {
          //console.log('Ads',this.state.apiResponse.message);
          //console.log('Location',this.state.markerLocations);
        } else {
          var reason = 'We could not find any coupons for you.';
          if (this.state.apiResponse.fsMessage !== "") {
            reason = this.state.apiResponse.fsMessage;
          }

          var LogOutPopup = "";
          if (this.state.isErrorPopup !== "") {
            LogOutPopup = <ContinuePopup popupButtonText="Logout" popupTitle="Mobile Deleted"
              popupContent={this.state.isErrorPopup}
              secondLineText=""
              handleClose={this.logoutToUser} openDialog={true} />
          }
          mapLoaderOrError = <div style={{ padding: '15px' }}>
            <div className="NoDataFound">
              {LogOutPopup}
              <img src={NoDataFound} alt="No Data Found" />
              <div>Oops!</div>
              <div>
                {reason}
              </div>
            </div>
          </div>;
          isDisplayErrorOrLoader = true;
        }
      } else {
        isDisplayErrorOrLoader = true;
      }
    }

    if (isDisplayErrorOrLoader) {
      return (
        <div className="isNormalView" style={{ 'paddingTop': '10px' }}>
          {mapLoaderOrError}
        </div>
      )
    }

    /* Popup */
    var isOpenPopupHtml = "";
    // if(this.state.isContinuePopup==="true"){
    //   isOpenPopupHtml = <ContinuePopup popupButtonText="Continue"
    //   popupContent={'Like to Shop Offline? Try Map View<br/>\
    //   (Need 1 Verified Online Purchase)<br/>\
    //   <div class="popupPinAlign"><img src="'+YellowPin+'"/>  Real Time Card Offers,</div>\
    //   <div class="popupPinAlign"><img src="'+RedPin+'"/>  Coupons for Stores Nearby</div>'}
    //   secondLineText=""
    //    handleClose={this.closeContinuePopup} openDialog={true}/>
    // }

    return (
      <div className="map2partsDevider">

        {isOpenPopupHtml}

        {this.state.tabValue === 0 ?
          <div className={this.state.isDragLoader ? 'mapCanvas-100vh showingLoader' : 'mapCanvas-100vh'}>
            {this.state.isDragLoader ? <div className="dragLoader">{dragLoader}</div> : ''}
            <div className="map-help-icon" onClick={() => { this.setState({ isMapPopup: true }) }}><span className="material-icons-outlined">help</span></div>
            {this.state.isMapPopup ?
              <ContinuePopup popupTitle="Nearby	Stores" popupButtonText="Continue To Shop"
                popupContent={'\
                <div class="popupPinAlign"><img src="'+ RedPin + '"/> Nearby Store Instant Cash/Voucher</div>\
                <div class="popupPinAlign"><img src="'+ GreenPin + '"/> Nearby Restaurant Discounts</div>\
                <div class="popupPinAlign"><img src="'+ YellowPin + '"/> Bank Credit/Debit Card Offers</div>'}
                secondLineText=""
                handleClose={() => { this.setState({ isMapPopup: false }) }} openDialog={true} /> : ''
            }
            {this.state.lat === "" ?
              <div style={{ 'fontWeight': 'bold', 'margin': '16px', 'textAlign': 'center' }}><Alert severity="error">You have need to enable location before view this screen.</Alert></div>
              :
              <MapWithAMarker key="1" mapZoom={this.state.mapZoom} googleMapRef={this.googleMapRef}
                isShowMarker={true}
                selectedMarker={this.state.selectedMarker}
                markers={this.state.apiResponse.foOfferList}
                lat={this.state.lat}
                long={this.state.long}
                getAdsBasedOnDrag={this.getAdsBasedOnDrag}
                openLink={this.openLink}
                onClick={this.handleClick}
                onToggleOpen={this.onToggleOpen}
                googleMapURL={mapUrl}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            }
            {this.state.lat1 !== "" ? <div onClick={this.mapPanTo} className="scrollMapToCurrentLocation">
              <img src={config.BASE_URL + '/assets/images/my-location.png'} title="1" />
            </div> : ''}
          </div>
          :
          <div className="isLeftRightSpace">
            <div className="adsListContainer">
              {this.state.apiResponse.foOfferList.map((marker, i) => {
                var cardClass = 'redCard';
                if (marker.fsPinColor === "green") {
                  cardClass = 'greenCard';
                } else if (marker.fsPinColor === "yellow") {
                  cardClass = 'yellowCard';
                } else if (marker.ad_type === "ENGAGED") {
                  cardClass = 'grayCard';
                } else {
                  cardClass = 'redCard';
                }
                // console.log(marker);
                return (
                  <MapItemList
                    key={i} marker={marker} cardClass={cardClass}>
                  </MapItemList>
                )
              })}
            </div>
          </div>
        }
      </div>
    )
  }
}