import React, { Component } from "react";
import config from './config';
import Button from '@material-ui/core/Button';
import PaymentMethod from "./PaymentMethod";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

import CircularProgress from '@material-ui/core/CircularProgress';

import { motion } from "framer-motion";

import InternetNotAvailable from './InternetNotAvailable';
import MarkAsReadPopup from './popup/MarkAsReadPopup';
import ContinuePopup from './popup/ContinuePopup';
import MoreCashbackPopup from './popup/MoreCashbackPopup';

import DeviceConnectionPopup from './popup/DeviceConnectionPopup';

import Card from '@material-ui/core/Card';


import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from "jquery";
import { Tune } from "@material-ui/icons";

// toast.configure()

export default class AdsMarkAsRead extends Component {
    constructor(props) {
        super(props)

        var currentTimeline = "";
        var isBlinkShopOnline = true;
        var fiActivityId = 0;
        fiActivityId = this.props.match.params.fiActivityId;
        localStorage.setItem('fiHighlightActivity', fiActivityId);
        this.state = {
            fiActivityId: fiActivityId,
            currentTimeline: currentTimeline,
            fsPassoword: '',
            fbShopOnlineLinkLoader: false,
            isDisableMarkAsRead: isBlinkShopOnline,
            isLoading: true,
            isBlinkButton: isBlinkShopOnline,
            isOpenContinuePopup: false,
            currentOffer: 1000,
            activeLocation: "",
            isOpenPopup: false,
            isDisplayLocations: false,
            popupContent: "",
            fbIsMyCashPopup: false,
            fbIsDevicePopupEnable: false,
            fbIsOpenPaymentMethod: false,
            showPaymentLoader: false,
            fiPayableAmt: 0,
            fiPriceMapId: 0,
            fiSalePrice: 0,
            openCodePurchasePopup: false,
            openRedirectPopup: false,
            fsPopupTitle: '',
            fsPopupBtnTxt: ''
        }

        this.goBack = this.goBack.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.updateMarkAsRead = this.updateMarkAsRead.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.blinkDisable = this.blinkDisable.bind(this);
        this.closePopupAndRedirectToLink = this.closePopupAndRedirectToLink.bind(this);
        this.closePopupAndRedirect = this.closePopupAndRedirect.bind(this);
        this.copyOfferToClipBoard = this.copyOfferToClipBoard.bind(this);
        this.withOutApiGoTimelineWithBillPopup = this.withOutApiGoTimelineWithBillPopup.bind(this);
        this.closeDevicePopup = this.closeDevicePopup.bind(this);
        this.scrollToRef = this.scrollToRef.bind(this);
        this.purchaseOfferCode = this.purchaseOfferCode.bind(this);
    }

    closePopupAndRedirect(goToLink = '') {
        if (goToLink === 'my-timeline') {
          this.props.history.push({
            pathname: '/' + goToLink,
          });
          return;
        }
    }

    scrollToRef = (ref) => { window.scrollTo(0, $('.' + ref).offset().top); this.setState({ activeLocation: ref }) }
    closeDevicePopup(mutipleDeviceOpenStatus) {
        if (mutipleDeviceOpenStatus === 1) {
            this.goBack();
        } else if (mutipleDeviceOpenStatus === 2) {
            this.setState({ 'fbIsDevicePopupEnable': false, 'isOpenContinuePopup': true })
        } else {
            this.setState({ 'fbIsDevicePopupEnable': false })
        }
    }

    componentDidMount() {
        var requestParams = {
            'fsAction': 'getActivityDetails',
            'fiActivityId': this.state.fiActivityId
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            this.setState({
                isLoading: false,
                isBlinkButton: responseJson.foActivity.fbIsBlinkShopOnline,
                currentTimeline: responseJson.foActivity
            });
        });

        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));

        let mid = foGlobalSetting.fsPaytmMID; // Merchant ID
        var url = foGlobalSetting.fbPaytmProd ? 'securegw.paytm.in' : 'securegw-stage.paytm.in';

        const script = document.createElement("script");
        script.async = false;
        script.src = 'https://' + url + "/merchantpgpui/checkoutjs/merchants/" + mid + '.js';
        document.body.appendChild(script);
    }

    withOutApiGoTimelineWithBillPopup() {
        this.props.history.push({
            pathname: '/my-timeline',
            state: { currentTimeline: this.state.currentTimeline, openBillUploadPopup: true }
        });
    }

    copyOfferToClipBoard(key) {
        config.updateUserSession('offer_online', this.state.currentTimeline.fiAdId);
        var couponDetails = this.state.currentTimeline.foCouponList[key];

        let fbIsUserPurchased = this.state.currentTimeline.foCouponList[key]?.fbIsUserPurchased;
        let fiSalePrice = this.state.currentTimeline.foCouponList[key]?.fiSalePrice;
        let fiPrice = this.state.currentTimeline.foCouponList[key]?.fiPrice;

        if (couponDetails.fsCouponCode != "") {
            this.copyToClipboard(couponDetails.fsCouponCode);
            toast.success('Code copied!', {
                position: "bottom-left"
            });
        }

        if (this.state.currentTimeline.fiAdCouponType === 1) {
            // window.open(couponDetails.fsCouponLink, '_blank');
            try {
                this.openLinkOnBrowser(couponDetails.fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            if (fbIsUserPurchased === true) {
                this.setState({
                    openCodePurchasePopup: true,
                    currentOffer: key,
                    fiSalePrice: fiSalePrice,
                    fiPayableAmt: fiPrice,
                    fsPopupTitle: 'Discount Code Copied!',
                    fsPopupBtnTxt: 'Shop At Store'
                });
            } else {
                this.setState({
                    isOpenContinuePopup: true,
                    currentOffer: key
                });
            }
        }
    }

    copyToClipboard(fsCouponCode) {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.copyToClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.setClipboard(fsCouponCode);
            } catch (error) {
                console.log(error);
            }
        }
        navigator.clipboard.writeText(fsCouponCode);
    }

    openLinkOnBrowser(fsCouponLink) {
        var fsProvider = config.getProviderReferral();
        if (fsProvider === 'M91APP') {
            try {
                window.JSBridgePlugin.openInBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else if (fsProvider === 'FATAKP') {
            try {
                window.ReactNativeWebView.postMessage(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                window.app.openLinkOnBrowser(fsCouponLink);
            } catch (error) {
                console.log(error);
            }
        }

        if (config.getProviderSetting().fbIsPWAOpenOnWeb) {
            window.open(fsCouponLink, '_blank');
        }

    }

    closePopupAndRedirectToLink() {
        this.blinkDisable(false);
        this.setState({ fbShopOnlineLinkLoader: true });

        /* Clear blink */
        var currentTimeline = this.state.currentTimeline;
        currentTimeline.fbIsBlinkShopOnline = false;
        // this.props.history.push({
        //     pathname: '/ads-mark-as-read/'+this.state.fiActivityId,
        // });
        var fsProvider = config.getProviderReferral();
        if (this.state.currentOffer === 1000) {
            if (fsProvider === 'M91APP') {
                this.openLinkOnBrowser(this.state.currentTimeline.fsShopOnlineLink);
            } else {
                window.open(this.state.currentTimeline.fsShopOnlineLink, '_blank');
            }
        } else {
            var couponDetails = this.state.currentTimeline.foCouponList[this.state.currentOffer];
            if (couponDetails.fsCouponCode !== "") {
                // console.log(couponDetails.fsCouponCode);
                this.copyToClipboard(couponDetails.fsCouponCode);
            }
            // if(fsProvider==='M91APP'){
            this.openLinkOnBrowser(couponDetails.fsCouponLink);
            // }else{
            //     window.open(couponDetails.fsCouponLink, '_blank');
            // }
        }

        setTimeout(() => {
            this.setState({ isOpenContinuePopup: false, fbShopOnlineLinkLoader: false });
            this.goBack();
        }, 10000);
    }

    blinkDisable(isShopOnlineSession = true) {

        var requestParams = {
            "fsAction": "updateShopOnlineBlink",
            "fiActivityId": this.state.currentTimeline.fiActivityId
        };
        config.fetchInformation(requestParams).then((responseJson) => {
            console.log('Blonk Updated', responseJson);
        });

        var isDisableMarkAsRead = true;
        var enableReadPinPopup = false;
        if (this.state.currentTimeline.fsPinColor === 'green') {
            isDisableMarkAsRead = false;
            var enableReadPinPopup = true;
        }
        if (this.state.currentTimeline.fiAdCouponType === 1) {
            enableReadPinPopup = false;
            this.openLinkOnBrowser(this.state.currentTimeline.fsShopOnlineLink);
        }
        this.setState({ isDisableMarkAsRead: isDisableMarkAsRead, 'isBlinkButton': false, 'isDisplayLocations': true, isOpenContinuePopup: enableReadPinPopup, currentOffer: 1000 });
        if (isShopOnlineSession) {
            config.updateUserSession('shop_online', this.state.currentTimeline.fiAdId);
        }
    }

    handleClose() {
        this.setState({ 'isOpenPopup': false })
    }

    openPopup() {
        this.setState({ 'isOpenPopup': true })
    }

    updateMarkAsRead() {
        this.setState({ isLoading: true });
        var requestParams = {
            "fsMobile_number": localStorage.getItem('PhonePrefix').trim() + localStorage.getItem('userPhone'),
            "fsAd_Id": this.state.currentTimeline.ad_id
        };
        var formBody = config.convertParams(requestParams);
        var request = config.getRequestHeaders();
        fetch(config.API_URL + 'Update_MarketingAdsUsedStatus&' + formBody, request)
            .then((response) => response.text())
            .then((responseText) => {
                // this.props.history.push({
                //     pathname: '/my-timeline',
                //     state:{ }
                // });

                this.withOutApiGoTimelineWithBillPopup();
            });
    }

    handlePasswordChange(e) {
        const value = e.target.value;
        if (value === this.state.currentTimeline.fsGiftCardUsedPassword) {
            this.setState({ isDisableMarkAsRead: false });
        } else {
            this.setState({ isDisableMarkAsRead: true });
        }
    }

    handlePhoneChange(phone) {
        phone = phone.replace('+', '-');
        if (phone === localStorage.getItem('userPhone')) {
            this.setState({ isDisableMarkAsRead: false });
        } else {
            this.setState({ isDisableMarkAsRead: true });
        }
    }

    goBack() {
        this.props.history.push({
            pathname: '/my-timeline'
        });
    }

    toggleCouponPurchase(key) {
        let style = document.querySelector('.onlineOfferItem.location-' + key).parentElement.parentElement.lastChild.getAttribute('style');
        if (style === 'display: block;') {
            document.querySelector('.onlineOfferItem.location-' + key).parentElement.parentElement.lastChild.style.display = 'none';
        } else {
            document.querySelector('.onlineOfferItem.location-' + key).parentElement.parentElement.lastChild.style.display = 'block';
        }
    }

    purchaseOfferCode(paymentObject) {
        console.log('paymentObject ', paymentObject);
        console.log('status in voucher', paymentObject.STATUS);
        if (paymentObject.STATUS === "TXN_SUCCESS") {
            var requestParams = {
                "fsAction": "addCouponPurchase",
                "fiAdId": this.state.currentTimeline.fiAdId,
                "fiPriceMapId": this.state.fiPriceMapId,
                "fiPaidAmt": parseInt(paymentObject.TXNAMOUNT),
                "foTransactionData": paymentObject,
            };
            config.fetchInformation(requestParams).then((responseJson) => {
                if (responseJson.fbIsError === true) {
                    toast.error(responseJson.fsMessage, { position: "top-center", autoClose: 8000 });
                } else {
                    window.location.reload();
                }
            });
        } else {
            this.setState({ fbIsOpenPaymentMethod: false, showPaymentLoader: false });
            toast.error(paymentObject.RESPMSG, { position: "top-center", autoClose: 8000 });
            return;
        }
    }



    render() {

        /* No Internet */
        if (!navigator.onLine) {
            return (
                <InternetNotAvailable />
            )
        }

        if (this.state.isLoading) {
            return (
                <div className="apiCallLoader fullScreenLoader">
                    <CircularProgress color="secondary" />
                </div>
            );
        }

        var onlineOfflineText = this.state.currentTimeline.fsPinColor === 'green' ? 'Online' : 'Offline';
        var shopOnlineText = 'Locate Nearby Stores';
        if (this.state.currentTimeline.fsPinColor === 'green') {
            shopOnlineText = "Shop Now";
            if (this.state.currentTimeline.fbIsLabelOrderNow === 1) {
                shopOnlineText = "Order Now";
            }
            if (this.state.currentTimeline.fbIsLabelOrderNow === 2) {
                shopOnlineText = "Pay Now";
            }
            if (this.state.currentTimeline.fbIsLabelOrderNow === 3) {
                shopOnlineText = "Mark as Used";
            }
        }

        var offerListHtml = '';
        var locationsListHtml = '';
        // if(this.state.currentTimeline.fsPinColor==='green'){
        if (this.state.currentTimeline.foCouponList.length > 0) {
            offerListHtml = <div className="offerListTabel">
                <div className="sub-title-of-offers">
                    <div>EXCLUSIVE STORE OFFER</div>
                    {this.state.currentTimeline.fiAdCouponType === 0 ? <div>{config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} is the same {this.state.currentTimeline.fsFlatCashBack} Extra.</div> : ''}
                </div>
                {this.state.currentTimeline.foCouponList.map((offerItem, key) => {
                    var blinkClass = "";
                    if (this.state.activeLocation === "location-" + key) {
                        blinkClass = " ActiveBannerAd";
                    }
                    let fsCouponHTML = <div>
                        <div className="offlineCouponLink" onClick={() => this.copyOfferToClipBoard(key)}>{offerItem.fsCouponName}</div>
                        {offerItem.fbIsUserPurchased === true ? <div className="coupon-valid-till">{offerItem.fdExpiryDate}</div> : ''}
                    </div>
                    if (offerItem.fbIsPurchaseEnable === true) {
                        fsCouponHTML = <div>
                            <div className="offlineCouponLink" onClick={() => this.toggleCouponPurchase(key)}>
                                {offerItem.fsCouponName}<br /> Pay Rs.{offerItem.fiPrice}
                            </div>
                        </div>;
                    }
                    return (
                        <div>
                            <div className={blinkClass} key={key}>
                                <div className={"onlineOfferItem location-" + key}>
                                    <div>{offerItem.fsCouponDescription}</div>
                                    <div>
                                        {localStorage.getItem('fbIsMobileConnected') === 'true' ?
                                            fsCouponHTML
                                            :
                                            <div className="offlineCouponLink" onClick={() => this.setState({ 'fbIsDevicePopupEnable': true, currentOffer: key })}>{offerItem.fsCouponName}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {offerItem.fbIsPurchaseEnable === true ?
                                <div style={{ 'display': 'none' }} className="code-purchase-body">
                                    <div className="code-purchase-dropdown">
                                        <table width={'100%'}>
                                            <tr>
                                                <td style={{ 'textAlign': 'left' }}>STORE DISCOUNT</td>
                                                <td>QUANTITY</td>
                                                <td>BUY NOW</td>
                                            </tr>
                                            <tr>
                                                <td style={{ 'textAlign': 'left' }}>Upto {this.state.currentTimeline.fsDiscountUpTo}</td>
                                                <td>1</td>
                                                <td>Rs. {offerItem.fiTotalPrice}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ 'textAlign': 'left' }} colSpan={'2'}>Debit {config.getGlobalCashbackString()} (Max You Can Redeem)</td>
                                                <td>Rs. {offerItem.fiSalePrice}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={'3'}>
                                                    <div className="code-purchase-footer">
                                                        <div><b>You Save Rs. {offerItem.fiSalePrice}</b></div>
                                                        <div><b>You Pay Only Rs. {offerItem.fiPrice}</b></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    {(this.state.currentTimeline.fiMinOrderAmount !== '') ?
                                        <div style={{ 'textAlign': 'center', 'marginTop': '12px' }}>Minimum Billing Rs {this.state.currentTimeline.fiMinOrderAmount}</div>
                                        : ''
                                    }
                                    <div className="Coupon ShopOnline box-shadow active">
                                        <div rel="noopener noreferrer" target="_blank" onClick={() => this.setState({
                                            fbIsOpenPaymentMethod: true,
                                            showPaymentLoader: true,
                                            fiCouponIndex: key,
                                            fiPayableAmt: offerItem.fiPrice,
                                            fiPriceMapId: offerItem.fiPriceMapId,
                                            fiSalePrice: offerItem.fiSalePrice
                                        })}>{this.state.showPaymentLoader && this.state.fiCouponIndex === key ? 'LOADING...' : 'BUY DISCOUNT CODE NOW'}</div>
                                    </div>
                                    <div style={{ 'textAlign': 'center' }}>( Use only UPI Payment Option For Now )</div>
                                </div>
                                : ''}
                        </div>
                    )
                })}
            </div>;
        }

        /* Open Payment Method */
        var PaymentMethodHtml = "";
        if (this.state.fbIsOpenPaymentMethod) {
            var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
            var orderDetails = {
                fiTotalAmount: this.state.fiPayableAmt,
                fsDescription: this.state.currentTimeline.fsAdName + ' Online Green Coupon Purchase',

                /* For Just Pay */
                "fiAdId": this.state.currentTimeline.fiAdId,
                "fiPriceMapId": this.state.fiPriceMapId,
                "fiPaidAmt": this.state.fiPayableAmt,
                "fiUserId": foGlobalSetting.fiUserId,
                "fsUserContact": localStorage.getItem('userPhone'),
                "fsType": 'purchase-offer-code'
            };
            PaymentMethodHtml = <PaymentMethod hideLoader={() => this.setState({ showPaymentLoader: false })}
                placeVoucherOrder={this.purchaseOfferCode}
                orderDetails={orderDetails}
                backToMain={() => { this.setState({ fbIsOpenPaymentMethod: false, showPaymentLoader: false, openRedirectPopup: true }) }}></PaymentMethod>
        }

        if (this.state.currentTimeline.fsPinColor === 'red') {
            if (this.state.currentTimeline.foLocationList.length > 0) {
                var displayStatus = 'none';
                if (this.state.isDisplayLocations) {
                    displayStatus = "block";
                }
                locationsListHtml = <div className="nearByLocations" style={{ 'display': displayStatus }}>
                    {this.state.currentTimeline.foLocationList.map((singleLocation, key) => {
                        var googleMapLink = 'https://maps.google.com/?q=' + singleLocation.location_lat + ',' + singleLocation.location_long;
                        return (
                            <div className="Coupon ShopOnline active" key={key}>
                                <a href={googleMapLink} rel="noopener noreferrer" target="_blank">NEARBY STORE {key + 1}</a>
                            </div>
                        )
                    })}
                </div>
            }
        }

        var shopOnlineLink = config.getTextFirstLink(this.state.currentTimeline.fsCouponDescription);
        if (this.state.currentTimeline.fsShopOnlineLink !== "") {
            shopOnlineLink = this.state.currentTimeline.fsShopOnlineLink;
        }

        if (localStorage.getItem('fbIsMobileConnected') === 'true' || this.state.currentTimeline.fsPinColor === 'red') {
            var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" target="_blank" onClick={this.blinkDisable}>{shopOnlineText}</div>;
        } else {
            var shopOnlineLinkHtml = <div href={shopOnlineLink} rel="noopener noreferrer" target="_blank" onClick={() => this.setState({ 'fbIsDevicePopupEnable': true })}>{shopOnlineText}</div>;
        }
        var shopClasses = 'Coupon ShopOnline box-shadow ';
        if (shopOnlineLink !== "") {
            shopClasses = shopClasses + 'active';
            if (this.state.isBlinkButton) {
                shopClasses = shopClasses + ' blink_me';
            }
        }

        var deliveryPopupMessage = '<span style="font-size:14px !important;">\
        AFTER PAYMENT AT STORE,<br/>\
        Transaction is Tracked & SMS is<br/>\
        sent within 24 Hrs to Max 36 Hrs<br/>\
        This SMS confirms the Cashback<br/><br/>\
        Cash Credit in '+ this.state.currentTimeline.fsWalletName + '<br/>\
        Within '+ this.state.currentTimeline.fiVirtualCashTransferDays + ' days, after the expiry of<br/>\
        product Return/Exchange period.<br/><br/>\
        OUR Cashback, No Code Needed<br/>\
        You may use Coupon codes given<br/>\
        at site checkout, if not given by us.<br/>\
        ';
        if (this.state.currentTimeline.fbIsBillUploadEnable) {
            deliveryPopupMessage = '<span style="font-size:14px !important;">\
                AFTER PAYMENT AT STORE,<br/>\
                Register Billing in “MyCoupons”<br/>\
                you will find the same coupon<br/>\
                Cashback is then Confirmed &<br/>\
                Tracked in about 24 hrs by SMS<br/><br/>\
                Cash Credit in '+ this.state.currentTimeline.fsWalletName + '<br/>\
                Within '+ this.state.currentTimeline.fiVirtualCashTransferDays + ' days, after the expiry of<br/>\
                product Return/Exchange period.<br/><br/>\
            ';
        }

        if (this.state.currentTimeline.fbIsMyCash === 'yes') {
            deliveryPopupMessage = '<span style="font-size:14px !important;">\
                AFTER CHECKOUT / PAYMENTS<br/>\
                CHECK MESSAGES OR SMS FOR<br/><br/>\
                “TRACKING MESSAGE”<br/><br/>\
                WITHIN 5 MINS TO 24 HRS <br/><br/>\
                AFTER THAT MYCASH CREDIT<br/>\
                WILL SHOW IN YOUR WALLET\
            ';
        }

        if (this.state.openCodePurchasePopup === true) {
            deliveryPopupMessage = '<span style="font-size:12px !important;">(To use code later, find it in MyCoupons)</span><br/><br/>\
            <span style="font-size:1rem !important;">You Don\'nt need to pay for the<br/>\
            code now, we will debit later <br/>\
            from your "Cashback" credit<span>\
            <ul style="text-align: left;list-style-type: disclosure-closed;padding-left: 2rem;">\
                <li>Apply code to get discount.</li>\
                <li>Pay & get tracked message <br/> in 5Mins to 24Hrs, that will <br/> confirm EXTRA cashback</li>\
                <li>Check for cashback credit <br/> status in your app wallet.</li>\
            </ul>\
            <span style="font-size:14px !important;font-weight:bold">\
                You Save Rs.'+ this.state.fiSalePrice + ', Pay Only Rs.' + this.state.fiPayableAmt + '\
            </span>';
        }

        if (this.state.openRedirectPopup === true) {
            var deliveryPopupMessage = '<span style="font-size:13px !important;">\
              On Order Verified, <br/>\
              You can find your order in My Coupons<br/>\
              ';
        }

        var shopAsUsualText = "SHOP AS USUAL";
        if (this.state.currentTimeline.fbIsLabelOrderNow === 1) {
            shopAsUsualText = "Order As Usual";
        }
        if (this.state.currentTimeline.fbIsLabelOrderNow === 2) {
            shopAsUsualText = "Pay Now";
        }
        if (this.state.currentTimeline.fbIsLabelOrderNow === 3) {
            shopOnlineText = "Mark as Used";
        }

        var CouponBg = config.getProviderSetting().fsCouponImg;
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >
                {PaymentMethodHtml}
                {this.state.fbIsDevicePopupEnable ? <DeviceConnectionPopup popupContent="To create your account for<br/>Cashback Credit" closeDevicePopup={this.closeDevicePopup} /> : ''}

                {this.state.isOpenPopup ? <MarkAsReadPopup updateMarkAsRead={this.updateMarkAsRead} handleClose={this.handleClose} popupContent={this.state.popupContent} openDialog={true} /> : ''}

                {this.state.isOpenContinuePopup ?
                    <ContinuePopup popupButtonText={this.state.fbShopOnlineLinkLoader ? 'Please wait...' : shopAsUsualText}
                        popupTitle={config.getCashbackString(this.state.currentTimeline.fbIsMyCash).toUpperCase() + " ACTIVATED"}
                        popupContent={deliveryPopupMessage}
                        secondLineText=""
                        handleClose={this.closePopupAndRedirectToLink} openDialog={true} /> : ''}

                {this.state.openCodePurchasePopup ?
                    <ContinuePopup popupButtonText={this.state.fsPopupBtnTxt}
                        popupTitle={this.state.fsPopupTitle}
                        popupContent={deliveryPopupMessage}
                        secondLineText=""
                        handleClose={this.closePopupAndRedirectToLink} openDialog={true} /> : ''}
                {this.state.openRedirectPopup ?
                    <ContinuePopup popupButtonText={'Ok'}
                        popupTitle={''}
                        popupContent={deliveryPopupMessage}
                        secondLineText=""
                        handleClose={() => this.closePopupAndRedirect('my-timeline')} openDialog={true} />
                    : ''}

                <ToastContainer theme="colored" position="bottom-left" />
                {this.state.fbIsMyCashPopup ? <MoreCashbackPopup
                    handleClose={() => { this.setState({ fbIsMyCashPopup: false }) }}
                    popupButtonText="Ok"
                    cashbackType={'mycash'}
                    openDialog={true} /> : ''}
                <div className="AppDashboard markAsReadScreen">
                    <div className="screenPadding">
                        <div className="markAsReadBack">
                            <span style={{ 'fontSize': '24px', 'marginTop': '-2px' }} className="material-icons-outlined" onClick={this.goBack}>keyboard_backspace</span>
                        </div>
                        <div className="MainTitle">
                            <div className="onlineOfflineTitle">{onlineOfflineText} COUPON</div>



                            {/* <div>{this.state.currentTimeline.fsAdName}</div>
                            
                            <div className="discount-and-cashback">
                                <div>Upto {this.state.currentTimeline.fsDiscountUpTo} Discount</div>
                                {this.state.currentTimeline.fsFlatCashBack!==""?<div>Exact {this.state.currentTimeline.fsFlatCashBack} Cashback</div>:''}
                            </div> */}

                            {/* <div className="couponCodeInCouponScn">Cashback Rs {this.state.currentTimeline.fiQuizReward}</div> */}
                        </div>

                        <div className="newOnlineCouponDesign">
                            <img src={CouponBg} />
                            <img className="brandLogo" src={config.getImageUrl(this.state.currentTimeline.fsAdLogo)} />
                            <div className="offer-name">{this.state.currentTimeline.fsAdName}</div>
                            <div className="extra-text">{this.state.currentTimeline.fsAdditionLabel}</div>
                            <div className="upto-dis text-design">
                                <span>Upto</span>
                                <span>{this.state.currentTimeline.fsDiscountUpTo}</span>
                                <span>Brand</span>
                                <span>Discount</span>
                            </div>
                            {this.state.currentTimeline.fiAdCouponType === 1 ?
                                <div>
                                    <div className="upto-amazon text-design">
                                        <span>Upto</span>
                                        <span>{this.state.currentTimeline.fsFlatCashBackAmazon}</span>
                                        <span>Instant</span>
                                        <span>Discount</span>
                                    </div>
                                    <div className="major-card">
                                        (On most Major Cards)
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="upto-other text-design">
                                        <span>Exact</span>
                                        <span>{this.state.currentTimeline.fsFlatCashBack}</span>
                                        <span>{config.getCashbackString(this.state.currentTimeline.fbIsMyCash)}</span>
                                        <span>Everytime</span>
                                    </div>
                                    {this.state.currentTimeline.fbIsMyCash === "no" ? <div className="max-cashback-amount">Max {config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} Rs {this.state.currentTimeline.fiQuizReward}</div> : ""}
                                </div>
                            }
                        </div>

                        {/* <div className="Coupon bgWhite">
                            {this.state.currentTimeline.fsCouponCode}
                        </div> */}
                        {this.state.currentTimeline.fsPinColor === 'green' ? <div className={shopClasses}>
                            {shopOnlineLinkHtml}
                        </div> : ''}
                        <div className="DontForgotUploadBill">
                            {(function ($this) {
                                if ($this.state.currentTimeline.fbIsMyCash === 'yes' && $this.state.currentTimeline.fiAdCouponType === 0) {
                                    return <span style={{ "marginLeft": '-30px' }}>({config.getCashbackString($this.state.currentTimeline.fbIsMyCash)} Credit After Tracking Message)
                                        <span style={{ "position": "absolute", "marginLeft": '10px' }} onClick={() => { $this.setState({ 'fbIsMyCashPopup': true }) }} className="material-icons-outlined help-icon">help</span>
                                    </span>;
                                } else if ($this.state.currentTimeline.fiAdCouponType === 0) {
                                    return <>
                                        <span>({config.getCashbackString($this.state.currentTimeline.fbIsMyCash)} in your {$this.state.currentTimeline.fsWalletName} in ~{$this.state.currentTimeline.fiVirtualCashTransferDays} Days)</span><br />
                                        <span>(Your {config.getGlobalCashbackString()} will be debited equal to cashback credit)</span>
                                    </>
                                }
                            })(this)}
                            {/* {this.state.currentTimeline.fbIsMyCash === 'yes' ?
                                this.state.currentTimeline.fiAdCouponType === 0 ?
                                    <span style={{ "marginLeft": '-30px' }}>({config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} Credit After Tracking Message)
                                        <span style={{ "position": "absolute", "marginLeft": '10px' }} onClick={() => { this.setState({ 'fbIsMyCashPopup': true }) }} className="material-icons-outlined help-icon">help</span>
                                    </span> : ''
                                :
                                this.state.currentTimeline.fiAdCouponType === 0 ? <span>({config.getCashbackString(this.state.currentTimeline.fbIsMyCash)} in your {this.state.currentTimeline.fsWalletName} in ~{this.state.currentTimeline.fiVirtualCashTransferDays} Days)</span> : ''
                            } */}
                            {/* CASHBACK paid in your <br/><b>{this.state.currentTimeline.fsWalletName}</b> IN <b>{this.state.currentTimeline.fiVirtualCashTransferDays}</b> DAYS. */}
                        </div>

                        {this.state.currentTimeline.fiAdCouponType === 0 ?
                            <Card>
                                <div className="Description" dangerouslySetInnerHTML={{ __html: this.state.currentTimeline.fsOfferDetails }}>
                                </div>
                            </Card>
                            :
                            <div className="amazon-slider">
                                {SwiperCore.use([Autoplay])}
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    navigation
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false
                                    }}
                                    pagination={{ clickable: false }}
                                    scrollbar={{ draggable: true }}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() => console.log('slide change')}
                                >
                                    {this.state.currentTimeline.foCouponList.map((offerItem, key) => {
                                        if (offerItem.fsCouponBanner === "") { return <></> }
                                        return (
                                            <SwiperSlide key={key}>
                                                <div className="slideItems">
                                                    <img onClick={() => this.scrollToRef('location-' + key)} src={offerItem.fsCouponBanner} />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        }

                        {this.state.currentTimeline.fsPinColor === 'red' ? <div className={shopClasses}>
                            {shopOnlineLinkHtml}
                        </div> : ''}

                        <div className="offerListMain">
                            {locationsListHtml}
                            {offerListHtml}
                        </div>
                        {this.state.currentTimeline.fsPinColor === 'green' ? '' :
                            <div className={this.state.isBlinkButton ? 'passwordInput firstNameLastName disabledPassword' : 'passwordInput firstNameLastName'}>
                                {this.state.currentTimeline.fsGiftCardUsedPassword === "" ?
                                    <div className="OTPInput">
                                        <div>
                                            <PhoneInput
                                                country={'in'}
                                                autoFormat={false}
                                                countryCodeEditable={false}
                                                enableSearch={true}
                                                searchPlaceholder='Search Country'
                                                placeholder="Mobile No. to Mark as Used"
                                                onChange={(phone) => this.handlePhoneChange(phone)}
                                                disabled={this.state.isBlinkButton}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="passwordInput">
                                        <input type="number" disabled={this.state.isBlinkButton} placeholder="Gift Card Pin" className="form-control customNomberInput" onChange={(event) => this.handlePasswordChange(event)} />
                                    </div>
                                }
                            </div>
                        }

                        {/* <div className="submitButton">
                            {this.state.currentTimeline.fsPinColor=== 'green'?
                                <Button onClick={this.withOutApiGoTimelineWithBillPopup} classes={{root:this.state.isBlinkButton?'':'blink_me'}} disabled={this.state.isDisableMarkAsRead} variant="contained" color="secondary">
                                    <span className="btnLabel">Register<br/>Bill</span>
                                </Button>
                            :
                                <Button onClick={this.openPopup} classes={{root:this.state.isBlinkButton?'':'blink_me'}} disabled={this.state.isDisableMarkAsRead} variant="contained" color="secondary">
                                    {this.state.isLoading === true ?
                                        <CircularProgress color="secondary" />
                                        :
                                        <span className="btnLabel">Mark as Used</span>
                                    }
                                </Button>
                            }
                            <Button variant="contained" onClick={this.goBack} color="secondary">
                                    Back To My<br/>Coupons
                            </Button>
                        </div> */}

                        {/* <div className="otherDetails">
                            After Shopping, Find the Brand Coupon IN <br/>TIMELINE & Upload Bill Showing<br/>Amount/Date Your Moby Mobile.
                        </div> */}
                        {this.state.currentTimeline.fsRemainDay !== "" ? <div className="Coupon validDays bgBlack colorRed">{this.state.currentTimeline.fsRemainDay}</div> : ''}
                    </div>
                </div>
            </motion.div>
        )

    }
}