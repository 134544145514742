import React, { Component } from 'react';
import config from './config';

import TopBarTitle from './TopBarTitle';
import ProfileForm from './updateProfile/ProfileForm';

import CircularProgress from '@material-ui/core/CircularProgress';

import { motion } from "framer-motion";

import Card from '@material-ui/core/Card';

import DeviceConnectionPopup from './popup/DeviceConnectionPopup';
import Button from '@material-ui/core/Button';

export default class UpdateProfile extends Component {

    constructor() {
        super();

        this.state = {
            isProfileSaved: false,
            haveDonate: false,
            isLoading: true,
            isOpenTermsPopup:false,
            termsAndConditionDetails:'',

            phone: localStorage.getItem('userPhone'),
            apiResponse: {},

            fbIsDevicePopupEnable : true
        }

        this.onUpdateProfile = this.onUpdateProfile.bind(this);
        this.acceptTermsAndCondition = this.acceptTermsAndCondition.bind(this);
        this.closeTermsScreen = this.closeTermsScreen.bind(this);
        this.goBack = this.goBack.bind(this);
        this.closeDevicePopup = this.closeDevicePopup.bind(this);
    }

    closeDevicePopup(isSuccess){
        this.setState({'fbIsDevicePopupEnable':false})
    }

    goBack() {
        this.props.history.push({
            pathname: '/',
        });
    }

    closeTermsScreen(){
        this.setState({
            isOpenTermsPopup:false
        });
    }

    acceptTermsAndCondition(){
        this.setState({
            isOpenTermsPopup:true 
        });
        var requestParams = { "fsAction":"loadWebView", "fsPage":"tandc" };
        config.fetchInformation(requestParams).then((responseJson)=>{
            this.setState({
                termsAndConditionDetails: responseJson.fsMessage,
            });
        });
    }

    onUpdateProfile() {
        this.setState({ 'isProfileSaved': true });
        this.props.history.push({
            pathname: '/'
        });
    }

    componentWillMount() {

        var requestParams = {
            "fsAction":"getUserDetails"
        };
        config.fetchInformation(requestParams).then((responseJson)=>{
            if(responseJson.fbIsError===false){
                localStorage.setItem('userProfile',JSON.stringify(responseJson));
                this.setState({
                    apiResponse: responseJson,
                    isLoading: false
                });
            }
        });
    }


    render() {
        var apiResponse = this.state.apiResponse;

        if(localStorage.getItem('fbIsMobileConnected')!=='true'){
            return(
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={config.pageAnimation}
                    transition={config.pageTransition}
                >
                    <div className="AppDashboard">
                        <div className="topAndSideBar">
                            <TopBarTitle strTitle="My Profile" goBack={this.goBack} />
                        </div>
    
                        <div className="AppContent isNormalView isTopBarOnTop">
                            <Card>
                                <div className="profileDeviceDetection">
                                    <div className="centerScreen">
                                        <h2>Verify Phone</h2>
                                        <div>To confirm your account & make<br/>edit details</div>
                                        <Button variant="contained" onClick={()=>this.setState({'fbIsDevicePopupEnable':true})} color="primary">Continue</Button>
                                    </div>
                                </div>

                                {this.state.fbIsDevicePopupEnable?<DeviceConnectionPopup popupContent="To confirm your account & make<br/>edit details" closeDevicePopup={this.closeDevicePopup}/>:''}
                            </Card>
                        </div>
                    </div>
                </motion.div>
            );
        }

        if(this.state.isOpenTermsPopup){
            return (
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={config.pageAnimation}
                    transition={config.pageTransition}
                >
                    <div className="AppDashboard">
                        <div className="topAndSideBar">
                            <TopBarTitle strTitle="Terms & Conditions" goBack={this.closeTermsScreen} />
                        </div>
    
                        <div className="AppContent isNormalView isTopBarOnTop">
                            <Card>
                                {this.state.termsAndConditionDetails === '' ?
                                    <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                                    :
                                    <div className="termsDetails" style={{'padding':'12px','paddingBottm':'0px'}} dangerouslySetInnerHTML={{__html: this.state.termsAndConditionDetails}}></div>
                                }
                            </Card>
                        </div>
                    </div>
                </motion.div>
            )
        }

        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={config.pageAnimation}
                transition={config.pageTransition}
            >
                <div className="AppDashboard">
                    <div className="topAndSideBar">
                        <TopBarTitle strTitle="My Profile" goBack={this.goBack} />
                    </div>

                    <div className="AppContent isNormalView isTopBarOnTop">
                        {this.state.isLoading === true ?
                            <div className="apiCallLoader"><CircularProgress color="secondary" /></div>
                            :
                            <ProfileForm apiResponse={apiResponse} acceptTermsAndCondition={this.acceptTermsAndCondition} onUpdateProfile={this.onUpdateProfile} />
                        }
                    </div>
                </div>
            </motion.div>
        )
    }
}
