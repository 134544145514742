import React, { Component } from "react";
import config from './../config';

import $ from "jquery";

export default class CreditCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fullDetails : this.props.fullDetails,
        }

        this.removeCard = this.removeCard.bind(this);
    }

    removeCard(cardId){
        var currentCard = this.state.fullDetails.foUserCardList[cardId];
        
        localStorage.setItem('isOpenCardSelectionPopup','false');

        var requestParams = {
            "fsAction" : 'deleteUserCard' ,
            "fiCardId" : currentCard.fiCardId
        };

        config.fetchInformation(requestParams).then((res)=>{
            // console.log(res);
        })
    }

    componentDidMount(){

        /* Remove Card Toggle */
        $(document).on('click','.creditCardsContainer .removeCards',function(){
            $(this).closest('.singleCreditCard').fadeOut().remove();
        });

    }

    render() {
        var creditCardHtml = "";
        if(this.state.fullDetails.foUserCardList!==undefined && 
           this.state.fullDetails.foUserCardList.length > 0){
            creditCardHtml = <div className="creditCardRow">
                {this.state.fullDetails.foUserCardList.map((item,i)=>{
                    return(
                        <div key={i} className="singleCreditCard">
                            <h5>{item.fsBankName}</h5>
                            <div>{item.fsCardType}</div>
                            <div>Show Me Live Rewards Pan India</div>
                            <div className="removeCards" onClick={()=>this.removeCard(i)}>
                                <span className="material-icons-outlined">
                                    highlight_off
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        }
        return (
            <div className="creditCardsOptions">
                <h3>My Credit & Debit Cards</h3>
                <div className="subTitle" style={{'marginBottom':'15px'}}>
                No Card Number / No Transactions / No Card CSV / Required, Discover Your Card Offers & Benefits Near You in Real Time.
                </div>
                <div className="creditCardsContainer">
                    {creditCardHtml}
                </div>
            </div>
        )
    }
}