import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import PhoneInput from 'react-phone-input-2'
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css'

import config from './config';

import firebaseConfig from './FireBaseConfig';
import LoginRegisterForm from './LoginRegisterForm';
// import Timer  from './Timer';

import firebase from 'firebase/app';
import 'firebase/auth';

import CircularProgress from '@material-ui/core/CircularProgress';

export default class MobileLogin extends Component {
    constructor(props) {
        super(props);

        var isProfileError = "";
        if(this.props.isDefaultError!==""){
            isProfileError = this.props.isDefaultError;
        }
        var isOpenUpdateProfileScreen = false;
        if(this.props.fbIsProvider===true){
            isOpenUpdateProfileScreen = true;
        }
        this.state = {
            'mobile': '',
            'isAddedMobile': false,
            'isMobileError': isProfileError,
            'showSendOtpLoader': false,
            'showVerifyOtpLoader': false,
            'isMobileVerifiedToServer':false,
            'isOpenUpdateProfileScreen':isOpenUpdateProfileScreen,
            'phone': '',
            'confirmResult': null, 
            'otp': '',
            'isOtpVerifyMsg': '',
            'timer': "",
            'resendText': 'Resend OTP',
            'otpSendMessage': ''
        }
        if (localStorage.getItem('userPhone') != null) {
            this.state.phone = localStorage.getItem('userPhone');
        }

        this.isMobileAddedByUser = this.isMobileAddedByUser.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.resendOTPToUser = this.resendOTPToUser.bind(this);
        this.onSaveProfile = this.onSaveProfile.bind(this);
    }

    onSaveProfile() {
        this.props.openDashboard();
    }

    openMiniProfile(){
        this.setState({
            'isOpenUpdateProfileScreen':true
        })
    }

    componentDidMount() {
        if (!firebase.apps.length) {
            console.log('App created with ', firebaseConfig);
            firebase.initializeApp(firebaseConfig);
        }
    }

    verifyOTP() {
        console.log(this.state.otp);
        this.setState({ 'showVerifyOtpLoader': true })
        this.state.confirmResult.confirm(this.state.otp).then(result => {
            // var user = result.user;
            window.verifyingCode = false;
            window.confirmationResult = null;
            
            var formBody = {fsAction:'getGlobalSetting'};
            config.fetchInformation(formBody).then((responseJson)=>{
                localStorage.setItem('foGlobalSetting',JSON.stringify(responseJson));

                if(responseJson.fbIsUserExist===true){
                    localStorage.setItem('isProfileUpdated', true);
                    this.onSaveProfile();
                }else{
                    this.openMiniProfile();
                }
            });
            
        }).catch(error => {
            console.log(error);
            window.verifyingCode = false;
            this.setState({
                'showVerifyOtpLoader': false,
                showSendOtpLoader: false,
                isOtpVerifyMsg: config.loginLabel.otpValid
            });
        });
    }

    resendOTPToUser() {
        this.setState({ 'resendText': "Resending..." });
        var phoneNumber = this.state.phone;
        phoneNumber = config.mobilePrefix.trim() + phoneNumber;
        console.log(phoneNumber);

        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: function (response) {
                    //submitPhoneNumberAuth();
                }
            }
        );
        
        firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
            .then(confirmationResult => {
                this.setState({
                    resendText: 'Resend OTP',
                    isOtpVerifyMsg: '',
                    'otpSendMessage': <span className="colorGreen">Otp Send Sucessfully</span>,
                    confirmResult: confirmationResult
                });
            })
            .catch(error => {
                this.setState({
                    resendText: 'Resend OTP',
                    isOtpVerifyMsg: '',
                    'otpSendMessage': <span className="colorRed">Error on send otp.</span>,
                });
            });
    }

    handleChange = otp => this.setState({ otp });

    isMobileAddedByUser() {
        console.log(this.state.phone);
        if (this.state.phone === "" || this.state.phone.length < 10) {
            this.setState({
                isMobileError: config.loginLabel.mobileValid
            });
        } else {
            this.setState({ 'showSendOtpLoader': true });
            var phoneNumber = this.state.phone;
            phoneNumber = config.mobilePrefix.trim() + phoneNumber;
            console.log(phoneNumber);
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: function (response) {
                        //submitPhoneNumberAuth();
                    }
                }
            );

            this.setState({
                isMobileVerifiedToServer: true
            });

            firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
                .then(confirmationResult => {
                    console.log('confirmationResult', confirmationResult);
                    this.setState({
                        isMobileError: "",
                        isAddedMobile: true,
                        confirmResult: confirmationResult
                    });
                    localStorage.setItem('userPhone', this.state.phone);
                    localStorage.setItem('PhonePrefix', config.mobilePrefix);
                })
                .catch(error => {
                    console.log('error', error);
                    window.location.reload();
                    this.setState({
                        isMobileError: error.message,
                        showSendOtpLoader:false
                    });
                });
        }
    }

    render() {
        var AppLogo = config.getProviderSetting().fsLogo;
        var otpError = "";
        if (this.state.isOtpVerifyMsg !== "") {
            otpError = <span className="mobileError" style={{'maxWidth':'290px'}}>{this.state.isOtpVerifyMsg}</span>;
        }
        if (otpError === "" && this.state.otpSendMessage !== "") {
            otpError = <span className="mobileError">{this.state.otpSendMessage}</span>;
        }

        if (this.state.isAddedMobile === true) {
            var OTPScreen =  <div className="AppLoginScreenOTP">
                    <form noValidate autoComplete="off">
                        <div className="otpMessage">
                            {config.loginLabel.OTPConfirm} <b>{config.mobilePrefix}{this.state.phone}</b>
                        </div>
                        <div className="OTPInput">
                            <div>
                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    isInputNum={true}
                                    separator={<span className="OTP-Items"></span>}
                                />
                                <div className="mobileInput pl-5">{otpError}</div>
                            </div>
                        </div>
                        <div className="submitButton progressButton mt--30 mb-20">
                            <div id="recaptcha-container"></div>
                            <Button variant="contained" onClick={this.verifyOTP} color="secondary">
                                {this.state.showVerifyOtpLoader === true ?
                                    <CircularProgress color="secondary" />
                                    :
                                    <span className="btnLabel">{config.loginLabel.VeriyButton}</span>
                                }
                            </Button>
                        </div>
                        <div className="resedOtp text-center">
                            <div onClick={this.resendOTPToUser} className="sendOtpLink mb-10">{this.state.resendText}</div>
                            {/* <div classNAme="resendCounter">02:30</div> */}
                        </div>
                    </form>
                </div>
            ;
        }

        var mobileError = "";
        if (this.state.isMobileError !== "") {
            mobileError = <span className="mobileError">{this.state.isMobileError}</span>;
        }

        var mobileInput = <PhoneInput
            country={'in'}
            autoFormat={false}
            countryCodeEditable={false}
            enableSearch={true}
            searchPlaceholder='Search Country'
            disabled={this.state.isAddedMobile === true?true:false}
            onlyCountries={['in','us','gb','au','ae']}
            // disableDropdown="true"
            // disableCountryCode="true"
            placeholder="Phone Number"
            value={this.state.phone}
            onChange={phone => this.setState({ phone })}
        />

        if(this.state.isOpenUpdateProfileScreen){
            return (
                <div className="AppLoginScreen SaveProfileFormFPay">
                    <div className="inner">
                        {/* <div className="preLogo">
                            <img alt="Logo" style={{'marginBottom':'0px'}} src={AppLogo} />
                            {/* <div className="appName">{config.appName}</div> }
                        </div> */}
                        <LoginRegisterForm onSaveProfile={this.onSaveProfile} phone={this.state.phone}/>
                    </div>
                </div>
            )
        }else{
            var minHeight = this.state.isAddedMobile === true?500:336;
            return (
                <div className="AppLoginScreen" style={{'minHeight':minHeight+'px'}}>
                    <form noValidate autoComplete="off">
                        <div className="preLogo">
                            <img alt="Logo" src={AppLogo} />
                            <div className="appName">{config.appName}</div>
                        </div>
                        {this.state.isAddedMobile !== true?
                            <div className="loginBlueBox">
                                <div>Moby Gives Real Cash !</div>
                                <div>Use correct info to match your  Bank Ac, Else online wallet may not credit.</div>
                            </div>
                        :''}
                        <div className="otpMessage" dangerouslySetInnerHTML={{__html: config.loginLabel.OTPMessage}}>
                        </div>
                        <div className="mobileInput changeHeight">
                            {mobileInput}
                            {mobileError}
                        </div>
                        {this.state.isAddedMobile?'':
                            <div className="submitButton progressButton">
                                <div id="recaptcha-container"></div>
                                <Button variant="contained" onClick={this.isMobileAddedByUser} color="secondary">
                                    {this.state.showSendOtpLoader === true ? <CircularProgress color="secondary" /> : <span className="btnLabel">{config.loginLabel.buttonName}</span>}
                                </Button>
                            </div>
                        }
                    </form>
    
                    {this.state.isAddedMobile === true?OTPScreen:''}
                </div>
            )
        }
    }
}
