import React, { Component } from "react";
import config from './config';
import MapItemList from './MapItemList';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContinuePopup from './popup/ContinuePopup';
import MoreCashbackPopup from './popup/MoreCashbackPopup';
import AdListFirstTimePopup from './popup/AdListFirstTimePopup';
import InternetNotAvailable from './InternetNotAvailable';
import SearchForm from './SearchForm';
import Button from '@material-ui/core/Button';
import TopBarTitle from './TopBarTitle';

import Snackbar from '@material-ui/core/Snackbar';
import InfiniteScroll from "react-infinite-scroll-component";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import $ from "jquery";

export default class Search extends Component {
  constructor(props) {
    super(props)
    console.log(this.props.location.state);

    var fiBannerId = 0;
    if (localStorage.getItem('fiBannerId') !== null && parseInt(localStorage.getItem('fiBannerId')) > 0) {
      fiBannerId = parseInt(localStorage.getItem('fiBannerId'));
      localStorage.removeItem('fiBannerId');
    }

    var foGlobalSetting = localStorage.getItem('foGlobalSetting');
    if (foGlobalSetting === null) {
      foGlobalSetting = {};
    } else {
      foGlobalSetting = JSON.parse(foGlobalSetting);
      if (foGlobalSetting.fbIsUserExist === false) {
        this.logoutToUser();
      }
    }

    this.state = {
      apiResponse: [],
      isLoading: true,
      selectedMarker: false,
      isLocationEnabled: true,
      lat: "",
      mapZoom: config.mapZoom,
      long: "",

      getAdsTimeInterval: 1000,
      currentCallTime: new Date().getTime(),
      isLocationSnackBar: false,

      selectedCategory: 0,
      fiBannerId: fiBannerId,
      searchValue: '',

      foGlobalSetting: foGlobalSetting,
      isDisplayFormOnLoader: false,

      isErrorPopup: '',
      currentPage: 1,
      hasMore: false,
      /* 1:Online, 2:Offline, 0:All */
      isOnlineOfflineAllFilter: this.getPageViewTypeBasedOnCategory(0),

      isOpenMyCashPopup: false,
      cashbackType: "cashback"
    }

    this.getAdsLists = this.getAdsLists.bind(this);
    this.logoutToUser = this.logoutToUser.bind(this);
    this.getAdsBasedOnConfig = this.getAdsBasedOnConfig.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.goDetailsScreenWithData = this.goDetailsScreenWithData.bind(this);
    this.enableYourLocation = this.enableYourLocation.bind(this);
    this.openLink = this.openLink.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleSearchAndCategory = this.handleSearchAndCategory.bind(this);
    this.updateOnlineOffLineStatus = this.updateOnlineOffLineStatus.bind(this);
    this.directMoveOnCouponScreen = this.directMoveOnCouponScreen.bind(this);
    this.openMyCashPopup = this.openMyCashPopup.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {

    /* Get User Details If Not Exist */
    this.getAdsBasedOnConfig();

    // this.props.history.index=0

    /* Disable Ad List Screen Back Event */
    // console.log(window.location.href);
    // if(window.location.href==='/'){
    //   window.history.replaceState(null, null, "/");
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener('popstate', function (event)
    //   {
    //     window.history.pushState(null, document.title, window.location.href);
    //   });
    // }
    /* Disable Ad List Screen Back Event */


    /* Call Again if Native Permission Provide */
    // document.updateGPSCall = () => {
    //   console.log('Location Permission Given');
    //   this.getAdsBasedOnConfig();
    // }

  }

  goBack() {
    this.props.history.push({
      pathname: '/',
    });
  }

  openMyCashPopup(type) {
    if (type === "cashback") {
      localStorage.setItem('fbIsHideCashbackHelp', "true");
    }
    this.setState({ isOpenMyCashPopup: true, cashbackType: type });
  }

  directMoveOnCouponScreen(adId) {
    this.setState({ 'isLoading': true });
    // console.log('adId',adId);
    var requestParams = {
      "fsAction": "directSubmitQuiz",
      "fiAdId": adId,
      "device_unique_id": 3,
    };
    config.fetchInformation(requestParams).then((responseJson) => {
      if (responseJson.fbIsError === true) {
        this.setState({ 'isLoading': false });
      } else {
        var goToCouponLink = '/my-timeline/' + responseJson.fsLastActivityId;
        localStorage.setItem('fiHighlightActivity', responseJson.fsLastActivityId);
        this.props.history.push({
          pathname: goToCouponLink,
        });
      }
    });
  }

  updateOnlineOffLineStatus(value) {
    this.setState({
      'isOnlineOfflineAllFilter': value,
      'currentPage': 1,
      'isLoading': true,
      'isDisplayFormOnLoader': true,
      'selectedCategory': 0
    }, () => { this.getAdsBasedOnConfig(); });
  }

  getPageViewTypeBasedOnCategory(Category) {
    var faOfflineCat = [66, 64, 67];
    return faOfflineCat.includes(Category) ? /* Offline */ 2 : /* Online */ 1;
  }

  handleSearchAndCategory(searchVal, Category, isOnlineOfflineAllFilter) {
    // var isOnlineOfflineAllFilter = this.getPageViewTypeBasedOnCategory(Category);
    this.setState({
      'selectedCategory': Category,
      'searchValue': searchVal,
      'currentPage': 1,
      'isLoading': true,
      'isDisplayFormOnLoader': true,
      'isOnlineOfflineAllFilter': isOnlineOfflineAllFilter
    }, () => { this.getAdsBasedOnConfig() });
  }

  closeSnackBar() {
    this.setState({
      isLocationSnackBar: false
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers, 
      * and you may want to customize it to your needs
      */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  goDetailsScreenWithData(adDetail) {
    console.log(adDetail);
    localStorage.setItem('isAdDetailBackToMap', true);
    this.props.history.push({
      pathname: '/start-quiz/' + adDetail.ad_id + '/' + adDetail.location[0].location_id,
      state: { marketingAd: adDetail }
    });
  }

  logoutToUser() {
    localStorage.removeItem('isUserLoggedIn');
    localStorage.removeItem('isProfileUpdated');
    localStorage.removeItem('AppLabels');
    localStorage.removeItem('userPhone');
    localStorage.removeItem('hasTermCheck');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('isShowMarketingAd');
    localStorage.removeItem('isOpenFirstProfile');
    window.location.reload();
  }

  getAdsBasedOnConfig(newLatLong = null) {
    // console.log('Re Enable Location');
    if (config.isProd === false || this.state.isOnlineOfflineAllFilter == 1) {
      // /*  Offfice Location */
      var parseLat = 21.2346964;
      var parseLong = 72.8619283;
      this.setState({ lat: parseLat, long: parseLong });
      localStorage.setItem('userLat', parseLat);
      localStorage.setItem('userLong', parseLong);
      this.getAdsLists(parseLat, parseLong, false);
    } else {

      /* Native Enable Location */
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position);
            var parseLat = position.coords.latitude;
            var parseLong = position.coords.longitude;
            var updateLatLong = {
              lat: parseLat,
              long: parseLong,
              isLocationEnabled: true
            }
            localStorage.setItem('userLat', parseLat);
            localStorage.setItem('userLong', parseLong);

            if (newLatLong !== null) {
              parseLat = newLatLong.lat;
              parseLong = newLatLong.lng;
            }
            this.setState(updateLatLong);
            this.getAdsLists(parseLat, parseLong, false);
          }, (error) => {
            this.setState({
              isLocationEnabled: false,
              isLoading: false,
              apiResponse: [],
              lat: '',
              long: ''
            });
          });
      }

      // console.log('triggerGPS');
      //  try {
      //    if(localStorage.getItem('fiDeviceType')==='0'){
      //      window.Android.triggerGPS();
      //    }else if(localStorage.getItem('fiDeviceType')==='1'){
      //      window.webkit.messageHandlers.callbackHandler.postMessage("triggerGPS");
      //    }
      //  } catch (e) {
      //    console.log(e);
      //  }

    }
  }

  enableYourLocation() {
    this.setState({
      apiResponse: [],
      isLoading: true,
      isLocationSnackBar: true
    }, () => { this.getAdsBasedOnConfig() });
  }

  getAdsLists(parseLat, parseLong, isInfiniteScroll) {
    /* Ad Api */
    [parseLat, parseLong] = [28.4789515, 77.0807113];
    var foDeviceDetails = '';
    if (localStorage.getItem('foDeviceDetails') !== "") {
      foDeviceDetails = localStorage.getItem('foDeviceDetails');
    }

    var isMarketing = 'false';
    // if(localStorage.getItem('isShowMarketingAd')==='true'){
    //   isMarketing = 'true';
    //   localStorage.setItem('isShowMarketingAd','false')
    // }

    var currentPage = this.state.currentPage;
    if (isMarketing === 'false' && isInfiniteScroll) {
      if (isInfiniteScroll) {
        currentPage++;
      } else {
        currentPage = 1;
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: true });
    }

    var requestParams = {
      "fsAction": "getOfferList",
      "fsDeviceId": localStorage.getItem('userPhone'),
      "ffUserLat": parseLat,
      "ffUserLng": parseLong,
      "isMarketingAds": isMarketing,
      "fiPageViewType": this.state.isOnlineOfflineAllFilter,
      "fbIsMapView": false,
      "foFilterParams": {
        "fiBannerId": this.state.fiBannerId,
        "fsSearchString": this.state.searchValue.trim(),
        "fiCurrentPage": currentPage,
        "fiCategoryId": this.state.selectedCategory,
        "fiHighlighOfferId": parseInt(localStorage.getItem('fiBannerAdId')) > 0 ? localStorage.getItem('fiBannerAdId') : 0
      }
    };

    config.fetchInformation(requestParams).then((responseJson) => {
      var responseText = JSON.stringify(responseJson);
      if (isMarketing === 'true') {
        if (JSON.parse(responseText).fbIsError === false) {
          this.goDetailsScreenWithData(JSON.parse(responseText).foOfferList[0]);
        } else {
          this.getAdsLists(parseLat, parseLong, false);
          return false;
        }
      }

      var isErrorPopup = "";
      if (JSON.parse(responseText).fbIsError === true && JSON.parse(responseText).fbIsLogout === true) {
        isErrorPopup = JSON.parse(responseText).fsMessage;
      }

      var responseJson = JSON.parse(responseText);
      var appendJson = this.state.apiResponse;
      /* Load more */
      var hasMore = false;
      if (responseJson.foOfferList.length > 0) {
        hasMore = true;
      }

      if (isInfiniteScroll) {
        if (appendJson !== "" && appendJson.foOfferList.length > 0) {
          if (responseJson.foOfferList.length > 0) {
            appendJson.foOfferList = appendJson.foOfferList.concat(responseJson.foOfferList);
          }
        } else {
          appendJson = responseJson;
        }
      } else {
        appendJson = responseJson;
      }

      this.setState({
        apiResponse: appendJson,
        isLoading: false,
        isErrorPopup: isErrorPopup,
        currentPage: currentPage,
        hasMore: hasMore,
        getAdsTimeInterval: parseInt(JSON.parse(responseText).getAdsTimeInterval),
        mapZoom: parseInt(JSON.parse(responseText).fIPWAZoom)
      });

    });

  }

  handleClick = (marker) => {
    // console.log({ marker })
    this.setState({ selectedMarker: marker })
  }

  openLink(link) {
    this.props.history.push({
      pathname: link
    });
  }

  render() {
    var NoDataFound = config.getProviderSetting().fsNoData;

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable isWithoutTopBar={true} />
      )
    }

    var varFirstTimePopupHtml = "";
    var loaderHtml = "";
    if (this.state.isLoading) {
      loaderHtml = <div className="apiCallLoader fullScreenLoader">
        <CircularProgress color="secondary" />
      </div>;
    }

    /* Handle Ads View */
    var searchFormToggleFixed = 'searchFormToggleFixed';
    if (localStorage.getItem('isSearchBlink') !== 'false') {
      searchFormToggleFixed += ' blink_me';
    }

    var searchForm = (
      <div className="searchToggleHandler">
        <SearchForm
          handleSearchAndCategory={this.handleSearchAndCategory}
          updateOnlineOffLineStatus={this.updateOnlineOffLineStatus}
          currentAdType={this.state.isOnlineOfflineAllFilter}
          searchValue={this.state.searchValue}
          selectedCategory={this.state.selectedCategory}
          isSearchScreen={true}
        />
      </div>);

    var displayError = "";
    /*Need display loader or error*/
    if (this.state.lat === "" && this.state.isLocationEnabled === false) {
      displayError = <div style={{ padding: '15px' }}>
        <div className="NoDataFound" style={{ 'marginTop': '60px' }}>
          <img src={NoDataFound} alt="No Data Found" />
          <div>Disable Location!</div>
          <div>These are “In store” Coupons for brand stores Near you. Please allow location to view.</div>
          <div className="enableYourLocation">
            {this.state.isLoading ?
              <div className="apiCallLoader">
                <CircularProgress color="secondary" />
              </div>
              : <Button onClick={this.enableYourLocation} variant="contained" color="primary"><span class="material-icons-outlined">location_searching</span> Enable Location</Button>
            }
          </div>
        </div>
        {this.state.isLocationSnackBar && this.state.isLocationEnabled === false ?
          <Snackbar open={true} autoHideDuration={6000} onClose={this.closeSnackBar}>
            <Alert onClose={this.closeSnackBar} severity="error">
              Please enable location setting manually.
            </Alert>
          </Snackbar> : ''}
      </div>
    } else {
      if (this.state.isLoading === false) {
        if (this.state.apiResponse.fbIsError === false) {
          //console.log('Ads',this.state.apiResponse.message);
          //console.log('Location',this.state.markerLocations);
        } else {
          var reason = this.state.apiResponse.fsMessage;

          var LogOutPopup = "";
          if (this.state.isErrorPopup !== "") {
            LogOutPopup = <ContinuePopup popupButtonText="Logout" popupTitle="Mobile Deleted"
              popupContent={this.state.isErrorPopup}
              secondLineText=""
              handleClose={this.logoutToUser} openDialog={true} />
          }
          displayError = <div style={{ padding: '15px' }}>
            <div className="NoDataFound" style={{ marginTop: '15px' }}>
              {LogOutPopup}
              <img src={NoDataFound} alt="No Data Found" />
              <div>Oops!</div>
              <div>
                {reason}
              </div>
            </div>
          </div>;
        }
      }
    }

    var paddingTop = '155px';
    if (this.state.searchFormToggle) {
      paddingTop = '163px';
    }

    if (displayError !== "") {
      return (
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle="Search" goBack={this.goBack} />
          </div>
          <div className=" AppContent adGridView isInfiniteScroll search-list">
            <div className="isNormalView" style={{ 'paddingTop': paddingTop }}>
              {this.state.isDisplayFormOnLoader ? <div className="searchFormSpaceHandler" style={{ 'padding': '0px 15px' }}>
                {searchForm}
              </div> : ''}
              {displayError}
            </div>
          </div>
        </div>

      )
    } else {

      var fbIsExpiration = parseInt(this.state.foGlobalSetting.fiExpirationId) > 0 ? <div onClick={() => {
        this.props.history.push({
          pathname: 'wallet',
        })
      }} class="searchFormToggleFixed my-cash-expiration zoom-in-zoom-out">
        <img src={config.getProviderSetting().fsMyCashExpiringImage} />
      </div> : '';

      return (
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle="Search" goBack={this.goBack} />
          </div>
          <div className=" AppContent adGridView isInfiniteScroll search-list">
            <div className="map2partsDevider">
              <div className="isLeftRightSpace" style={{ 'paddingTop': paddingTop }}>

                {this.state.isOpenMyCashPopup ? <MoreCashbackPopup
                  handleClose={() => { this.setState({ isOpenMyCashPopup: false }) }}
                  popupButtonText="Got It"
                  cashbackType={this.state.cashbackType}
                  openDialog={true} /> : ''}
                {fbIsExpiration}
                {searchForm}
                {loaderHtml}
                {this.state.apiResponse.foOfferList !== undefined && this.state.apiResponse.foOfferList.length > 0 ? <div className="adsListContainer">
                  <InfiniteScroll
                    dataLength={this.state.apiResponse.foOfferList.length - 15}
                    next={() => this.getAdsLists(this.state.lat, this.state.long, true)}
                    hasMore={this.state.hasMore}
                    loader={<div className="apiCallLoader" style={{ 'marginBottom': '10px' }}><CircularProgress color="secondary" /></div>}
                  >
                    {this.state.apiResponse.foOfferList.map((marker, i) => {
                      var cardClass = 'redCard';
                      if (marker.fsPinColor === "green") {
                        cardClass = 'greenCard';
                      } else if (marker.fsPinColor === "yellow") {
                        cardClass = 'yellowCard';
                      } else if (marker.ad_type === "ENGAGED") {
                        cardClass = 'grayCard';
                      } else {
                        cardClass = 'redCard';
                      }
                      // console.log(marker);

                      return (
                        <MapItemList
                          directMoveOnCouponScreen={this.directMoveOnCouponScreen}
                          openMyCashPopup={this.openMyCashPopup}
                          key={i} marker={marker} cardClass={cardClass} fiBannerId={this.state.fiBannerId}>
                        </MapItemList>
                      )
                    })}
                  </InfiniteScroll>
                </div> : ''}
              </div>
            </div>
          </div>
        </div>


      )
    }


    return (loaderHtml)
  }
}