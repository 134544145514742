import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: ["'Open Sans'", 'sans-serif'].join(','),
    },
    body: {
        fontFamily: ["'Open Sans'", 'sans-serif'].join(','),
    }
});

export default theme;